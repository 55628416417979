import React, { useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';


export default function NuevoClientePage(props) {

    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError]=useState(null);
const [initialForm, setInitialForm] = useState( {nombre:'', celular:'', facturaNombre:'', facturaRuc:''} )


    useEffect(() => {
      console.log("props", props.location.state.query);
      setInitialForm(  {nombre:'', celular: props.location.state.query , facturaNombre:'', facturaRuc:''}   );

      console.log(  {nombre:'', celular: props.location.state.query , facturaNombre:'', facturaRuc:''} );
    }, [])




    const onSubmit = async (values, setSubmitting) => {
            // console.log(" auth", auth);
            console.log("submit called",  values, setSubmitting);
            setError( null );
            

            axios.post(  `delivery/cliente`, values).then(
                (result)=>{
                   console.log( "result ", result);
                   setSubmitting(false);
                   props.history.replace(`/callcenter/cliente/${result.data.id}`); //after saving email the user will be sent to Panel;
                }
            ).catch(error => {
                setSubmitting(false);
                console.log("error ", error.response.data.message);
                setError(error.response.data.message);
            });;  

  
  
  
  
        }
  



    return (
        <div className="  ">
<Formik
enableReinitialize={true}
      initialValues={  initialForm }
      validate={values => {
        const errors = {};

        if (!values.nombre) {
          errors.nombre = 'Requerido'; 
        }

        if (!values.celular) {
            errors.celular = 'Requerido'; 
          }


          
        // if (!values.nombre) {
        //     errors.nombre = 'Requerido'; 
        //   }        
        
        //   if (!values.nacimiento) {
        //     errors.nacimiento = 'Requerido';
        //   }                  
       
        //   if (!values.cedula) {
        //     errors.cedula = 'Requerido';
        //   }      

        //   if (!values.ciudad) {
        //     errors.ciudad = 'Requerido';
        //   }      


        //   if (!values.barrio) {
        //     errors.barrio = 'Requerido';
        //   }      
 
          return errors;

      }}
      onSubmit={(values, { setSubmitting }) => {


        setSubmitting(true);


        console.log("submit");
        setTimeout(() => {
            onSubmit(values, setSubmitting);
            //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        }, 100);

      }}
    >




{({ isSubmitting }) => (
  <Form className="m-5 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

   { isSubmitting?
    <p className='text-xl pb-5'>
            Aguarde...
    </p>
:
<div>

    <p className='text-xl pb-5'>
            Registro de nuevo cliente  
    </p>

{
    error && <p className='text-xl bg-red-700 p-3 mb-5 rounded'>
    Error: {error}
</p>
}

    <div className="mb-1">
        <label className="block text-gray-700 text-sm font-bold " htmlFor="nombre">
        Nombre
        </label>
        <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="nombre" />
        <ErrorMessage name="nombre" component="div" className="p-1 bg-red-600 border rounded" />
    </div>

    <div className="mb-1">
        <label className="block text-gray-700 text-sm font-bold " htmlFor="celular">
        Celular
        </label>
        <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="celular" />
        <ErrorMessage name="celular" component="div" />
    </div>
      
    <div className="mb-1">
        <label className="block text-gray-700 text-sm font-bold " htmlFor="facturaNombre">
        Factura nombre
        </label>
        <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="facturaNombre" />
        <ErrorMessage name="facturaNombre" component="div" />
    </div>

    <div className="mb-1">
        <label className="block text-gray-700 text-sm font-bold " htmlFor="facturaRuc">
        Factura RUC
        </label>
        <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="facturaRuc" />
        <ErrorMessage name="facturaRuc" component="div" />
    </div>


    <div className="flex items-center justify-between">
      <button  type="submit"  className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" >
        Registrar
      </button>
    </div>
</div> }

  </Form>
  )}

  </Formik>
        </div>
    )
}
