import React from 'react'

export default function ChartCard(props) {
    return (
        <div className={ props.className?props.className:'w-full md:w-1/2 p-3' }  >
        <div className="bg-gray-900 border border-gray-800 rounded shadow">
            <div className="border-b border-gray-800 p-3">
                <h5 className="font-bold uppercase text-gray-600">{props.title}</h5>
            </div>
            <div className="p-5">
            {props.children}
            </div>
        </div>
    </div>
    )
}
