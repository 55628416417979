import React, {useState }from 'react'
import { useSelector  } from 'react-redux';
import Factura from '../components/Factura'
import {  useDispatch} from 'react-redux';
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import axios from 'axios';



import {
  selectProductos,
  selectSucursales
    } from '../features/masterSlice';


import {
         selectDireccion,
         selectCliente,
         selectPedido,
         reset,
         setFormaPago,
         setFactura,
         setModalidad,
         setObservaciones,
         setVehiculo,
         setBuscaEn,
         setSucursal,
         recalcular
      } from '../features/pedidoSlice';







export default function Pedido( props ) {




  const sucursales = useSelector(selectSucursales);
  const cliente = useSelector(selectCliente);
  const direccion = useSelector(selectDireccion);
    const pedido = useSelector(selectPedido);
    const productos = useSelector(selectProductos);
    const [showDialog, setShowDialog]=useState(0);
    const [isSubmitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();


    const cars=[
      {  src:"./assets/cars/car-city-model.svg", nombre:'Compacto'  },
      {  src:"./assets/cars/sedan-car-model.svg", nombre:'Sedan'  },
      {  src:"./assets/cars/minivan-car.svg", nombre:'Minivan'  },
      {  src:"./assets/cars/jeep.svg", nombre:'Camioneta'  },
      {  src:"./assets/cars/bike.svg", nombre:'Moto'  }
    ];
  
  
    const colors=[
      { bg:'black', fg:'white',  nombre:'Negro' },
      { bg:'red', fg:'white',  nombre:'Rojo' },
      { bg:'gray', fg:'white',  nombre:'Gris' },
      { bg:'white', fg:'gray',  nombre:'Blanco' },
      { bg:'blue', fg:'white',  nombre:'Azul' },
      { bg:'#b0b8b9', fg:'black',  nombre:'Plata' },
      { bg:'gold', fg:'gray',  nombre:'Dorado' }
    ];
  
  





     const iniciarOrden = ()=>{
        console.log( props.history );
        props.history.push('/callcenter/cliente');

     }


     const cancelarOrden = ()=>{
        
        
        dispatch( reset() );
        props.history.push('/callcenter/cliente');


     }

     const enviarOrden = ()=>{
        
       


        //console.log('pedido', pedido);

      if( pedido.productos.length ===0){
        alert("Debe agregar productos al pedido");
        return;
      }



        let data= JSON.parse(JSON.stringify(pedido));



        data.cliente=null;


        setSubmitting(true);

        axios.post(  `delivery/pedido`, data).then(
          (result)=>{
             console.log( "result axios pedido", result);
             setSubmitting(false);
            
              dispatch( reset() );
              props.history.push('/pedidos');
          }
          ).catch( error=>{
            alert(error.response.data.error);
          });  


          setTimeout(  ()=>setSubmitting(false), 2000);



        //dispatch( reset() );
        //props.history.push('/pedidos');


     }




     const seleccionarCliente = ()=>{

        props.history.push('/callcenter/cliente');
     }



    return (
       <div>
            <p className="text-4xl text-center">PEDIDO ACTUAL</p>



<Dialog style={{ color: "red" }} isOpen={showDialog} onDismiss={ ()=> setShowDialog(false) }   >
    <p className="m-5 text-2xl  text-center">Confirmas cancelar la orden?</p>

     <div className="flex">
        <button className="bg-red-200 border p-2 text-xl mr-5 outline-none" onClick={ ()=> setShowDialog(false) }>No</button>
        <button className="bg-green-200 border p-2 text-xl outline-none"  onClick={ ()=> {setShowDialog(false); cancelarOrden();} }>Si</button>
    </div>       


</Dialog>



            <div className="mb-5" >
            { pedido && <Factura  callcenter={true}    data={pedido} /> }
            </div>
           


            {/* <div className="mb-5">

            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
            MODALIDAD: {pedido.modalidad}
            </label>
                 
                    <div className="inline-flex">
  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> {  dispatch(setModalidad('delivery')); 
  dispatch(recalcular( {  deliv: productos.find( i=>i.nameFront==='Delivery' ) } ));  }  }>
   Delivery
  </button>
  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" onClick={()=> { dispatch(setModalidad('fasttrack'));  
  dispatch(recalcular( {  deliv: productos.find( i=>i.nameFront==='Delivery' ) } ));  } }>
    Fasttrack
  </button>
</div>
                </div> */}



            {


            // pedido.modalidad==='fasttrack' && 
             <div className="mb-5">

<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
              Sucursal: {pedido.sucursal}
              </label>
                  

                      <div className="grid grid-cols-4 gap-1 ">

            { sucursales.map( (i,index)=>
                <button key={index} className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> {  
                  dispatch(setSucursal(  i.nombre )); 
            }  }>
            {i.nombre}
            </button> 
              
              )
            }

            </div>


{/* 
              <label className="mt-5 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
              Tipo vehiculo: {pedido.vehiculo?.tipo}
              </label>

                     <div className="inline-flex ">
            { cars.map( (i,index)=>
                <button key={index} className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> {  
                  dispatch(setVehiculo(  {  ...pedido.vehiculo, tipo:i.nombre } )); 
            }  }>
            {i.nombre}
            </button> 
              
              )
            }
            </div>



            <label className="mt-5 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
              Color: {pedido.vehiculo.color}
              </label>
                  

             <div className="inline-flex">

                { colors.map(  (i,index)=>
                    <button key={index}  className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> {  
                      dispatch(setVehiculo(  {  ...pedido.vehiculo, color:i.nombre } )); 
                }  }>
                {i.nombre}
                </button> 
                  
                  )
                }
            </div>



            <label className="mt-5 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
              Busca en : {pedido.buscaEn} minutos
              </label>
                  

             <div className="inline-flex">

           
                <button className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=>  dispatch(setBuscaEn(0)) }>
                Ahora
                </button> 

                <button className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=>  dispatch(setBuscaEn(5)) }>
                5 min.
                </button> 

                <button className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=>  dispatch(setBuscaEn(10)) }>
                10 min.
                </button> 

                <button className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=>  dispatch(setBuscaEn(15)) }>
                15 min.
                </button> 

                <button className="bg-gray-300 text-sm hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=>  dispatch(setBuscaEn(30)) }>
                30 min.
                </button> 


            </div> */}




                  </div>

            }









            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
        CLIENTE: 
      </label>
                { cliente ? <p className="text-black text-2xl leading-none"> {cliente.nombre} {cliente.apellido} ({cliente.celular}) </p>:
             <button className="bg-green-200 border p-2 text-xl outline-none"  onClick={ ()=> seleccionarCliente }>Seleccionar cliente</button>
            }
            
            


            <div className="w-full mt-5">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-fact-nombre">
        FACTURA NOMBRE: 
      </label>
      <input value={  pedido.factura.nombre } onChange={(e)=> dispatch( setFactura( {nombre:e.target.value, ruc: pedido.factura.ruc} ) )}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-fact-nombre" type="text" placeholder="nombre factura "/>
    </div>


    <div className="w-2/3 mt-5">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-factura-ruc">
        FACTURA RUC: 
      </label>
      <input value={  pedido.factura.ruc } onChange={(e)=> dispatch( setFactura( {nombre:pedido.factura.nombre, ruc: e.target.value} ) )}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-factura-ruc" type="text" placeholder="ruc factura "/>
    </div>



    <div className="mt-5">
    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
        DIRECCIÓN: 
      </label>
       { direccion ? <p className="text-black text-2xl leading-none"> {direccion.calle} <br/>{direccion.referencias} </p>:
             <button className="bg-green-200 border p-2 text-xl outline-none"  onClick={ ()=> seleccionarCliente }>Seleccionar cliente</button>
            }
    </div>        



            <div className="mt-5">

            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  >
            Forma de pago: {pedido.formaPago}
            </label>
                 

                    <div className="inline-flex">
  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> dispatch(setFormaPago('efectivo'))  }>
   Efectivo
  </button>
  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" onClick={()=> dispatch(setFormaPago('pos')) }>
    Pos
  </button>
</div>




<div className="w-full mt-5">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-obs">
        OBSERVACIONES: 
      </label>


      <textarea 
      value={  pedido.observaciones } onChange={(e)=> dispatch( setObservaciones(e.target.value) )}
      id="grid-obs" type="text" placeholder="Observaciones"
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
      resize-none " />

      {/* <input value={  pedido.observaciones } onChange={(e)=> dispatch( setObservaciones(e.target.value) )}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
       id="grid-obs" type="text" placeholder="Observaciones"/> */}



    </div>


                </div>

            

              <div className="mt-8">

              { isSubmitting?
            <p className='text-xl pb-5'>
                    Aguarde...
            </p>:
            <div className="flex">  
            <button className="px-3 py-1 bg-red-500 text-sm text-white font-semibold rounded text-2xl w-full mr-5 outline-none"  
            onClick={ ()=> setShowDialog( true ) } >Cancelar orden</button>
            <button className="px-3 py-1 bg-green-300 text-sm text-black font-semibold rounded text-2xl w-full outline-none"  
        onClick={  enviarOrden } >Confirmar orden</button> </div> }

              </div>

        



        </div> 



    )
}
