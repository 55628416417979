import React, {useState, useEffect, useRef} from 'react'
import NumPad from 'react-numpad';
import { BsCheck, BsBack  } from "react-icons/bs";


import { IoMdArrowBack } from "react-icons/io";


import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import { gql,  useLazyQuery} from "@apollo/client";


const RUC_QUERY = gql`
 query($cedula: String!){
    rucByCedula(cedula: $cedula){
        ruc
        dv
        razon_social
    }
 }
`;



const optionsKbdRucLineal = {
    theme: "hg-theme-default hg-theme-ios teclado-custom-ruc",
    layout: {
      default: [
        "1 2 3 4 5 6 7 8 9 0 - {bksp}"
      ]
    },
    display: {
      "{enter}": "⏎",
      "{bksp}": "⌫"
    }
  }


const optionsKbdRuc = {
    theme: "hg-theme-default hg-theme-ios teclado-custom-ruc",
    layout: {
      default: [
        "7 8 9",
        "4 5 6",
        "1 2 3",
        "- 0 {bksp}",
      ]
    },
    display: {
      "{enter}": "⏎",
      "{bksp}": "⌫"
    }
  }







export default function Ruc( {isOpen,onRequestClose, style }) {


    const [keyboardRef, setKeyboardRef] = useState(null)
    const kbRef = useRef(null)
    const [ruc, setRuc] = useState("")
    const [rucByCedula, setRucByCedula] = useState(null)
//    const { setCliente } = useCajaContext();

    const [input, setInput] = useState('');

    const [ searchRucByCedula,  { loading, error, data  }, ] = useLazyQuery(RUC_QUERY );
    



    useEffect(() => {
      if(data?.rucByCedula)setRucByCedula(data.rucByCedula)
    }, [data])
    


    const handleSubmit = (event)=>{

        try{
            searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
                console.log(res);
            });
    
        }catch(ex){
            console.log("error on search ruc by cedula", ex)
        }

        event.preventDefault();
      }



      const confirmRuc = ()=>{

    //    setCliente(rucByCedula)
        onRequestClose( rucByCedula  );
        
      }



      const  confirmSinRuc = ()=>{
        onRequestClose( 
           {
            razon_social:'',
            ruc:'00000000',
            dv:'0'
           }
          );
      }



      const cleanSearch = ()=>{
            setRuc('')
            setRucByCedula(null)
      }

    //   useEffect(() => {
    //     console.log("ruc effect", ruc)
    //     if(ruc){

    //         try{
    //             searchRucByCedula({variables:{ cedula: ruc }}).then( res=>{
    //                 console.log(res);
    //             });
        
    //         }catch(ex){
    //             console.log("error on search ruc by cedula", ex)
    //         }


    //     }
    //   }, [ruc, searchRucByCedula])
      


  return (  
 

        <form onSubmit={handleSubmit}>
            <div className="   flex flex-col gap-10">
       

                    <div className="flex flex-col  justify-center items-center  gap-8 bg-gray-700 w-full rounded-lg p-5">



                            { !rucByCedula ? <div className="flex flex-col  justify-center items-center  gap-8">

                                <p className="text-white  text-4xl  text-center">Ingrese su RUC:</p>



                                <div className="flex justify-center items-center">
                                   <div className="p-3 bg-gray-300 flex flex-col">

                                   <pre className="text-4xl p-3 bg-green-100 font-mono">
                                        { ruc
                                        ?
                                        ruc
                                        :
                                        <p>&nbsp;</p>
                                        }
                                    </pre>

                                    <Keyboard
                                            ref={kbRef}
                                            {...optionsKbdRuc}
                                            onKeyPress={(button) => {
                                            if (button === "{enter}") {
                                                handleSubmit();
                                            }
                                        }}

                                        onInit={(keyboard) => {
                                                setKeyboardRef(keyboard)
                                        }}

                                            onChange={  (input) => {
                                            console.log("onChange input", input);
                                            setRuc(input)
                                            }  }
                                            // onKeyPress={this.onKeyPress}
                                        />
                                   </div>
                                </div>





                                    <div>
                                        <button type="submit" className="px-10 py-8 text-2xl bg-blue-300 rounded-lg" style={{width:'calc(30vw)'  }} >
                                            Buscar RUC
                                        </button>
                                    </div>
                            </div>:
                            <div className="w-full flex flex-col gap-5">
                                <div className="flex gap-5">
                                    <button
                                    type="button" 
                                    onClick={ confirmRuc }  
                                    className="p-6 flex-1 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{rucByCedula?.razon_social}</h5>
                                        <p className="mb-3 text-4xl font-normal text-gray-700 dark:text-gray-400">{`${rucByCedula?.ruc}-${rucByCedula?.dv}`}</p>
                                    </button>

                                    <button
                                    style={{width:'calc(20vw)'  }}
                                    type="button" 
                                    onClick={ confirmRuc }  
                                    className="p-6 max-w-sm bg-green-300 rounded-lg border border-gray-200 flex flex-col justify-center items-center"
                                    >
                                        <BsCheck size={120} color="black" />
                                        <p className="text-xl">continuar...</p>
                                    </button>
                                </div>

                                <div className="flex justify-end ">
                                    <button
                                        style={{width:'calc(20vw)'  }}
                                        type="button" 
                                        onClick={ cleanSearch }  
                                        className="p-6 max-w-sm bg-red-300 rounded-lg border border-gray-200 flex flex-col justify-center items-center"
                                        >
                                            <IoMdArrowBack size={80} color="black" />
                                            <p className="text-xl">volver</p>
                                    </button>
                                </div>
                            </div>                            
                        }
                    </div>

                    
                    <div className="flex flex-col  justify-center items-center  gap-8 bg-gray-700 w-full rounded-lg p-5">
                    
                                        <button type="button" onClick={confirmSinRuc} className="px-10 py-8 text-2xl bg-red-300 rounded-lg" style={{width:'calc(30vw)'  }}>
                                            Continuar sin RUC
                                        </button>
                                   
                    </div>



            </div>
     </form>




  )
}
