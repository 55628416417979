import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PrintComponentButton = ({child}) => {
  const componentRef = useRef(null);

  const handlePrint = () => {
    html2canvas(componentRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.autoPrint();
      window.open(pdf.output('bloburl'), '_blank');
    });
  };


  return (
    <div>
      <div ref={componentRef}>
        <h1>Componente para imprimir</h1>
        {child}
      </div>
      <button className="px-4 py-3 bg-blue-300" onClick={handlePrint}>
        Imprimir Componente
      </button>
    </div>
  );
};

export default PrintComponentButton;