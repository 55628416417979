import React from 'react'

export default function Direccion({data, enviarHanlder}) {

    const storage=process.env.REACT_APP_STORAGE;

    const url = data && (storage+data.img);


    return (



<div className="  w-full flex border   border-gray-400   bg-white rounded ">

    
  <div className="h-48  w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" 
  style={  { backgroundImage: `url(${ url })`} }  title="mapita">
  </div>



 
  <div className="w-full p-4 flex flex-col justify-between leading-normal">
    <div className="mb-8">
      
      <div className="text-gray-900 font-bold text-xl mb-2">  { data.calle} </div>
      <p className="text-gray-700 text-base"> { data.referencias} </p>
    </div>
    
    { enviarHanlder && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"   onClick={  ()=>enviarHanlder(data) } >
  Enviar aquí
</button>  }

    
  </div>
</div>


    )
}
