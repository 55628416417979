import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/es'

moment.locale(['es'])
// this.productos=data.productos;
// this.listaProductos=this.productos.filter( (i)=> i.orden>=0   );
// this.bebidas = this.listaProductos.filter( (i)=> i.categoria=='BEBIDAS'  );
// this.user=data.user;
// this.sucursales=data.sucursales;
// this.zonas=data.zonas;  

function getCurrentDimension(){
  return {
      width: window.innerWidth,
      height: window.innerHeight
  }
}

export const masterSlice = createSlice({
  name: 'master',
  initialState: {
    fecha: moment().toString(),
    productos: [],
    listaProductos: [],
    listaProductosdi: [],
    bebidas: [],
    bebidasSa: [],
    user: {},
    screenSize: getCurrentDimension(),
    sucursales: [],
    sucursalesUser: [],
    zonas: [],
    filtroProducto: '',
    loading: false,
    sucursalesCount: [{ n: 'Sol', c: 3 }, { n: 'Mariscal', c: 3 }, { n: 'Pinedo', c: 3 }]
  },
  reducers: {



   


    updateScreenSize: (state, action) => {
      state.screenSize = getCurrentDimension()
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setFecha: (state, action) => {
      state.fecha = action.payload
    },

    setSucursalesCount: (state, action) => {
      state.sucursalesCount = action.payload
    },

    setSucursalesUser: (state, action) => {
      state.sucursalesUser = action.payload
    },

    setFiltroProducto: (state, action) => {
      console.log('setfilrprod', action)
      state.filtroProducto = action.payload
    },

    setDatos: (state, action) => {

      console.log("set datos", state)

      state.productos = action.payload.menu
      state.listaProductos = action.payload.menu.filter((i) => i.orden >= 0) 

      state.productosdi = action.payload.menudi
      state.listaProductosdi =  action.payload.menudi.filter((i) => i.orden >= 0) 

      // state.bebidas = state.listaProductos.filter( (i)=> i.categoria==='BEBIDAS'  );
      // state.bebidasSa = state.bebidas.filter( (i)=> i.alcohol!=true  );

      state.user = action.payload.user
      state.sucursales = action.payload.sucursales
      state.zonas = action.payload.zonas
      console.log('state redux', action.payload)
    },

  },
})

export const {
  setDatos,
  setFiltroProducto,
  setLoading,
  setSucursalesCount,
  setFecha,
  setSucursalesUser,
  updateScreenSize
} = masterSlice.actions

export const loadData = () => async dispatch => {

  axios.get(process.env.REACT_APP_API_ENDPOINT + 'delivery/app').then(
    (result) => {

      console.log(' load data ', result.data)
      // if (localStorage.getItem('foodtruck')==="true") {
      //   result.data.menudi = result.data.menudi.filter((item) => item.foodtruck === true)
      //   console.log(result.data.menudi)
      //
      // }
      dispatch(setDatos(result.data))
    }
  ).catch((error) => {
    console.log('error', error)

  })


}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.master.value)`
export const selectProductos = state => state.master.productos
export const selectListaProductos = state => state.master.listaProductos

export const selectProductosDi = state => state.master.productosdi
export const selectListaProductosDi = state => state.master.listaProductosdi

export const selectBebidas = state => state.master.bebidas
export const selectBebidasSa = state => state.master.bebidasSa
export const selectUser = state => state.master.user
export const selectSucursalesUser = state => state.master.sucursalesUser
export const selectSucursales = state => state.master.sucursales
export const selectZonas = state => state.master.zonas
export const selectFiltroProducto = state => state.master.filtroProducto
export const selectLoading = state => state.master.loading
export const selectFecha = state => state.master.fecha
export const selectSucursalesCount = state => state.master.sucursalesCount

export default masterSlice.reducer
