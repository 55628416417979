import React, {useEffect, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import Direccion from '../components/Direccion'
import { useSelector, useDispatch } from 'react-redux';
import TablaPedidos from '../components/TablaPedidos'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
//    selectDireccion,
    setDireccion,
    setCliente,
    setSucursal
  } from '../features/pedidoSlice';



  import {
    selectZonas,
    selectSucursales
  } from '../features/masterSlice'

  export default function ClientePage(props) {

    const [res, setRes] = useState([]);
    const [loading, setLoading]=useState(false);
//    const direccion = useSelector(selectDireccion);
    const dispatch = useDispatch();



    const [maps, setMaps] = useState(null);
   // const [map, setMap] = useState(null);
    const zonasM = useSelector(selectZonas);
    const sucursales = useSelector(selectSucursales);



    useEffect( () => {

        console.log("request para cliente id");
          getData();
      }, [props.match.params.id])




      const getData=()=>{

        setLoading(true);
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/cliente/${props.match.params.id}` ).then(
          (result)=>{
             console.log( "result axios counter", result);
             setRes(result.data);
             setLoading(false);

          }
      ).catch( e=>         setLoading(false)      );  

      }



      const enviarHanlder = (u)=>{


            let s= getSucursalFromLocation( u.ubicacion, false);

            if(!s){
                alert("La dirección ingresada no se encuentra en cobertura");
                return;                
            }

            console.log( "enviar handler", u);
            dispatch( setCliente(res) );
            dispatch( setDireccion(u) );
            dispatch( setSucursal(s) );
            props.history.push('/callcenter/productos');
      }


      const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        console.log("handle api map loaded");
        setMaps(maps);  
       // setMap(map);  

      };





      const solicitarRetorno = (id)=>{

        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/pedidos/solicitarRetorno?id=${id}` ).then(
            (result)=>{
               
              getData();
            }
        );        
    }
  
  
    const verPedido= (id)=>{
      props.history.push(`/pedidos/${id}`)
    }
  
  
    const regenerar= (id)=>{
      props.history.push(`/callcenter/productos`,{id:id});
    }
  







      const getSucursalFromLocation=( u, permitirExtendido )=>{
        var sucursal=null;

        console.log( "getSucursalFromLocation ", u);
      
        let ps=[];
        let zonas=zonasM.filter( i => i.extendido==  permitirExtendido);
      
        zonas.forEach( (z)=>{
           ps= ps.concat( z.features.filter( i => i.geometry.type==='Polygon' )    );
        });
      
        ps.forEach( (p) => {
              let paths= p.geometry.coordinates[0].map( i=> { return {lng:i[0], lat:i[1]} } );
      
              let s = sucursales.find((s)=>s.nombre==p.properties.nombre);
              var poly = new maps.Polygon({
                paths: paths,
                nombre: p.properties.nombre});

                console.log("poly", poly );            

                
                if( maps.geometry.poly.containsLocation( new maps.LatLng(u.latitude, u.longitude)  ,poly))sucursal=s.nombre;
        });
      
        console.log("sucursal", sucursal);
        return sucursal;
      };
      

    //   "celular": "0981558036",
    //   "email": "dwc@tedepsa.com",
    //   "nombre": "Diego",
    //   "facturaNombre": "DIEGO WASMOSY",
    //   "facturaRuc": "706269-9",
    //   "direccion": "AVENIDA SANTISIMA TRINIDAD 1900 CASI RIO PILCOMAYO",
    //   "barrio": "MBURUCUYA",
    //   "ciudad": "ASUNCION",
    //   "observaciones": null,
    //   "latitude": "-25.2829130",
    //   "longitude": "-57.5660160",
    //   "created_at": "2018-09-15 17:05:18",
    //   "updated_at": "2020-03-28 21:07:52",
    //   "pedidosCount": 0,


    return (
        <div className="overflow-scroll w-full  p-5">



<div className="rounded border overflow-hidden shadow-lg mb-5 p-2">
    <small>DATOS PERSONALES</small>
    <p className="font-serif text-2xl text-gray-800 text-center">
    {res.nombre}
    </p>

    <p className="font-mono text-lg text-gray-800 text-center">
    {res.celular}
    </p>

    <p className="font-serif  text-gray-800 text-center">
    {res.created_at}
    </p>
</div>

<div className="rounded border overflow-hidden shadow-lg p-2 mb-5">
    <small>FACTURA</small>
    <p className="font-mono text-lg text-gray-800 text-center">
    {res.facturaNombre}
    </p>
    <p className="font-mono text-lg text-gray-800 text-center">
    {res.facturaRuc}
    </p>
</div>




<div className="rounded border overflow-hidden shadow-lg p-2  mb-5 ">

<GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCjyZq8RSfrImlIfnf5Rhbq-IXRqA5Dh7s'}}
          defaultCenter={ {lat:-25.2830485, lng:-57.5687854}  }
          defaultZoom={ 15}
 
      
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}

          yesIWantToUseGoogleMapApiInternals={true}
        >

        </GoogleMapReact>

    <small>DIRECCIONES</small>



    <div className="grid  grid-cols-1  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                        {
    res.direcciones && res.direcciones.map( (el)=>
    <div key={el._id} className="mb-5  " >  <Direccion   enviarHanlder={(u)=>enviarHanlder(u)}   data={el} /> </div>
)                        }


<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-16"  onClick={()=> props.history.push(`/callcenter/direccion/${res.id}`)  }   >
  Agregar nueva dirección
</button>

                        </div>

    
   

</div>




<div className="rounded border overflow-hidden shadow-lg p-2">



    <small>PEDIDOS</small>
{!loading ? <span onClick={()=>getData()} 
className="ml-3 hover:text-green-800 text-black"><FontAwesomeIcon icon={['fas','redo-alt']}   /></span>:'cargando...'}


    <TablaPedidos 
data={res.pedidos}
regenerarHandle={ regenerar }
retornoHandle={ solicitarRetorno }
verPedidoHandle={ verPedido }
/>

    
   

</div>






        </div>
    )
}



