import React from 'react'
import NumberFormat from 'react-number-format';



export default function ProductoDi({data, showPrice, addProductHandler, handlerClickProduct}) {







  const clickProducto = (p)=>{
   // console.log("click p", handlerClickProduct);
    if( handlerClickProduct )handlerClickProduct(p);
  }



    return (




<div className="w-full bg-gray-200 shadow-lg rounded-lg overflow-hidden   flex flex-col relative"  onClick={ ()=>clickProducto(data) } >


<div className="absolute right-0 top-0 p-2 text-xs bg-gray-200">
    {data.sku}
</div>

<img className="w-full  object-cover " src={  data.url  } alt="ilustracion" />


<div className="p-2">

    <p className="text-gray-900 text-2xl font-bold   truncate"> {data.nameFront} </p>
    {  showPrice && <p className="text-black font-bold text-sm">
        <NumberFormat value={data.precio} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
    </p>
    }

</div>
</div>



    )
}

 