import React  from 'react'
import Moment from 'react-moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';


export default function TablaPedidos(props) {
    return (
        
        

<table className="table-auto w-full">
<thead>
<tr className="bg-indigo-200">
<th className="px-2 py-2">#</th>
<th className="px-2 py-2">Fecha</th>
<th className="px-2 py-2">Cliente</th>
<th className="px-2 py-2">Orden</th>
<th className="px-2 py-2">Monto</th>
<th className="px-2 py-2">TP</th>
<th className="px-2 py-2">Modalidad</th>
<th className="px-2 py-2">Sucursal</th>
<th className="px-2 py-2">Estado</th>
<th className="px-2 py-2">Tomado por</th>
<th className="px-2 py-2">Acciones</th>
</tr>
</thead>
<tbody>

{
props.data && props.data.map ((item,i)=>

<tr className="bg-gray-100"  key={item._id} >
<td className="border px-1 py-1"> { i+1 } </td>

<td className="border px-1 py-1">  <Moment  format="LLL" locale="es">{item.created_at}</Moment>   </td>
<td className="border px-1 py-1">{item.cliente.nombre} {item.cliente.apellido}</td>
<td className="border px-1 py-1"> {item.ticket} </td>
<td className="border px-1 py-1 text-right">    <NumberFormat value={ item.total } displayType={'text'} thousandSeparator={true} prefix={''} />   </td>
<td className="border px-1 py-1"> {item.formaPago} </td>
<td className="border px-1 py-1"> {item.modalidad} </td>
<td className="border px-1 py-1"> {item.sucursal} </td>
<td className="border px-1 py-1"> {item.estado} </td>
<td className="border px-1 py-1"> {item.tomado ? item.tomado.name: 'app cliente'} </td>

<td>


<button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"  
onClick={()=>   props.verPedidoHandle(item._id)  }>
     <FontAwesomeIcon icon={['fas','eye']}   />
     </button>


{ item.tomado && !item.solicitaRetorno &&                <button className="bg-red-500 hover:bg-red-300 text-red-800 font-bold py-2 px-4 rounded mr-1" 
onClick={()=> { props.retornoHandle(item._id) }  }>
<FontAwesomeIcon icon={['fas','exchange-alt']}   /> PR
     </button>}


     { item.tomado && item.solicitaRetorno &&  item.estado!=='Retornado'    &&            <div className="inline-block bg-transparent  text-red-800 font-bold py-2 px-4 rounded mr-1"  >
<FontAwesomeIcon   icon={['fas','clock']}   /> ...
     </div>}


 {
   item.tomado && item.estado==='Retornado' &&
<button className="bg-green-500 hover:bg-green-300 text-green-800 font-bold py-2 px-4 rounded mr-1" 
onClick={()=> props.regenerarHandle(item._id) } alt="regenerar">
   <FontAwesomeIcon icon={['fas','copy']}   /> RG
           </button>


 }



</td>

</tr>
)



}

<tr className="bg-gray-100"   >

<td className="border px-1 py-1 text-right font-bold">    <NumberFormat value={  props.data && props.data.length } displayType={'text'} thousandSeparator={true} prefix={''} />   </td>

 
<td className="border px-1 py-1"  colSpan={3}></td>

<td className="border px-1 py-1 text-right font-bold">    <NumberFormat value={  props.data && props.data.reduce( (a,b)=> a+b.total  ,0) } displayType={'text'} thousandSeparator={true} prefix={''} />   </td>

<td className="border px-1 py-1"  colSpan={6}></td>
</tr>


</tbody>

</table>




    )
}
