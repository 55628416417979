import React, {useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";
import Opciones from '../components/Opciones'
import Bebidas from '../components/Bebidas'
import { useSelector , useDispatch} from 'react-redux';
import NumberFormat from 'react-number-format';

import {
    selectBebidasSa,
    selectProductos
      } from '../features/masterSlice';

import { addProducto }
from '../features/pedidoSlice';
import ProductoOption from './ProductoOption';



export default function ProductoDetail(props) {

    const dispatch = useDispatch();
    const location = useLocation();
    const [ flagsSeleccion, setFlagsSeleccion ]= useState(  {opciones:false,bebida:false, papas:false, agranda:false, juguete:false}  );
    const [data] = useState(location.state.data );
    const productos = useSelector(selectProductos);
    const [options, setOptions] = useState([]);
//    const [tipo, setTipo] = useState('')
    const [preferences, setPreferences] = useState([])
    const [precioC, setPrecioC] = useState(  data.precio );







    // const [comments, setComments] = useState('')
const [juguete, setJuguete] = useState('')

    const [producto, setProducto] = useState({
        ...location.state.data,
        cantidad:1,
        order_product_addons:[],
        grupos: [...new Set(location.state.data?.options?.map(item => item.option_group))]
    });

 

    useEffect(() => {

        let p=location.state.data;


        let precioBase=data.precio;


        // producto.cantidad
        setPrecioC(  precioBase  );
    
    
        console.log( "producto", producto)


        // new set from array values withou repeating



    
        let t='simple';
        if( producto.cajita && producto.cajita===true)t='cajita';
        if( producto.combo && producto.combo === true )t='combo';
        if( producto.promo && producto.promo === true )t='promo';
        if( producto.bebidas && producto.bebidas === true )t='bebidas';
    
       // setTipo(t);
    
       setProducto( {...producto, 
        precio:precioC, 
        tipo:t 

        });
    
    
        switch(  t )
        {
            case 'cajita':  
            setFlagsSeleccion(  { bebida:true, papas:false, agranda:false, juguete:true} );
            break;
    
            case 'combo':  
            setFlagsSeleccion(  { bebida:true, papas:true, agranda:true, juguete:false} );
            break;
    
            case 'promo':  
            setFlagsSeleccion(  { bebida:true, papas:false, agranda:true, juguete:false} );
            break;        
    
            case 'bebidas':  
            setFlagsSeleccion(  { bebida:true, papas:false, agranda:false, juguete:false} );
            break;     

            default:  
            setFlagsSeleccion(  { bebida:false, papas:false, agranda:false, juguete:false} );
            break;
        }
    
        if( producto.preferences.length>0) setFlagsSeleccion( ps=>  {return { ...ps, opciones:true}} );
    
    


    
     }, [props]);
    




     useEffect(() => {
        let sumaAddons=0;        
        
        if( options.length > 0){
            sumaAddons=options.reduce( (prev, i) => prev+parseInt( i.addon.price   ), 0 )
        }

        setPrecioC( prev=>  parseInt(data.precio) +parseInt(sumaAddons));    

     }, [options])

 
     
 

     const clickPreference= (o, v)=>{

        console.log( o, v);

        // this.pedidoProvider.producto.opciones=this.opciones.filter( i=> i.value===true );

        // console.log( "opc", this.opciones, this.pedidoProvider.producto.opciones);

        let opc= JSON.parse(JSON.stringify(o)); 

        var index = preferences.findIndex(p => p.nombre === o.nombre);

        if( index === -1){
            setPreferences(   prev => [...prev, opc]    )
        }else{
            if( !v) setPreferences(   preferences.filter(p => p.nombre !== opc.nombre)    );  
        }


        console.log("preferences", preferences);
        
     }





     const clickOption= (item)=>{

        console.log("clickOption", item);

        let addOn={
            id: item.sku,
            price: item.precio,
            addon_id: item.sku,
            addon:{
              id: item.sku,
              price: item.precio,   
              addon_name: {
                id: item.sku,
                name:  item.nameFront,
                label: item.option_group,
                description: item.option_group
              }
            }
        };

        let n= options.filter( f=> f.addon.addon_name.label!==item.option_group);
        setOptions(  [  ...n, addOn ] );        
        
       
        

     }



 

useEffect(() => {




 }, [producto]);




 useEffect(() => {
    setProducto( {...producto, precio:precioC});

 }, [precioC]);




    const handlerAceptar = ()=>{


        const totalGrupos = producto.grupos.length;
        const totalSeleccionados = options.length;

        console.log("handlerAceptar options", producto.grupos.length,  options.length  )


        if( totalGrupos > totalSeleccionados){ alert("Seleccione todas las opciones requeridas");   return;}

        // if( !cliente ){
        //     alert("Debe seleccionar un cliente primero");
        //     return;
        // }

        // if(  flagsSeleccion.juguete && !juguete  ){ alert("Seleccione el juguete");   return;}
        // if( flagsSeleccion.bebida && !options.find(f=>f.addon.addon_name.label==='Bebida') ){ alert("Seleccione la bebida");   return;}
        // if( flagsSeleccion.papas && !options.find(f=>f.addon.addon_name.label==='Papas')  ){ alert("Seleccione el tipo de papas");   return;}
        // if( flagsSeleccion.agranda && !options.find(f=>f.addon.addon_name.label==='Agrandado')  ){ alert("Seleccione si es agrandado o no");   return;}

        let productoResultante={ producto:{...producto, order_product_addons:options,  preferences:preferences, opciones:preferences, comments:''+juguete}, deliv: productos.find( i=>i.nameFront==='Delivery' ) };
        productoResultante.producto.options=[];
        delete productoResultante.producto.options;


        console.log( "state", producto);
        dispatch(  addProducto( productoResultante) );
        console.log(" producto agregado", productoResultante)
        props.history.push('/callcenter/productos');

    }
     








    const handlerCancelar= ()=>{
        props.history.push('/callcenter/productos');
     }



    return (

         <div className="w-full   h-full shadow-lg rounded-lg overflow-y-auto flex flex-col justify-between bg-gray-200 ">
          
 
            <div  className="overflow-scroll   " >
        <div className="flex-1">
                <div className="flex m-5">
                            <img className=" w-1/5 object-cover mt-2" src={  data.url  } alt="ilustracion" />

                            <div className="px-4 py-2">
                            <h3 className="text-gray-900  font-bold  truncate"> {data.nameFront} ({producto.tipo}) </h3>
                            <p className="text-gray-700 text-lg mt-1">{data.descripcion} </p>
                        </div>
                </div>


                    { flagsSeleccion.opciones &&                  
                        <div className="border rounded p-2 m-5">
                            <p className="p-3">Preferencias:    </p>
                            <ul className="inline-flex">
                            {
                                
                                producto.preferences.map( i => 
                                    
                                    <p class="inline-flex"> <label className="md:w-2/3 block text-gray-500 font-bold">
                                    <input className="mr-2 leading-tight" type="checkbox" onChange={ e=>clickPreference(i, e.target.checked)  } />
                                    <span className="text-sm">
                                    {i.nombre}
                                    </span>
                                    </label> </p>
                                    
                                    )
                                    

                            } </ul>  
                        </div>
                    }



                <div className='m-5 '>
        
                        <div className="flex flex-col gap-3">
                                {
                                producto?.grupos?.map(i=>{
                                    return (  <ProductoOption  grupo={i} clickOptionHandler={clickOption} producto={producto} options={options}  />   )
                                })

                                }
                        </div>
        
                </div>
        </div>               
                            </div>








          <div className="flex items-center justify-between px-4 py-2 bg-gray-900  ">
                <h1 className="text-gray-200 font-bold text-2xl">
                
                <NumberFormat value={producto.cantidad*precioC} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
                </h1>


        


                        <div className="inline-flex">

                        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l" onClick={()=> setProducto( prev=> { if(prev.cantidad!==1)return {...producto,cantidad:prev.cantidad-1}; else return {...producto,cantidad:prev.cantidad};  } ) }>
                        -
                        </button>
                        <p className="font-mono bg-white p-3"> {producto.cantidad} </p>
                        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r mr-5" onClick={()=> setProducto( prev => {return {...producto, cantidad:prev.cantidad+1}} ) }>
                            +
                        </button>


                    <button className="px-4 py-2 bg-gray-200 text-xl text-gray-900 font-semibold rounded mr-5" onClick={()=> handlerCancelar() }  >Cancelar</button>
                    <button className="px-4 py-2 bg-green-400 text-xl text-gray-900 font-semibold rounded" onClick={()=> handlerAceptar() }    >ACEPTAR</button>
                    </div>
          </div>

        </div>
        
    )
}
