import React from 'react'
import Estado from '../components/Estado'

export default function ListaEstado ({ data, filtro, clickHandler, refreshHandler }) {
  if (filtro === 'Entregado') {
    const dataClone = [...data]
    dataClone.reverse()
    return (
      <div className="">
        {
          dataClone.filter(i => i.estado === filtro).map((i, index) => {
              // console.log('asd',i,index)
              if (i.modalidad === 'drivein') {
                console.log('drivein')
              }
              return (<div className="m-3" key={i._id} onClick={e => clickHandler && clickHandler(i)}>
                <Estado data={i} key={i._id}/>
              </div>)
            }
          )

        }
      </div>
    )
  }else{
    return (
      <div className="">
        {
          data.filter(i => i.estado === filtro).map((i, index) => {
              // console.log('asd',i,index)
              if (i.modalidad === 'drivein') {
                console.log('drivein')
              }
              return (<div className="m-3" key={i._id} onClick={e => clickHandler && clickHandler(i)}>
                <Estado data={i} key={i._id}/>
              </div>)
            }
          )

        }
      </div>
    )
  }

}
