import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment'
import 'moment/locale/es';


import {  useDispatch, useSelector } from 'react-redux';

import {
  selectUser, setFecha, selectFecha
} from '../features/masterSlice';



let colorBg= process.env.REACT_APP_ENV==='DEV'?'bg-black':'bg-red-700';  


const links=[
  {  to:"/callcenter", icon:'phone', titulo:'Callcenter'},
  {  to:"/estados", icon:'glasses', titulo:'Monitoreo' },
  {  to:"/pedidos", icon:'list', titulo:'Pedidos' },
  {  to:"/motoristas", icon:'motorcycle', titulo:'Motoristas' },
  {  to:"/dashboard", icon:'tachometer-alt', titulo:'Dashboard' },
  {  to:"/agenda", icon:'book', titulo:'Agenda' },
  {  to:"/sugerencias", icon:'book-open', titulo:'Sugerencias' },
  {  to:"/clientes", icon:'user-friends', titulo:'Clientes' },
  {  to:"/panel", icon:'user', titulo:'Panel de usuario' },
];




export default function Header(props) {

  const {rol} = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const fecha=useSelector(selectFecha);

  const [showMenu, setShowMenu] = useState(false)

  const goto=(to)=>{
    setShowMenu(false);
    history.push(  to );
  }


  const user=useSelector(selectUser);



  const onFechaChange=(i)=>{

      console.log(i);
    dispatch(   setFecha(  moment(i).toString() ) );

  }



    if( !(  (rol==='admin'  || rol==='superadmin'  ||   rol==='sucursal' ||   rol==='delivery'))  ) return <nav className={  `flex items-center 
     
    justify-between flex-wrap ${colorBg} p-2` }>
      <div  className=" h-96">
        gh
      </div>
      </nav>
 

  
    return (



      
<nav className={  `flex items-center justify-between  ${colorBg} p-2 fixed w-full z-50` }>
  <div className="flex items-center flex-shrink-0 text-white mr-6">
    <img src="/mostazalogoW.svg" width="24" height="24" alt="mostaza" className="mr-5"/>

    
    <p className="hidden md:block   font-semibold text-lg tracking-tight">Delivery {process.env.REACT_APP_VERSION} 
    
    
    <button className=" ml-3 text-base hover:text-yellow-200   text-yellow-400 font-bold p-1 rounded mr-1" onClick={()=> { window.location.reload(true); }  }>
          <FontAwesomeIcon icon={['fas','wrench']}   />
    </button>
        

  {process.env.REACT_APP_ENV  }

    </p>
  </div>




  <div className="block lg:hidden z-50">

<div className="flex inline-block ">
  { !showMenu &&  <div className="bg-white flex">
    <DateTimePicker
    locale="es"
    
    format="d/MM/y"
    clearIcon={null}
    onChange={onFechaChange}
    value={  moment(fecha).toDate()  }
    />
    </div>
  }

    <button className="flex items-center px-3 py-2 bg-red-600 border rounded text-teal-200 border-teal-400 focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0  "
    onClick={ ()=> setShowMenu( prev=> !prev )  } >
    <FontAwesomeIcon icon={['fas','bars' ]}   />
    </button>
    </div>



  </div>




  { showMenu && <div className={`  shadow w-64 mt-2 mr-2 fixed right-0 top-0 z-40   p-3 pt-10 ${colorBg} `}>

      <div className=" mb-2 flex justify-between items-center text-white">
          <p className="font-semibold text-lg tracking-tight">Delivery {process.env.REACT_APP_VERSION} 
      
        
            <button className=" ml-3 text-base hover:text-yellow-200   text-yellow-400 font-bold p-1 rounded mr-1" onClick={()=> { window.location.reload(true); }  }>
                  <FontAwesomeIcon icon={['fas','wrench']}   />
            </button>
          
          </p>

          <p className="font-semibold text-lg tracking-tight" >
          {process.env.REACT_APP_ENV  }
          </p>
      </div>

      <div className="grid grid-cols-1 gap-2 opacity-100 ">



          {
            links.map( i=>
                  <div onClick={ ()=>goto(i.to) }  className="inline-block leading-none   p-2  rounded bg-red-600  lg:inline-block lg:mt-0 text-teal-200 hover:bg-red-400 ">
                      <FontAwesomeIcon icon={['fas', i.icon ]}   /> {i.titulo}
                      </div>  
                      )}
  
      </div>    
  </div>}



  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto  hidden" >
    <div className="text-sm lg:flex-grow">
        {
           links.map( i=>
            <Link to={i.to} className="inline-block leading-none mt-4 p-2  rounded bg-red-600  lg:inline-block lg:mt-0 text-teal-200 hover:bg-red-400 mr-4">
            <FontAwesomeIcon icon={['fas', i.icon ]}   />
            </Link>
  
            )

        }
    </div>


    <div className=" bg-white">
      <DateTimePicker
    locale="es"   
    format="d/MM/y"
    clearIcon={null}
    onChange={onFechaChange}
    value={  moment(fecha).toDate()  }
    />


</div>

    <div className="flex text-sm  ">
    <Link to="/panel" className="inline-block leading-none mt-4 p-2  rounded bg-red-600  lg:inline-block lg:mt-0 text-teal-200 hover:bg-red-400">
     <FontAwesomeIcon  usuario={user.name} icon={['fas','user']}   /> 
      </Link>
      </div>
  
  
  </div>

  
</nav>


    )
}
