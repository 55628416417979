import React, {useState, useEffect} from 'react'
import ReactPlayer from 'react-player'
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import TotemProductos from "../../components/TotemProductos"
import PedidoTotem from "../../components/PedidoTotem"



import ProductoTotemDetail from '../../components/ProductoTotemDetail';



export default function MainLayout({history}) {



  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenSize])


/// 607

/// 500



  return (
    <div className="bg-black flex flex-col w-full h-screen">

            <div className="flex   overflow-hidden p-5" style={{height:'250px'}}>
                <div className="overflow-hidden rounded-lg">
                <ReactPlayer muted width={screenSize.width} height={600} loop  playing url='https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/mtz-video1.mp4' />
                </div> 
            </div>

            <div className="flex  flex-1 overflow-hidden">
                    <div className="relative  w-full">
                        <Route exact path="/totem/main/productos" component={ TotemProductos }></Route>
                        <Route exact path="/totem/main/producto/:id" component={ProductoTotemDetail}></Route>
                    </div>
          
            </div>

            <div 
            style={{height:'400px'}}
            className="flex p-5">

              {/* <h1 className="bg-gray-200">
              {screenSize.width}x{screenSize.height}
              </h1> */}

                <div className="bg-gray-300 rounded-lg w-full flex flex-col">
                        <PedidoTotem   gotoIntro={ ()=>history.push('/totem')  } />
                </div>


            </div>










    </div>
  )
}
