
import React, {  useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import Marcador from '../components/Marcador'
import axios from 'axios';
import { useSelector  } from 'react-redux';


import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from "react-places-autocomplete";


  import {
    selectZonas,
    selectSucursales
  } from '../features/masterSlice'


    const defaultZoom=12;

export default function DireccionPage(props) {

    const [coordinates, setCoordinates] = useState({lat:-25.3262016, lng:-57.5398399} );
    const [address, setAddress] = useState('');
    const [results, setResults] = useState(null);
    const [zoom, setZoom] = useState( defaultZoom );


    const [direccion, setDireccion] = useState('');
    const [referencias, setReferencias] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);

    const [maps, setMaps] = useState(null);
    const [map, setMap] = useState(null);
    const [sucursal, setSucursal] = useState(null);
    const zonasM = useSelector(selectZonas);
    const sucursales = useSelector(selectSucursales);


      // useEffect(() => {
      //   dibujarPoligonos();
      // }, [map, maps]);

      useEffect(() => {
//        console.log("dibujar poligonos", map, maps);
        if(map && maps && zonasM)dibujarPoligonos();
      }, [ map, maps, zonasM]);







      const onFormSubmit= e=>{
          e.preventDefault();


          let data= {
            ubicacion: {latitude:  coordinates.lat , longitude: coordinates.lng},
            calle: direccion,
            referencias:referencias,
            cliente_id: props.match.params.id,
            sucursal:sucursal
          }

          setSubmitting(true);

          axios.post(  `delivery/direccion`, data).then(
            (result)=>{
               console.log( "result axios counter", result);
               setSubmitting(false);
               props.history.replace(`/callcenter/cliente/${props.match.params.id}`);
               //history.replace('/callcenter'); //after saving email the user will be sent to Panel;
            }
        );  


      }


      const dibujarPoligonos=()=>{

        console.log("dibujarPoligonos");

          let ps=[];
          let zonas=zonasM.filter( i => i.extendido===  false);


          console.log( 'zonas', zonas);




      
    
          zonas.forEach( (z)=>{
            ps= ps.concat( z.features.filter( i => i.geometry.type==='Polygon' )    );
          });
    
          ps.forEach( (p) => {
                      console.log( p );            
                let paths= p.geometry.coordinates[0].map( i=> { return {lng:i[0], lat:i[1]} } );
    
                let s = sucursales.find((s)=>s.nombre==p.properties.nombre);
              if(s){
                let color = s.color;
                var poly = new maps.Polygon({
                  paths: paths,
                  nombre: p.properties.nombre,
                  strokeColor: color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: color,
                  fillOpacity: 0.35});
                   poly.setMap(map);
              }else{
                console.log("no se encuentra sucursal con nomre", p.properties.nombre);
              }
    
                // this.polygons.push( poly );
          });
      }



    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        setMap(map);  
        setMaps(maps);  

      };









      

      const getSucursalFromLocation=( u, permitirExtendido )=>{
        var sucursal=null;

        console.log( "getSucursalFromLocation ", u);
      
        let ps=[];
        let zonas=zonasM.filter( i => i.extendido==  permitirExtendido);
      
        zonas.forEach( (z)=>{
           ps= ps.concat( z.features.filter( i => i.geometry.type==='Polygon' )    );
        });
      




        ps.forEach( (p) => {
              let paths= p.geometry.coordinates[0].map( i=> { return {lng:i[0], lat:i[1]} } );
      
              let s = sucursales.find((s)=>s.nombre==p.properties.nombre);
              var poly = new maps.Polygon({
                paths: paths,
                nombre: p.properties.nombre});

                console.log("poly", poly );            

                
                if( maps.geometry.poly.containsLocation( new maps.LatLng(u.lat, u.lng)  ,poly))sucursal=s.nombre;
        });
      
        console.log("sucursal", sucursal);
        return sucursal;
      };
      
      




      const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);

        console.log( " resultado ", results);

        let s=getSucursalFromLocation( latLng, false );

        if(!s){
          setResults(null);
          setZoom(12);
          alert("La dirección ingresada no se encuentra en cobertura");
          return;
        }

        setZoom(17);
        setSucursal(s);
        setAddress(value);
        setCoordinates(latLng);
        setResults(results);

        setDireccion( results[0].formatted_address );
    

      };
    











    return (
        <div style={{ height: '100vh', width: '100%' }}>



<PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={  {
            
            componentRestrictions: {country: "py"}
          } }
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
                
                
            {
            !results? <div className="bg-gray-500 ">
              <input  
              
              {...getInputProps({ placeholder: "Ingrese dirección a buscar", className:'p-3 m-2 w-3/4  border text-xl' })} />


            </div>:

            <div className=" w-full  p-5">
           <form  onSubmit={ onFormSubmit }  className="w-full     ">
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-1/2  px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-address">
        DIRECCIÓN
      </label>
      <input value={direccion} onChange={(e)=> setDireccion(e.target.value)}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-address" type="text" placeholder="dirección "/>
    </div>

    <div className="w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-referencias">
        REFERENCIAS
      </label>
      <input value={referencias} onChange={(e)=> setReferencias(e.target.value)}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="referencias" type="text" placeholder="referencias"/>
    </div>

  </div>

  { isSubmitting?
    <p className='text-xl pb-5'>
            Aguarde...
    </p>:
  <div className="flex justify-center">
    <div className="flex md:w-1/2 ">

    <button className="shadow w-full bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mr-5" 
    onClick={()=>{setResults(null); setAddress(''); setZoom(12); }  }
    type="button">
        Atras
      </button>

      <button className="shadow w-full bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
        Registrar
      </button>
    </div>
  </div>  }
  
</form>

            </div>
          }

  

            <div>
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>


        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCjyZq8RSfrImlIfnf5Rhbq-IXRqA5Dh7s'}}
          defaultCenter={ {lat:-25.2830485, lng:-57.5687854}  }
          defaultZoom={ defaultZoom }
          zoom={zoom}
          center={coordinates}
          yesIWantToUseGoogleMapApiInternals={false}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          distanceToMouse={()=>{}}
          layerTypes={['TrafficLayer', 'TransitLayer']}

          onZoomAnimationEnd={ (z)=>setZoom(z) }
        >
          <Marcador
            lat={ coordinates.lat }
            lng={ coordinates.lng }
            text="M"
          />
        </GoogleMapReact>
      </div>
    )
}
