import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { useSelector} from 'react-redux';


import { selectPedido }
from '../features/pedidoSlice';

export default function DiMenu(props) {

    let history = useHistory();
    const pedido = useSelector(selectPedido);

  

   let colorBg= process.env.REACT_APP_ENV==='DEV'?'bg-black':'bg-red-700';  



    return (

        <div className={  `flex items-center justify-between w-full p-2  ${colorBg}   h-full w-1/12 flex-col` }>



         <div className="flex flex-col grid gap-5 w-full">
             <button className=" text-2xl py-1 bg-green-400 text-xl text-gray-900 font-semibold rounded"   
             onClick={ ()=> history.push(  `/drivein`  ) }
             >
            <FontAwesomeIcon  icon={['fas', 'home' ]}   />
            </button>

            <button   
            onClick={ ()=> history.push(  `/drivein/categorias`  ) }
            className=" text-2xl py-1  bg-gray-200 text-xl text-gray-900 font-semibold rounded "   >
            <FontAwesomeIcon  icon={['fas', 'hamburger' ]}   />
            </button>


            {
                pedido.productos.length>0 &&
                <button   
                onClick={ ()=> history.push(  `/drivein/pedido`  ) }
                className=" relative text-2xl py-1  bg-gray-200 text-xl text-gray-900 font-semibold rounded "   >
                    <FontAwesomeIcon  icon={['fas', 'clipboard-list' ]}   /> 
                    <div  style={ { right:-5, top:-8} }  class="absolute top-0 right-0 bg-green-400 p-1 rounded text-white text-xs"> { pedido.productos.length }  </div>
                </button>
            }

 


        </div>







        <div className="flex flex-col grid gap-5 w-full self-end">
 

<p  className="text-sm text-center text-white"  > 
{process.env.REACT_APP_VERSION} 
</p>


            <button   
            onClick={ ()=> history.push(  `/panel`  ) }
            className=" text-2xl py-1  bg-gray-200 text-xl text-gray-900 font-semibold rounded "   >
            <FontAwesomeIcon  icon={['fas', 'user' ]}   />
            </button>



        </div>







          </div>
    )
}
