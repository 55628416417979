import React, {useState, useEffect} from 'react'
import DateTimePicker from 'react-datetime-picker';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';
import TablaPedidos from '../components/TablaPedidos';





export default function SugerenciasPage(props) {

    const [res,setRes]=useState([]);
    const [loading,setLoading]=useState(false);






    useEffect(() => {
      
        refreshData();

    }, [])

 







    const refreshData = ()=>{
         

       setLoading(true);
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `sugerencias` ).then(
            (result)=>{
          
               setRes(result.data);
               setLoading(false);
            }
        ).catch( e=>{
          setLoading(false);
          
        } );        
    }







    return (


      <div className="w-full     flex  justify-center overflow-scroll  ">

      <div className="container  h-full    p-5 ">




<div className="justify-center p-5 bg-teal-500 border rounded mt-5">
 

{!loading ? <span onClick={()=>refreshData()} 
                 className="ml-3 hover:text-green-800 text-black"><FontAwesomeIcon icon={['fas','redo-alt']}   /></span>:'cargando...'}

 

 

</div>












<table className="table-auto w-full">
<thead>
<tr className="bg-indigo-200">
<th className="px-2 py-2">#</th>
<th className="px-2 py-2">Fecha</th>
<th className="px-2 py-2">Cliente</th>
<th className="px-2 py-2">Celular</th>
<th className="px-2 py-2">Sugerencia</th>
</tr>
</thead>
<tbody>

{
 res.map ((item,i)=>

<tr className="bg-gray-100"  key={item._id} >
<td className="border px-1 py-1"> { i+1 } </td>

<td className="border px-1 py-1">  <Moment  format="LLL" locale="es">{item.created_at}</Moment>   </td>
<td className="border px-1 py-1">{item.cliente && item.cliente.nombre}</td>
<td className="border px-1 py-1">{item.cliente && item.cliente.celular}</td>
<td className="border px-1 py-1"> {item.texto} </td>


</tr>
)


}


</tbody>
</table> 



 </div>
      </div>
    )
}
