import React, {useEffect, useState} from 'react'

import  FacturaDetalle from './FacturaDetalle'
import NumberFormat from 'react-number-format';

import { useSelector , useDispatch} from 'react-redux';

import { removeProducto }
from '../features/pedidoSlice';



export default function FacturaTotem({data}) {

    const [total, setTotal]=useState(0);
    const dispatch = useDispatch();

    useEffect( () => {
       
        let  total = data.productos.reduce((prev,next) => {
            return parseInt(prev) + parseInt(next.precio*next.cantidad);
        },0);
        setTotal( total );
     }, [data])



     const removeHandler= (data, indice)=>{
         if( data.nameFront==='Delivery')return;

         dispatch(  removeProducto( indice ) );
     };




    return (
        <div>
           
        {

            data.productos.map( (item,i)=> <FacturaDetalle key={i} removeHandler={removeHandler} data={item} indice={i} /> )
        }

        </div>
    )
}
