import React from 'react'
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProductoDetail from '../../components/ProductoDetail';
import MenuPage from './MenuPage';
import ProductoDiDetail from '../../components/ProductoDiDetail';
import DiMenu from '../../components/DiMenu';
import BotoneraCategoria from '../../components/BotoneraCategoria';
import Pedido from '../../components/Pedido';
import PedidoDi from '../../components/PedidoDi';
import PedidosDiPage from '../PedidosDiPage';


export default function CentralPage(props) {
    return (




<div className="w-full flex ">


<div className="w-16 ">
<DiMenu />
</div>



<div className="    w-full   h-full    overflow-auto  bg-gray-800  ">


<Route exact path="/drivein/" component={ProductoDetail}>
      <div className="w-full flex flex-col  h-full  p-5 ">

         <div className=" flex-1">
                  <div className="grid  grid-cols-2 h-full">

                  <button onClick={()=>props.history.push('/drivein/categorias')}  
                  className="  m-5  bg-gray-600 text-6xl text-white      font-bold py-2 px-4 rounded   items-center  ">
                  <span>
                    Productos
                  </span>
                  </button>

                  <button class=" m-5  bg-gray-600 text-6xl text-white     font-bold py-2 px-4 rounded  items-center  ">
                  <span>Ver Orden</span>
                  </button>
                        </div>

          </div>

          <div className="   flex-1">
          <div className="grid  grid-cols-2 h-full">
          <button class=" m-5  bg-gray-600 text-6xl text-white      font-bold py-2 px-4 rounded  items-center  ">
          <span>
            
          </span>
          </button>

          <button
          onClick={()=>props.history.push('/drivein/pedidos')}  
          class="m-5  bg-gray-600 text-6xl text-white    font-bold py-2 px-4 rounded  items-center  ">
          <span>Pedidos</span>
          </button>
                </div>
          </div> 
        
      </div>

</Route>


        <Route exact path="/drivein/categorias" component={ BotoneraCategoria }></Route>

        <Route exact path="/drivein/menu" component={ MenuPage }></Route>
        <Route exact path="/drivein/producto/:id" component={ProductoDiDetail}></Route>

        <Route exact path="/drivein/pedido" component={PedidoDi}></Route>

        <Route exact path="/drivein/pedidos" component={PedidosDiPage}  /> 


</div>



</div>




    )
}
