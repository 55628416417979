import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import masterReducer from '../features/masterSlice';
import pedidoReducer from '../features/pedidoSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    master: masterReducer,
    pedido: pedidoReducer
  },
});
