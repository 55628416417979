// import the library
import { library } from '@fortawesome/fontawesome-svg-core'


import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faExclamationTriangle, faHome, faTrash, faRedoAlt,
   faExchangeAlt, faEye, faCopy, faClock,
   faMotorcycle, faCar, faWrench, faBook, faBookOpen, faUserFriends, faArrowRight, faArrowLeft, 
   faPlus, faToggleOn, faToggleOff, faInfo, faArrowsAlt, faFlagCheckered, faThumbsUp, faGlasses, faTachometerAlt, faPhone, faList, faUser, faBars,
   faMinus, faCheck,faWindowClose, faHamburger, faClipboardList, faGlobe, faExclamationCircle


} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee, faExclamationTriangle, 
    faHome, faTrash, faRedoAlt, faExchangeAlt, faEye, faCopy, faClock, faMotorcycle, faCar, faWrench,
     faBook, faBookOpen, faUserFriends, faCheck,
     faArrowLeft, faArrowRight, faPlus, faMinus, faToggleOn, faToggleOff, faInfo, faArrowsAlt, faFlagCheckered,faThumbsUp, faGlasses, faTachometerAlt, faPhone, faList, faUser, faBars,
     faWindowClose, faHamburger, faClipboardList, faGlobe, faExclamationCircle
    )





