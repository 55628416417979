import React from 'react'
import NumberFormat from 'react-number-format';



export default function Producto({data, showPrice, addProductHandler, handlerClickProduct}) {







  const clickProducto = (p)=>{
   // console.log("click p", handlerClickProduct);
    if( handlerClickProduct )handlerClickProduct(p);
  }



    return (
<div className="max-w-xs bg-gray-200 shadow-lg rounded-lg overflow-hidden mb-5"  onClick={ ()=>clickProducto(data) } >
  <div className="px-4 py-2">
    <h3 className="text-gray-900 font-bold  truncate"> {data.nameFront} </h3>
    <p className="text-gray-600 text-sm mt-1"> </p>
  </div>
  <img className=" w-full object-cover mt-2" src={  data.url  } alt="ilustracion" />
  <div className="flex items-center justify-between px-4 py-2 bg-gray-900">
    {  showPrice && <h1 className="text-gray-200 font-bold text-xl">
    
    <NumberFormat value={data.precio} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
    </h1>}
    {addProductHandler && <button className="px-3 py-1 bg-gray-200 text-sm text-gray-900 font-semibold rounded"  onClick={()=> addProductHandler(data) } >Agregar</button>}
  </div>
</div>
    )
}
