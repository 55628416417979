import React from 'react'

export default function ProductoOption({producto, grupo, options,  clickOptionHandler}) {


    const selected = options.find(f=>f.addon.addon_name.label===grupo)  && options.find(f=>f.addon.addon_name.label===grupo).addon.addon_name.name;



    return (


<div className="border rounded p-2 bg-gray-400  ">
<p className="text-xl">{grupo}: 

{/* <span className="font-bold text-2xl"> {   options.find(f=>f.addon.addon_name.label===grupo)  && options.find(f=>f.addon.addon_name.label===grupo).addon.addon_name.name  }</span> */}

</p>
<div className="overflow-scroll pb-5">
                    <div className="inline-flex gap-2">

            
                    {
                        producto.options.filter(f=>f.option_group===grupo).map( i=>
                            <div
                            type="button"
                            onClick={()=> clickOptionHandler(i)    }
                            className={`w-24 cursor-pointer
                             ${ i.nameFront===selected?'border border-green-400 bg-green-400':'border border-gray-200'} 
                            border flex flex-col overflow-hidden `} key={ i.sku }   >
                                
                                <div 
                            style={{
                                backgroundImage: `url(${i.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}
                            className={`
                                
                                ${ i.nameFront===selected?'bg-green-400':'bg-gray-200'}   text-xs 
                            focus:outline-none text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24`} 
                           >
        
        </div> 
                                    <p className="text-xs p-1 ">
                                    { i.nameFront} { i.precio>0 && `(+${i.precio})`  }
                                    </p>
        </div>
                    )
                    
                }

                    
                    
                    </div>
                </div>
          </div>

    )
}
