import React, {useState, useEffect} from 'react'

export default function PedibossJob({reasignarHandler, pedido}) {

    const [jobId, setJobId] = useState( pedido?.responsePb?.data?.job_id )



    // useEffect(() => {

    //     console.log(
    //         "useEffect pedido responsePb", pedido?.responsePb
    //     )

    //   if(pedido?.responsePb){
    //     setJobId(pedido?.responsePb?.data?.job_id)
    //   }
    // }, [pedido])
    





    const reasignar = ()=>{
        
        if( 
            window.confirm(`¿Está seguro que desea reasignar el JOB ID PB a ${jobId}?`) 
         )        reasignarHandler(jobId)
    }


  return (
    <div className="p-3 bg-red-200 flex flex-col gap-3 max-w-sm">
        
            <p>Reasignar JOB ID PB</p>

            <input 
            className="p-3"
            type="text"
            value={jobId}
                onChange={(e) => setJobId(e.target.value)}
            placeholder="JOB ID PB"></input>
            <button 
                className="px-3 py-2 bg-red-500 text-white rounded"
            onClick={ reasignar }>Reasignar</button>
        
        </div>
  )
}
