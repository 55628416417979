import React  from 'react'
import Moment from 'react-moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';


export default function TablaPedidosIntegracion(props) {
    return (
        
        

<table className="table-auto w-full">
<thead>
<tr className="bg-indigo-200">
<th className="px-2 py-2">#</th>
<th className="px-2 py-2">Fecha</th>
<th className="px-2 py-2">Cliente</th>
<th className="px-2 py-2">Orden</th>
<th className="px-2 py-2">Monto</th>

<th className="px-2 py-2">Sucursal</th>

<th className="px-2 py-2">job_date_time</th>
<th className="px-2 py-2">Response</th>


</tr>
</thead>
<tbody>

{
props.data && props.data.map ((item,i)=>
{

    let clasePb ='';

    if(item.responsePb?.message){

        if(item.responsePb?.message && !item.responsePb?.data?.job_id)clasePb='bg-red-400'
        else clasePb = 'bg-green-200'

    }

    return (<tr className="bg-gray-100"  key={item._id} >
    <td className="border px-1 py-1"> { i+1 } </td>
    
    <td className="border px-1 py-1">  <Moment  format="LLL" locale="es">{item.created_at}</Moment>   </td>
    <td className="border px-1 py-1">{item.cliente.nombre} {item.cliente.apellido}</td>
    <td className="border px-1 py-1"> {item.ticket} </td>
    <td className="border px-1 py-1 text-right">    <NumberFormat value={ item.total } displayType={'text'} thousandSeparator={true} prefix={''} />   </td>
    
    <td className="border px-1 py-1"> {item.sucursal} </td>
    <td className="border px-1 py-1"> {item.payloadPb?.job_date_time} </td>
    <td className={`border px-1 py-1 ${clasePb}  `}> 
        <p>{item.responsePb?.message}</p>
        <p>{item.responsePb?.data?.job_id}</p>
     </td>
    
    
    
    </tr>)
}

)



}



</tbody>

</table>




    )
}
