import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Opciones from '../components/Opciones'
import Bebidas from '../components/Bebidas'
import { useSelector, useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'
import Modal from 'react-modal'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

import '../styles/checkbox.css'


import {
  selectBebidasSa,
  selectProductosDi,
  selectUser
} from '../features/masterSlice'

import { addProducto, selectPedido, setModalidad, setSucursal, setDireccion, setCliente, setFactura }
  from '../features/pedidoSlice'

import ProductoOptionTotem from './ProductoOptionTotem'
import DiMenu from './DiMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as swal from 'sweetalert2'


const customStylesMessageDialog = {
  content: {
    top: '800px',
    left: '240px',
    right: '240px',
    bottom: '800px',
    transform: 'none' // Elimina la transformación de la posición central
  }
}



export default function ProductoTotemDetail (props) {

  const dispatch = useDispatch()
  const location = useLocation()
  const [flagsSeleccion, setFlagsSeleccion] = useState({
    opciones: false,
    bebida: false,
    cafe: false,
    cono: false,
    cono2: false,
    papas: false,
    agranda: false,
    juguete: false
  })
  const {closeModal} = props
  const [data] = useState(    props.data)   // location.state.data 
  const productos = useSelector(selectProductosDi)
  const pedido = useSelector(selectPedido)
  const user = useSelector(selectUser)
  const [options, setOptions] = useState([])
//    const [tipo, setTipo] = useState('')
  const [preferences, setPreferences] = useState([])
  const [precioC, setPrecioC] = useState(data.precio)

  // const [comments, setComments] = useState('')
  const [juguete, setJuguete] = useState('')

  const [producto, setProducto] = useState({
    ...data, //...location.state.data,
    cantidad: 1,
    order_product_addons: [],
  })


  const [showMessageDialog, setShowMessageDialog] = useState({
    open:false,
    message:''
  })



  
  const mostrarMensaje = (message)=>{
    setShowMessageDialog({
        open:true,
        message:message
    })
  }


  const ocultarMensaje = ()=>{
    setShowMessageDialog({
        open:false,
        message:""
    })
  }

  useEffect(() => {

    console.log("props", props)
    console.log("data", data)

    //let p = data  //location.state.data

    let precioBase = data.precio

    // producto.cantidad
    setPrecioC(precioBase)

    console.log('producto', producto)

    let t = 'simple'
    if (producto.cajita && producto.cajita == true) t = 'cajita'
    if (producto.combo && producto.combo === true) t = 'combo'
    if (producto.promo && producto.promo === true) t = 'promo'
    if (producto.cafe && producto.cafe === true) t = 'cafe'
    if (producto.cono && producto.cono === true) t = 'cono'
    if (producto.cono2 && producto.cono2 === true) t = 'cono2'
    console.log('t', t)
    // setTipo(t);

    setProducto({ ...producto, precio: precioC, tipo: t })

    switch (t) {
      case 'cajita':
        setFlagsSeleccion({
          bebida: true, papas: false, agranda: false, juguete: true, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'combo':
        setFlagsSeleccion({
          bebida: true, papas: true, agranda: true, juguete: false, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'promo':
        setFlagsSeleccion({
          bebida: true, papas: false, agranda: true, juguete: false, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'cafe':
        setFlagsSeleccion({
          bebida: false, papas: false, agranda: false, juguete: false, cafe: true, cono: false,
          cono2: false
        })
        break

      case 'cono':
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: true,
          cono2: false
        })
        break

      case 'cono2':
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: true,
          cono2: true
        })
        break

      default:
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: false,
          cono2: false
        })
        break
    }

    if (producto.preferences.length > 0) setFlagsSeleccion(ps => {return { ...ps, opciones: true }})

  }, [data])




  useEffect(() => {
    let sumaAddons = 0

    if (options.length > 0) {
      sumaAddons = options.reduce((prev, i) => prev + parseInt(i.addon.price), 0)
    }

    setPrecioC(prev => parseInt(data.precio) + parseInt(sumaAddons))

  }, [options])

  const clickPreference = (o, v) => {

    console.log(o, v)

    // this.pedidoProvider.producto.opciones=this.opciones.filter( i=> i.value===true );

    // console.log( "opc", this.opciones, this.pedidoProvider.producto.opciones);

    let opc = JSON.parse(JSON.stringify(o))

    var index = preferences.findIndex(p => p.nombre === o.nombre)

    if (index === -1) {
      setPreferences(prev => [...prev, opc])
    } else {
      if (!v) setPreferences(preferences.filter(p => p.nombre !== opc.nombre))
    }

    console.log('preferences', preferences)

  }

  const clickOption = (item) => {

    let addOn = {
      id: item.sku,
      price: item.precio,
      addon_id: item.sku,
      addon: {
        id: item.sku,
        price: item.precio,
        addon_name: {
          id: item.sku,
          name: item.nameFront,
          label: item.option_group,
          description: item.option_group
        }
      }
    }

    let n = options.filter(f => f.addon.addon_name.label !== item.option_group)
    setOptions([...n, addOn])

  }

  useEffect(() => {

  }, [producto])

  useEffect(() => {
    setProducto({ ...producto, precio: precioC })

  }, [precioC])

  const handlerAceptar = () => {

    console.log('pedido', user, pedido)

//     if (pedido.modalidad !== 'drivein') {
//  //     let suc = user.funcionario.sucursales[0]
//       dispatch(setModalidad('drivein'))
//  //     dispatch(setSucursal(suc))
//    //   dispatch(setDireccion({ calle: 'DriveIn', referencias: suc, cliente_id: 0 }))
//       dispatch(setCliente({ nombre: '', celular: '', facturaNombre: '', facturaRuc: '' }))
//       dispatch(setFactura({ nombre: '', ruc: '' }))
//     }

    // if( !cliente ){
    //     mostrarMensaje("Debe seleccionar un cliente primero");
    //     return;
    // }
    if (flagsSeleccion.juguete && !juguete) {
      mostrarMensaje('Seleccione el juguete')
      return
    }
    if (flagsSeleccion.bebida && !options.find(f => f.addon.addon_name.label === 'Bebida')) {
      mostrarMensaje('Seleccione la bebida')
      return
    }
    if (flagsSeleccion.papas && !options.find(f => f.addon.addon_name.label === 'Papas')) {
      mostrarMensaje('Seleccione el tipo de papas')
      return
    }
    if (flagsSeleccion.agranda && !options.find(f => f.addon.addon_name.label === 'Agrandado')) {
      mostrarMensaje('Seleccione si es agrandado o no')
      return
    }

    if (flagsSeleccion.cafe && !options.find(f => f.addon.addon_name.label === 'Cafe')) {
      mostrarMensaje('Seleccione cafe')
      return
    }

    if (flagsSeleccion.cono && !options.find(f => f.addon.addon_name.label === 'Cono')) {
      mostrarMensaje('Seleccione sabor')
      return
    }
    if (flagsSeleccion.cono2 && !options.find(f => f.addon.addon_name.label === 'Cono2')) {
      mostrarMensaje('Sabores')
      return
    }

    let productoResultante = {
      producto: {
        ...producto,
        order_product_addons: options,
        preferences: preferences,
        opciones: preferences,
        comments: '' + juguete
      }, deliv: productos.find(i => i.nameFront === 'Delivery')
    }
    productoResultante.producto.options = []
    delete productoResultante.producto.options

    console.log('state', producto)
    dispatch(addProducto(productoResultante))
    console.log(' producto agregado', productoResultante)
    // props.history.push('/drivein/categorias')

    closeModal();

  }

  const handlerCancelar = () => {
    props.history.push('/callcenter/productos')
  }



  console.log("producto", producto)


  return (

    <div className="w-full h-full flex flex-col   gap-5 ">


      <div className="flex ">
        <img className=" object-cover rounded-lg  w-40 h-40 " src={data.url} alt="ilustracion"/>

        <div className="p-2 overflow-auto">
          <h3 className="text-gray-200  font-bold " style={{fontSize:"48px"}}> {data.nameFront} </h3>
          <p className="text-gray-300    mt-1" style={{fontSize:"24px"}}>{data.descripcion} </p>
        </div>
      </div>


      <div className="w-full  flex-1 justify-start       rounded-lg overflow-y-auto flex flex-col gap-5    ">


<div>

</div>

{flagsSeleccion.opciones &&
        <div className="  bg-gray-400 rounded p-2  ">
          <p className="p-3">Preferencias: </p>
          <ul className="inline-flex gap-5">
            {
              producto.preferences.map(i =>

                <label class="container">{i.nombre_descripcion}
                <input type="checkbox"  onChange={e => clickPreference(i, e.target.checked)}  />
                <span class="checkmark"></span>
              </label>


              )

            } </ul>
        </div>
        }


        {/* {flagsSeleccion.opciones &&
        <div className="  bg-gray-400 rounded p-2  ">
          <p className="p-3">Preferencias: </p>
          <ul className="inline-flex">
            {

              producto.preferences.map(i =>

                <p class="inline-flex"><label className="md:w-2/3 block text-gray-800 font-bold">
                  <input className="mr-2 leading-tight h-24" type="checkbox"
                         onChange={e => clickPreference(i, e.target.checked)}/>
                  <span className="text-2xl">
                            {i.nombre}
                            </span>
                </label></p>
              )

            } </ul>
        </div>
        } */}


        <div className=''>
            {flagsSeleccion.juguete &&

            <div className=" rounded  bg-gray-400  mb-5 p-2">


                <p className="text-xl">Juguete:
                <span className="font-bold text-2xl"> {juguete}</span>
                </p>

                <div className="inline-flex">

                <button className="bg-green-400   text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24"
                        onClick={() => setJuguete('niña')}>
                    Niña
                </button>

                <button className="bg-green-400   text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24"
                        onClick={() => setJuguete('niño')}>
                    Niño
                </button>
                </div>
            </div>

            }
            {flagsSeleccion.papas &&
            <ProductoOptionTotem grupo="Papas" clickOptionHandler={clickOption} producto={producto} options={options}/>}
            {flagsSeleccion.bebida &&
            <ProductoOptionTotem grupo="Bebida" clickOptionHandler={clickOption} producto={producto} options={options}/>}
            {flagsSeleccion.agranda &&
            <ProductoOptionTotem grupo="Agrandado" clickOptionHandler={clickOption} producto={producto} options={options}/>}
            {flagsSeleccion.cafe &&
            <ProductoOptionTotem grupo="Cafe" clickOptionHandler={clickOption} producto={producto} options={options}/>}
            {flagsSeleccion.cono &&
            <ProductoOptionTotem grupo="Cono" clickOptionHandler={clickOption} producto={producto} options={options}/>}
            {flagsSeleccion.cono2 &&
            <ProductoOptionTotem grupo="Cono2" clickOptionHandler={clickOption} producto={producto} options={options}/>}
        </div>


        <div className="text-gray-200 font-bold   text-right mt-auto " style={{fontSize:'42px'}}>
          <NumberFormat value={producto.cantidad * precioC} displayType={'text'} thousandSeparator={true}
                        prefix={'Gs. '}/>
        </div>


      </div>








      <div className="flex justify-end gap-5    w-full">



                <div className="flex    gap-2">
                   
                    <button className="bg-gray-300  text-2xl  px-8 py-6  hover:bg-gray-400 text-gray-800 font-bold   rounded-l focus:outline-none"
                            onClick={() => setProducto(prev => {
                                if (prev.cantidad !== 1) {
                                return {
                                    ...producto,
                                    cantidad: prev.cantidad - 1
                                }
                                } else {return { ...producto, cantidad: prev.cantidad }}
                            })}>
                        <FontAwesomeIcon icon={['fas', 'minus']}/>
                    </button>
                   
                        <div className="font-mono bg-white p-3 text-4xl px-6 flex justify-center flex-col"> 
                            <p className="">{producto.cantidad} </p>
                        </div>
                  
                    <button className="bg-gray-300 text-2xl px-8 py-6  hover:bg-gray-400 text-gray-800 font-bold  w-full rounded-r mr-5 focus:outline-none"
                            onClick={() => setProducto(prev => {return { ...producto, cantidad: prev.cantidad + 1 }})}>
                        <FontAwesomeIcon icon={['fas', 'plus']}/>
                    </button>
                </div>

                <div className="flex  gap-5    ">
                    <button className=" text-4xl px-8 py-6  bg-red-300 text-gray-900 font-semibold rounded  focus:outline-none "
                            onClick={() => closeModal()  }>
                        <FontAwesomeIcon icon={['fas', 'window-close']}/>
                        {/* <FontAwesomeIcon icon={['fas', 'arrow-left']}/> */}
                    </button>

                    <button className=" text-4xl px-8 py-6 bg-green-500  text-gray-900 font-semibold rounded focus:outline-none"
                            onClick={() => handlerAceptar()}>
                        <FontAwesomeIcon icon={['fas', 'check']}/>
                    </button>
                </div>


      </div>


      {/* <Dialog style={{ color: 'blue' }} isOpen={showMessageDialog.open} onDismiss={  ocultarMensaje }>
                <p className="m-5 text-2xl  text-center"> {showMessageDialog.message}  </p>
                <div className="flex">
                <button className="bg-green-200 border p-2 text-2xl outline-none w-32  h-24 focus:outline-none" onClick={ ocultarMensaje }>Aceptar
                </button>
                </div>
            </Dialog> */}


            <Modal
          ariaHideApp={false}
          isOpen={showMessageDialog.open}
          style={customStylesMessageDialog}
          contentLabel=""
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ ocultarMensaje }
        >

<div className="flex flex-col w-full justify-center items-center   h-full gap-8">

        <p className="text-3xl  text-center"> {showMessageDialog.message}  </p>
 
        <button className="bg-green-200 border p-2 text-2xl outline-none px-12   py-8 focus:outline-none" onClick={ ocultarMensaje }>Aceptar
        </button>

</div>


</Modal>



    </div>

  )
}
