import React from 'react'

export default function Opciones({data,selectHandler}) {


    const opcionChange=(r, e)=>{
        if( selectHandler )selectHandler(r, e.target.checked);
    }


    return (
        
  
        <ul>
        
        { data.receta.map( r=> <li>
        
            <label className="md:w-2/3 block text-gray-500 font-bold">
<input className="mr-2 leading-tight" type="checkbox" onChange={ e=>opcionChange(r,e)  } />
<span className="text-sm">
{r.nombre}
</span>
</label> </li>)}

        </ul>



    )
}
