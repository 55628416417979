import React, { useContext, useEffect, useState } from 'react';
import  Header   from './layout/Header'
// import { Counter } from './features/counter/Counter';
import {  useDispatch} from 'react-redux';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { useSelector } from 'react-redux';

import ApolloProvider from "./utils/apolloClient";



import DashboardPage from './pages/DashboardPage'
import PedidosPage from './pages/PedidosPage'
import ProductosPage from './pages/ProductosPage'
import ClientesPage from './pages/ClientesPage'
import AyudaPage from './pages/Ayuda'
import PrivateRoute from './components/PrivateRoute';

import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
 
import DireccionPage from './pages/DireccionPage';
import ClientePage from './pages/ClientePage';
import CallcenterPage from './pages/CallcenterPage';
import SignIn from './screens/SignIn';
import TotemSignIn from './screens/TotemSignIn';
import Panel from './screens/Panel';

import { authContext } from './contexts/AuthContext';
import PedidoPage from './pages/PedidoPage';
import DireccionAgendaPage from './pages/DireccionAgendaPage';
import AgendaPage from './pages/AgendaPage';
import EstadosPage from './pages/EstadosPage';
import SugerenciasPage from './pages/SugerenciasPage';
import MotoristasPage from './pages/MotoristasPage';
import LoadingOverlay from 'react-loading-overlay';

import {
  selectLoading,
  selectUser
} from './features/masterSlice';

import CentralPage from './pages/DriveIn/CentralPage';
import RouterTotem from './pages/Totem/Router';
import Menu from './pages/DriveIn/MenuPage';
import MenuPage from './pages/DriveIn/MenuPage';


function App(props) {

    const dispatch = useDispatch();

    const {  auth } = useContext(authContext);

    const isLoading=useSelector(selectLoading);
    const user=useSelector(selectUser);

    useEffect(() => {
      console.log("effect en appjs");


      const pusherClient = new Pusher( process.env.REACT_APP_PUSHER_KEY, {
        cluster:  process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      });

      console.log( "set pusher client", pusherClient);
      setPusherClient(pusherClient);
         


      window.initMap = initMap.bind(this);
    }, [props])


    const initMap=()=>{

      console.log("init map at appjs");
    }



    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
      return {
          width: window.innerWidth,
          height: window.innerHeight
      }
    }
  
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    
    
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])






  return (


   
<ApolloProvider  >
    <Router >

{isLoading &&<LoadingOverlay
  active={isLoading}
  spinner
text='Aguarde...'
  styles={{
      content:(base) => ({
          ...base,
          color:"black",
          textAlign:'center'
      }),
    wrapper: (base) => ({
      ...base,
      position:'absolute',
      background: 'rgba(180, 180, 180, 0.5)',
      width:"100vw",
      height:'100vh'

    })
  }}
  ></LoadingOverlay>}



    {                          auth.data ?


    <div className="flex flex-col  h-screen  ">
          

          {
             <Header   rol = {user.rol}  />
          }

          <ReactNotification />

          <div className="flex  justify-center flex-1 h-full pt-12">
                  <Switch>
                  <Route path="/sign-in" component={ SignIn } />
                      {/* <Route path="/" exact component={DashboardPage}></Route> */}
                      <Route path="/dashboard" exact component={DashboardPage}></Route>


                      <PrivateRoute path="/drivein"  component={CentralPage}></PrivateRoute>
                      
                      <PrivateRoute path="/totem"  component={RouterTotem}></PrivateRoute>




                      <PrivateRoute exact path="/estados" exact component={EstadosPage}></PrivateRoute>
                      <PrivateRoute exact path="/motoristas" exact component={MotoristasPage}></PrivateRoute>

                      <Route exact path="/sugerencias" exact component={SugerenciasPage}></Route>


                      <Route exact path="/pedidos" exact component={PedidosPage}></Route>
                      <Route exact path="/pedidos/:id" exact component={PedidoPage}></Route>

                      <PrivateRoute  path="/callcenter"  component={CallcenterPage}></PrivateRoute>
                      <Route path="/productos" exact component={ProductosPage}></Route>
                      <Route path="/clientes" exact component={ClientesPage}></Route>
                      <Route name="cliente" path="/clientes/:id" exact component={ClientePage}></Route>
                      
                      <Route exact path="/agenda" exact component={AgendaPage}></Route>
                      <Route exact path="/agenda/direccion" exact component={DireccionAgendaPage}></Route>

                      <Route exact path="/clientes" component={ClientesPage}></Route>

                      <Route path="/direccion" exact component={DireccionPage}></Route>
                      <Route path="/ayuda" exact component={AyudaPage}></Route>

                      <PrivateRoute path="/" component={Panel} />
                  </Switch>
          </div>
    </div> :


    <div>
      <Route path="/" component={SignIn}></Route>
      <Route path="/totemSignIn/:user/:pass/:mac" component={TotemSignIn}></Route>

    </div>

                             

    }




    </Router>
    </ApolloProvider>
  );
}

export default App;
