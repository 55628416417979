import React from 'react'
import dayjs from 'dayjs'

export default function FleetDetail({data}) {


    // data.pb_pickup_started_datetime
    // data.cinet_onProduction_date
    // data.cinet_ready_date
    // data.pb_delivery_started_datetime
    // data.pb_delivery_arrived_datetime


    return (
<table className="table-auto w-full">
  {/* <thead>
    <tr>
      <th className="px-2 py-2">Event </th>
      <th className="px-2 py-2">Fecha</th>
    </tr>
  </thead> */}
  <tbody>


              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Nombre</td>
                <td className="border px-2 py-1"> { `${data.first_name} ${data.last_name} ` }</td>
              </tr>

              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Teléfono</td>
                <td className="border px-2 py-1"> { `${data.phone}` }</td>
              </tr>


              {/* <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Teléfono</td>
                <td className="border px-2 py-1">  <img src={data.fleet_thumb_image} />  </td>
              </tr> */}

  </tbody>
</table>
    )
}
