import React, {useContext, useEffect, useState } from "react";
import { authContext } from "../contexts/AuthContext";
import axios from 'axios'

import { useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";

import { selectUser }
from '../features/masterSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setIn } from "formik";




const Panel = () => {
  const { setAuthData, auth, user } = useContext(authContext);
  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [stores, setStores] = useState([])
//  const user = useSelector( selectUser );


  const refresh = () => {

    setLoading(true);
    axios.get('delivery/test_vpn').then( res=> {
      if(res?.status===200){
        console.log('vpn', res.data)
        setStores(res.data?.sucursalesWithVpn  || [] )

      }else{
        console.log('status not 200', res?.status)
      }
    }).catch( e=> console.log('vpn', e) ).finally( ()=> setLoading(false) )
      

  }



  const connectVpn = ()=>{

    setLoading(true);
    axios.get('delivery/connect_vpn').then( res=> {
      if(res?.status===200){
        console.log('vpn', res.data)
        setStores(res.data?.sucursalesWithVpn  || [] )

      }else{
        console.log('status not 200', res?.status)
      }
    }).catch( e=> console.log('vpn', e) ).finally( ()=> setLoading(false) )
      

  }


  useEffect(() => {
    refresh()
    const intervalo = setInterval( ()=> refresh(), 60000)

    return ()=>{
      clearInterval(intervalo)
    }

  }, [])
  




  const onLogOut = () => {
    setAuthData(null);
  } //clearing the context
  return (

    <div className="overflow-scroll relative w-full">
          <div className="absolute w-full p-3">
          <div className=" w-full gap-5 flex flex-col md:flex-row  justify-center ">
          <div className="bg-white">
            <div className="text-center p-6  border-b">
              {/* <img
                className="h-24 w-24 rounded-full mx-auto"
                src="https://randomuser.me/api/portraits/men/24.jpg"
                alt="Randy Robertson"
              /> */}
              <p className="pt-2 text-xs"> {user.rol} </p>
              <p className="text-lg font-semibold"> {user.name} </p>
              <p className="text-sm text-gray-600 mb-5">{user.email} </p>

              <div className="grid grid-cols-3 gap-2 mb-5">
                  {
                    user.funcionario && user.funcionario.sucursales.map( i=>
                      <div className="rounded   border border-gray-300 p-1 text-sm text-gray-600">  {i} </div>
                  )
                  }
              </div>


<h1 className="text-2xl text-center font-bold">
{process.env.REACT_APP_VERSION} 
</h1>

<h1 className="text-3xl text-center font-bold">
  AMBIENTE {process.env.REACT_APP_ENV} 
</h1> 

{/* <p>
  {JSON.stringify(user)}
</p> */}


                  <div className="grid grid-cols-2">
             { 
             user.rol==='drivein'  &&
             
             <button   
            onClick={ ()=> history.push(  `/drivein`  ) }
            className=" text-5xl p-5  bg-green-200 text-xl text-gray-900 font-semibold rounded m-5"   >
            <FontAwesomeIcon  icon={['fas', 'home' ]}   />
            </button>}


            { 
             user.rol==='totem'  &&
             
             <button   
            onClick={ ()=> history.push(  `/totem`  ) }
            className=" text-5xl p-5  bg-green-200 text-xl text-gray-900 font-semibold rounded m-5"   >
            <FontAwesomeIcon  icon={['fas', 'home' ]}   /> TOTEM
            </button>}


            {/* <button   
           onClick={()=> { window.location.reload(true); }  }
            className=" text-xl p-5  bg-green-200 text-xl text-gray-900 font-semibold rounded mb-5 m-5 "   >
            <FontAwesomeIcon  icon={['fas', 'wrench' ]}   /> Actualizar
            </button>*/}


            </div> 



              <div className="mt-5">

                  <div className="flex gap-3">
                      <a
                      href="#"
                      onClick={()=> { window.location.reload(true); }  }
                      className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-700 bg-blue-200"
                    >
                      Actualizar
                    </a>

                      <a
                      href="#"
                      onClick={onLogOut}
                      className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-700 bg-red-200"
                    >
                      Salir
                    </a>
                  </div>

              </div>




            </div>
            {/* <div className="border-b">
              <a href="#" className="px-4 py-2 hover:bg-gray-100 flex">
                <div className="text-gray-800">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    className="w-5 h-5"
                  >
                    <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-medium text-gray-800 leading-none">Add members</p>
                  <p className="text-xs text-gray-500">Add/manage users &amp; teams</p>
                </div>
              </a>
              <a href="#" className="px-4 py-2 hover:bg-gray-100 flex">
                <div className="text-gray-800">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    className="w-5 h-5"
                  >
                    <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-medium text-gray-800 leading-none">Account settings</p>
                  <p className="text-xs text-gray-500">Usage, billing, branding, teams</p>
                </div>
              </a>
              <a href="#" className="px-4 py-2 hover:bg-gray-100 flex">
                <div className="text-gray-800">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    className="w-5 h-5"
                  >
                    <path d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-medium text-gray-800 leading-none">Personal settings</p>
                  <p className="text-xs text-gray-500">Email, profile, notifications</p>
                </div>
              </a>
              <a href="#" className="px-4 py-2 hover:bg-gray-100 flex">
                <div className="text-green-600">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    className="w-5 h-5"
                  >
                    <path d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                  </svg>
                </div>
                <div className="pl-3">
                  <p className="text-sm font-medium text-gray-800 leading-none">
                    Apps &amp; integrations
                  </p>
                  <p className="text-xs text-gray-500">Google, slack, mail</p>
                </div>
              </a>
            </div> */}
    
            
          </div>



          <div className="bg-white">
            <div className="text-center p-6 ">
        

              <div className=" w-full bg-gray-100">

                <table className=" w-full">
                  <thead>
                    <tr className=" bg-blue-200">
                      <th className="px-4 py-2">Sucursal</th>
                      <th className="px-4 py-2">VPN</th>
                      <th className="px-4 py-2">ID CONF</th>
                      <th className="px-4 py-2">ID DB</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    loading?
                    <tr>
                      <td colSpan={4}>
                        <div className="h-64 flex items-center justify-center">
                          <p>Loading...</p>
                        </div>
                      </td>
                    </tr>
                    :
                    stores.map( i=>
                      <tr>
                        <td className="border px-1 text-left"> {i.storeData.nombre} </td>
                        <td className="border px-1"> { i.connected?
                            <button onClick={refresh}>   
                            <div className="flex gap-1">
                                <FontAwesomeIcon  icon={['fas', 'globe' ]}  color='blue'  />
                            </div>
                            </button>
                        :
                          <button onClick={connectVpn}>   
                            <div className="flex gap-1">
                              <FontAwesomeIcon  icon={['fas', 'globe' ]} color='red'  />
                              <FontAwesomeIcon  icon={['fas', 'exclamation-circle' ]} color='red'  />
                            </div>
                            </button>

                        }</td>
                        <td className="border px-1"> {i.storeData.cinetIdentifier} </td>
                        <td className="border px-1"> {i.cinetName} { i.cinetName !== i.storeData.cinetIdentifier && <FontAwesomeIcon  icon={['fas', 'exclamation-circle' ]} color='red'  /> } </td>
                      </tr>
                  )
                  }
                  </tbody>
                </table>



              </div>
            </div>
            
          </div>








        </div>
          </div>

    </div>
   
  );
};

export default Panel;