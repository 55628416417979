import React, {useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export default function Addon({data}) {


    useEffect(() => {
     console.log("addon cmp", data.addon);
    }, [])



    let render='';

    if( !data.addon )return '';

    switch( data.addon.addon_name.label ){
        case 'Bebida':            
        render= <div className="inline-flex mr-3  border rounded border-gray-300 p-1">
              <img src="/soda.svg"   height="100%" alt="bebida" className="block mr-1 h-4"/>  <span className="uppercase text-xs"> {data.addon.addon_name.name} </span>   
          </div>      
        break;


        case 'Papas':
                render= <div className="inline-flex mr-3  border rounded border-gray-300 p-1">
                <img src="/fries.svg" height="100%" alt="bebida" className="block mr-1 h-4"/>  <span className="uppercase text-xs"> {data.addon.addon_name.name} </span>   
            </div>      
          
        break;



        case 'Agrandado': 
      
         data.addon.addon_name.name=='si'?
        render= <div className="inline-flex mr-3  border rounded border-gray-300 p-1">
             <span className="uppercase text-xs font-bold"> AGRANDADO </span>   
        </div>:  render=''        
            
        break;

        default:
         render= <div className="inline-flex mr-3  border rounded border-gray-300 p-1">
                <span className="uppercase text-xs font-bold"> {data.addon.addon_name.label}:{data.addon.addon_name.name} </span>   
           </div>
        break;


    }

    return  render
}
