import React, {useState, useEffect} from 'react'
import { useSelector} from 'react-redux';

import ProductoTotem from './ProductoTotem'
import ProductoTotemDetail from './ProductoTotemDetail'




import Modal from 'react-modal'


import {
    selectListaProductosDi
  } from '../features/masterSlice'

  import {
    selectPedido
  } from '../features/pedidoSlice'




//   const customStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)'
//     }
//   }


const customStyles = {
    content: {
      backgroundColor: '#2d3748',
      top: '100px',
      left: '20px',
      right: '20px',
      bottom: '100px',
      transform: 'none' // Elimina la transformación de la posición central
    }
  }


export default function TotemProductos(props, { addProductHandler,  showPrice}) {


    const [openModal, setOpenModal] = useState( {open:false, data:null} )
    const [categoria, setCategoria] = useState(null)
    const [productosGroup, setProductosGroup] = useState([])

    const productos = useSelector(selectListaProductosDi);
    const pedido = useSelector(selectPedido);
    const [items,setItems]=useState([]);
//    const [clear,setClear]=useState(false);





    const filtrar =()=>{
        let term = ''
        let filtered=[];  
        if( term !==""){
            filtered= productos.filter( (a)=>{
            return (a.nameFront.toUpperCase().search(term.toUpperCase())  !== -1) || a.grupo.toUpperCase().search(term.toUpperCase())  !== -1 ; 
            });
        }else
        {
            filtered= productos.filter( (a)=>{
            return true 
            });   
        }

        filtered= filtered.filter( i=> i.grupo!=='Delivery');

            let a={};
            filtered.forEach( i=>   {  if(!a[i.grupo])a[i.grupo]=[];    a[i.grupo].push(i) } );

            let b=[];
            for (const property in a) {
                b.push( {nombre:property, productos:a[property]} );
            }
        setItems( b );
    }





    const filtrarPorCategoria =( term )=>{

        
        const filtered= productos.filter( (a)=>{
            console.log("a", a.grupo.toUpperCase(), term );
           return  a.grupo.toUpperCase().search( term.toUpperCase() )  !== -1 ; 
        });

        setProductosGroup( filtered );
        // console.log("term ", term);
        // let filtered=[];  
        // if( term !==""){
        //     filtered= productos.filter( (a)=>{
        //         console.log("a", a.grupo.toUpperCase(), term );
        //     return  a.grupo.toUpperCase().search( term.toUpperCase() )  !== -1 ; 
        //     });
        // }else
        // {
        //     filtered= productos.filter( (a)=>{
        //     return true 
        //     });   
        // }

        // filtered= filtered.filter( i=> i.grupo!=='Delivery');

        //     let a={};
        //     filtered.forEach( i=>   {  if(!a[i.grupo])a[i.grupo]=[];    a[i.grupo].push(i) } );

        //     let b=[];
        //     for (const property in a) {
        //         b.push( {nombre:property, productos:a[property]} );
        //     }
        //     setProductosGroup( b );
    }



    useEffect(() => {
      if(items.length>0){
        console.log("items", items[0] )
        const index = items.findIndex( i=> i.nombre.toLowerCase() ==='carnes' );
        setCategoria(items[index].nombre)
      }
    }, [items])
    


    useEffect(() => {
       if(categoria) filtrarPorCategoria(categoria)
    }, [categoria])
    
 
    useEffect( () => {
        filtrar();    
    }, [])


    useEffect( () => {
       console.log("use effects products");
       filtrar();    
    }, [productos])


    const handlerClickCategoria = (n)=>{
        console.log("n", n);
        setCategoria(n);
        //props.history.push(  `/drivein/menu`, {categoria:n}  );
    }




    const getImageCat = (n)=>{
 
        switch(n.toUpperCase()){

            case 'CARNES':  return  <img src="/menu/carne.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'LIVIANOS':  return  <img src="/menu/salad.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'POLLO':  return  <img src="/menu/pollo.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'CAJITAS':  return  <img src="/menu/children.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'PAPAS':  return  <img src="/menu/fries.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'BEBIDAS':  return  <img src="/menu/soda.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'POSTRES':  return  <img src="/menu/ice-cream.svg" className="block mr-1 h-16 " alt="bebida" />;


        }



    }



    const closeProductDetailModal = ()=>{
        setOpenModal({open:false, data:null})
    }



    const handlerClickProduct = (p)=>{
        console.log("add product ", p, props);


//        props.history.push(  `/totem/main/producto/${p.sku}`, {data:p}  );

        setOpenModal({
            open:true,
            data:{...p}
        })


    }



    return (


       


<div className="w-full   h-full flex gap-5 p-5">





        <Modal
          ariaHideApp={false}
          isOpen={openModal.open}
          style={customStyles}
          contentLabel="Detalles del producto"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ closeProductDetailModal }
        >

          <div className=" flex   h-full justify-between  w-full  bg-gray-800  ">

                <ProductoTotemDetail     data={openModal.data}  closeModal = {closeProductDetailModal}   />

          </div>


        </Modal>


        








<div className="    h-full grid grid-cols-1  gap-5     overflow-auto">
    {items.map(c => 
    <div  onClick={  ()=>handlerClickCategoria(c.nombre) }   key={c.nombre}
    className="rounded   content-center justify-center  text-white     flex  flex-col bg-gray-600  p-1 ">
                {  getImageCat(c.nombre) }  
            <p className="font-bold   sm:text-xs   md:text-xl mb-2 uppercase text-center   ">{c.nombre.substr(0,12)} </p>
    </div>
    )}
</div>


    <div className=" flex-1    overflow-auto">
        <p className="text-4xl text-white">
        {categoria}
        </p>



        <div className="grid  sm:grid-cols-3  md:grid-cols-3   gap-5">
        {
            productosGroup.map(el => 
            <ProductoTotem   
             handlerClickProduct={handlerClickProduct} 
            //  addProductHandler={addProductHandler} 
             showPrice={true}   
             key={el.sku} data={el} /> )
        }
        </div>

    </div>




</div>
      


        )
}
