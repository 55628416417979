import React, {useEffect} from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';

import Pedido from '../components/Pedido'
import ClientesPage from './ClientesPage';
import Productos from '../components/Productos';
import axios from 'axios';

import {
    selectCliente, recreate
  } from '../features/pedidoSlice'
import ProductoDetail from '../components/ProductoDetail';
import ClientePage from './ClientePage';
import DireccionPage from './DireccionPage'
import NuevoClientePage from './NuevoClientePage';

export default function CallcenterPage(props) {

    const cliente = useSelector(selectCliente);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
      console.log("viene con id de pedido", location.state);


        if( location.state && location.state.id ){

            axios.get(  `delivery/pedido/${location.state.id}`).then(
                (result)=>{
                   console.log( "result ", result);
                   dispatch( recreate(result.data) );

                }
            ).catch(error => {
                console.log("error ", error.response.data.message);
            });;  


        }


      
    }, [props])




    return (
<div className="flex w-full">
        <div className="flex  flex-col bg-red-200  w-3/5">

 


            <Route exact path="/callcenter" >
                 {cliente ? <Redirect to="/callcenter/productos" /> : <Redirect to="/callcenter/cliente" />}
            </Route>

        <Route exact path="/callcenter/cliente" component={ClientesPage}></Route>
        <Route exact path="/callcenter/productos" component={Productos}></Route>
        <Route exact path="/callcenter/productos/:id" component={Productos}></Route>
        <Route exact path="/callcenter/producto/:id" component={ProductoDetail}></Route>
        <Route exact path="/callcenter/cliente/:id"  component={ClientePage}></Route>
        <Route exact path="/callcenter/nuevo/cliente"  component={NuevoClientePage}></Route>
        <Route exact path="/callcenter/direccion/:id"  component={DireccionPage}></Route>

        </div>


        <div className="bg-red-300 p-5  overflow-scroll w-2/5">
 

        {
             cliente ? <Route path="/callcenter" component={Pedido}></Route>:
             <div>
                <button className="outline-none px-3 py-1 bg-gray-200 text-sm text-gray-900 font-semibold rounded text-2xl w-full"  
                 onClick={  ()=>props.history.push('/callcenter/cliente') } >Iniciar orden</button>
            </div>
        }
        

        </div>
        </div>

    )
}
