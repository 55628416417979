import React, {useState} from 'react'

import Menu2 from '../components/Menu2'
import Pedido from '../components/Pedido'
import {   Switch, Route } from 'react-router-dom';
import ClientesPage from './ClientePage'

export default function ProductosPage() {



    const [query,setQuery]=useState('');


    const [step,setStep]=useState(1);





    return (

<div className="flex w-full">
        <div className="flex flex-col bg-red-400 p-5  w-full">

            <input  value={query} onChange={  (e)=> { setQuery(e.target.value); }   }
            className="mb-5 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" 
            placeholder="Buscar..." type="text"></input>

<div className=" flex-grow overflow-scroll">




    <Menu2 term={query} />
</div>

        </div>

        </div>



    )
}
