import React, {useContext} from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';




import {
    resetTotem
  } from '../../features/pedidoSlice'
import { authContext } from "../../contexts/AuthContext";


export default function Intro(props) {


    const {  auth } = useContext(authContext);

    const dispatch = useDispatch()

    const iniciarPedido =()=>{

        // props.history.push('/totem/main/productos')
        props.history.push('/totem/dondeComer')
        dispatch(resetTotem())
    }



    return (




<div className="w-full flex ">


{/* <div className="w-16 ">
<DiMenu />
</div> */}



        <div className="    w-full   h-full    overflow-auto bg-black   flex  ">

        <div className="absolute text-white" style={{ top:'10px', right:'10px', fontSize:'20px' }}>
                {auth.decoded.mac}
        </div>

        <div className="bg-neutral-800 flex-1 flex flex-col ">
            <div className="py-8 bg-red-700 flex flex-col justify-center text-center">
                    <p className="font-bold " style={{fontSize:'8em', fontFamily:"Patua One"}}>¡PEDÍ ACÁ!</p>
                    <p className=" " style={{fontSize:'6em' }}>RAPIDO Y FACIL</p>
            </div>

            <div className="flex-1 flex flex-col justify-center">
                    <ReactPlayer  muted width={1080} height={607} loop  playing url='https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/mtz-video1.mp4' />          
            </div>

            <div className="flex">
                <div className="flex justify-center p-12">
                        <img className="w-32" src="https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/mostazalogoW.svg"  />
                </div>
                <div className="flex-1 flex   justify-center items-center p-12">
                    <button 
                    type="button"
                    style={{borderRadius: '1.2em'}}
                    onClick={ iniciarPedido }
                    className=" text-white text-6xl  px-8 py-6 border-4 border-white  outline-none   ">INICIAR PEDIDO</button>
                </div>
            </div>
        </div>

 

        {/* <Route exact path="/drivein/" component={ProductoDetail}>
            <div className="w-full flex flex-col  h-full  p-5 ">

                <div className=" flex-1">
                        <div className="grid  grid-cols-2 h-full">

                        <button onClick={()=>props.history.push('/drivein/categorias')}  
                        className="  m-5  bg-gray-600 text-6xl text-white      font-bold py-2 px-4 rounded   items-center  ">
                        <span>
                            Productos
                        </span>
                        </button>

                        <button class=" m-5  bg-gray-600 text-6xl text-white     font-bold py-2 px-4 rounded  items-center  ">
                        <span>Ver Orden</span>
                        </button>
                                </div>

                </div>

                <div className="   flex-1">
                <div className="grid  grid-cols-2 h-full">
                <button class=" m-5  bg-gray-600 text-6xl text-white      font-bold py-2 px-4 rounded  items-center  ">
                <span>
                    
                </span>
                </button>

                <button
                onClick={()=>props.history.push('/drivein/pedidos')}  
                class="m-5  bg-gray-600 text-6xl text-white    font-bold py-2 px-4 rounded  items-center  ">
                <span>Pedidos</span>
                </button>
                        </div>
                </div> 
                
            </div>

        </Route>


        <Route exact path="/drivein/categorias" component={ BotoneraCategoria }></Route>
        <Route exact path="/drivein/menu" component={ MenuPage }></Route>
        <Route exact path="/drivein/producto/:id" component={ProductoDiDetail}></Route>
        <Route exact path="/drivein/pedido" component={PedidoDi}>          
        </Route>
        <Route exact path="/drivein/pedidos" component={PedidosDiPage}  />  */}


</div>



</div>




    )
}
