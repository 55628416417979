import React from 'react'
import moment from 'moment'

export default function Estados({data}) {
    return (
<table className="table-auto w-full">
  <thead>
    <tr>
      <th className="px-2 py-2">HORA</th>
      <th className="px-2 py-2">ESTADO</th>
      <th className="px-2 py-2">USUARIO</th>
    </tr>
  </thead>
  <tbody>

        {
            data.map( (item,index)=>


              <tr  className={  index%2 ?'bg-gray-200':'bg-gray-100' } >
                <td className="border px-2 py-1"> { moment(item.created_at).format('HH:mm:ss') }</td>
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold "> { item.actual } </td>
                <td className="border px-2 py-1  uppercase    text-gray-700   "> { item.usuario ? item.usuario.name:'-' } </td>
              </tr>

            )


        }



  </tbody>
</table>
    )
}
