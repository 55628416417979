import React , {useEffect}  from 'react'
import { useSelector } from 'react-redux';

import { motion, useAnimation } from "framer-motion";

import {
     selectSucursales
      } from '../features/masterSlice';


export default function SucursalMCount({data}) {

    const sucursales=useSelector(selectSucursales);
    const controls = useAnimation()

    const getColor=(sn)=>{

        let s= sucursales.find( i=> i.nombre===sn);
        if( s )return s.color;
        else return '#fff';

    }


    useEffect(() => {
        efecto1();
    }, [data.l])


    useEffect(() => {
        efecto1();
    }, [data.c])


    const efecto1=async ()=>{
        // initial={{ scale: 0 }}
        // animate={{ rotate: 180, scale: 1 }}

        await controls.start({scale:0.1, rotate:-90, opacity:0, transition: { duration: 0 }});

        await controls.start({
            scale: 2,
            rotate:0,
            opacity:1,
            translateX:20,
            transition: {             
                duration: 0.5
            }
        })

        await controls.start({
            scale: 1,
            rotate:0,
            opacity:1,
            translateX:0,
            transition: {             
                duration: 0.3
            }
        })


    }


    return (

        <motion.div
        initial={false}
        animate={ controls }
      >
<div className="p-0 w-8 h-8 flex flex-col justify-center bg-blue-900 rounded   active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none text-center mr-1" 
             style={{backgroundColor: getColor(data.n) }}>

                <p className="text-white font-bold"> { data.l }  </p>
            </div>   
            </motion.div>
    )
}
