import React, {useState, useEffect} from 'react'
import { useSelector} from 'react-redux';




import {
    selectListaProductosDi
  } from '../features/masterSlice'

  import {
    selectPedido
  } from '../features/pedidoSlice'



export default function BotoneraCategoria(props, { addProductHandler,  showPrice}) {


    const productos = useSelector(selectListaProductosDi);
    const pedido = useSelector(selectPedido);
    const [items,setItems]=useState([]);
//    const [clear,setClear]=useState(false);





    const filtrar =()=>{
        let term = ''
        let filtered=[];  
        if( term !==""){
            filtered= productos.filter( (a)=>{
            return (a.nameFront.toUpperCase().search(term.toUpperCase())  !== -1) || a.grupo.toUpperCase().search(term.toUpperCase())  !== -1 ; 
            });
        }else
        {
            filtered= productos.filter( (a)=>{
            return true 
            });   
        }

        filtered= filtered.filter( i=> i.grupo!=='Delivery');

            let a={};
            filtered.forEach( i=>   {  if(!a[i.grupo])a[i.grupo]=[];    a[i.grupo].push(i) } );

            let b=[];
            for (const property in a) {
                b.push( {nombre:property, productos:a[property]} );
            }
        setItems( b );
    }


 
    useEffect( () => {
        filtrar();    
    }, [])


    useEffect( () => {
       console.log("use effects products");
       filtrar();    
    }, [productos])


    const handlerClickCategoria = (n)=>{
        console.log("n", n);
        props.history.push(  `/drivein/menu`, {categoria:n}  );
    }




    const getImageCat = (n)=>{
 
        switch(n.toUpperCase()){

            case 'CARNES':  return  <img src="/menu/carne.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'LIVIANOS':  return  <img src="/menu/salad.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'POLLO':  return  <img src="/menu/pollo.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'CAJITAS':  return  <img src="/menu/children.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'PAPAS':  return  <img src="/menu/fries.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'BEBIDAS':  return  <img src="/menu/soda.svg" className="block mr-1 h-16 " alt="bebida" />;
            case 'POSTRES':  return  <img src="/menu/ice-cream.svg" className="block mr-1 h-16 " alt="bebida" />;


        }



    }




    return (


       


<div className="w-full   h-full grid grid-cols-3  gap-8 shadow-lg  p-5 bg-gray-800">



{items.map(c => 


<div  onClick={  ()=>handlerClickCategoria(c.nombre) }   key={c.nombre}
 className="rounded  shadow   content-center justify-center  text-white bg-gray-600   flex  flex-col bg-gray-300  ">

 
            {  getImageCat(c.nombre) }  
  


        <p className="font-bold text-4xl mb-2 uppercase text-center   ">{c.nombre} </p>
</div>
)}





{
pedido.productos.length > 0 &&
<div  onClick={  ()=>props.history.push('/drivein/pedido')  }   className="rounded  shadow   content-center justify-center    flex  flex-col bg-gray-500  ">
        <p className="font-bold text-4xl mb-2 uppercase text-center   ">Ver pedido </p>
</div>}


</div>
      


        )
}
