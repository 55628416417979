import React,{ Component } from 'react'
import axios from 'axios';

import { store as notificador } from 'react-notifications-component';
import Pusher from 'pusher-js';
import Modal from 'react-modal';

import Sound from 'react-sound'



import ListaEstado from "../components/ListaEstado"
import PedidoPage from './PedidoPage';

import { connect } from 'react-redux';
import ToolsMonitoreo from '../components/ToolsMonitoreo';

import {
    setSucursalesCount
      } from '../features/masterSlice';


const styles = {
    a:{background: "blue",
    borderRadius: 30,
    width: 150,
    height: 150,
    margin: "auto"}
  };





  const customStyles = {
    content : {
        padding:0
    }
  };
  


  const KpollTime=60000;


  let pusher;





const  TituloSeccion = ({children,refreshHandler})=>(<div className=" flex justify-between items-center w-full p-2 gap-2">
      <button onClick={() => refreshHandler()}
              className="w-8 h-8 bg-blue-900   rounded flex justify-center items-center  focus:shadow-lg focus:outline-none focus:ring-0    ">
        <svg viewBox="0 0 20 20" enable-background="new 0 0 20 20" className="w-6 h-6 inline-block">
          <path fill="#FFFFFF"
                d="M12.319,5.792L8.836,2.328C8.589,2.08,8.269,2.295,8.269,2.573v1.534C8.115,4.091,7.937,4.084,7.783,4.084c-2.592,0-4.7,2.097-4.7,4.676c0,1.749,0.968,3.337,2.528,4.146c0.352,0.194,0.651-0.257,0.424-0.529c-0.415-0.492-0.643-1.118-0.643-1.762c0-1.514,1.261-2.747,2.787-2.747c0.029,0,0.06,0,0.09,0.002v1.632c0,0.335,0.378,0.435,0.568,0.245l3.483-3.464C12.455,6.147,12.455,5.928,12.319,5.792 M8.938,8.67V7.554c0-0.411-0.528-0.377-0.781-0.377c-1.906,0-3.457,1.542-3.457,3.438c0,0.271,0.033,0.542,0.097,0.805C4.149,10.7,3.775,9.762,3.775,8.76c0-2.197,1.798-3.985,4.008-3.985c0.251,0,0.501,0.023,0.744,0.069c0.212,0.039,0.412-0.124,0.412-0.34v-1.1l2.646,2.633L8.938,8.67z M14.389,7.107c-0.34-0.18-0.662,0.244-0.424,0.529c0.416,0.493,0.644,1.118,0.644,1.762c0,1.515-1.272,2.747-2.798,2.747c-0.029,0-0.061,0-0.089-0.002v-1.631c0-0.354-0.382-0.419-0.558-0.246l-3.482,3.465c-0.136,0.136-0.136,0.355,0,0.49l3.482,3.465c0.189,0.186,0.568,0.096,0.568-0.245v-1.533c0.153,0.016,0.331,0.022,0.484,0.022c2.592,0,4.7-2.098,4.7-4.677C16.917,9.506,15.948,7.917,14.389,7.107 M12.217,15.238c-0.251,0-0.501-0.022-0.743-0.069c-0.212-0.039-0.411,0.125-0.411,0.341v1.101l-2.646-2.634l2.646-2.633v1.116c0,0.174,0.126,0.318,0.295,0.343c0.158,0.024,0.318,0.034,0.486,0.034c1.905,0,3.456-1.542,3.456-3.438c0-0.271-0.032-0.541-0.097-0.804c0.648,0.719,1.022,1.659,1.022,2.66C16.226,13.451,14.428,15.238,12.217,15.238"/>
        </svg>
      </button>
  <p className="text-base text-center bg-gray-400 rounded p-1 flex-1">{children}</p>
  </div>)

  





  class EstadosPage extends Component{


    interval;
    // const [res, setRes] = useState([]);
    // const [loading, setLoading] = useState(false);


    constructor(props) {
        super(props);
        this.state = {
            res: [],
            loading:false,
            openModal:false,
            pedidoId:null,
            playAlarm1:false,
            playClick1:false, 
            sucursalesNames:[]
        };

        this.verPedido = this.verPedido.bind(this);
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.refreshData = this.refreshData.bind(this);

        this.checkEnCaja = this.checkEnCaja.bind(this);
        
      }
    


      componentDidMount(){

        console.log("******************************     use effects props",   process.env.REACT_APP_PUSHER_KEY );

        pusher = new Pusher(  process.env.REACT_APP_PUSHER_KEY , {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            encrypted: true
          });


          console.log("pusher", pusher)


          const channel = pusher.subscribe('nuevo-pedido');
          const channelFlota = pusher.subscribe('flota');




          channel.bind('App\\Events\\NewMessageNotification', data => {
            console.log("channel bind App\\Events\\NewMessageNotification", data)
                this.eventoPusher(data);
          });

          channel.bind('App\\Events\\EstadoNotification', data => {
            console.log("channel bind App\\Events\\EstadoNotification", data)
            this.eventoEstado(data);
            });

  


          channel.bind('App\\Events\\CinetEvent', data => {
            console.log("channel bind App\\Events\\CinetEvent", data)
            this.eventoCinet(data);
          });



          channelFlota.bind('conteo', data => {
              console.log("   flota count", data.conteos);
             this.props.sc(  data.conteos );  // myAction(  data )  ;
          });


          console.log(" will mount");

          this.interval= setInterval(  ()=> this.refreshData(), KpollTime);
          console.log("interval",  this.interval );

        this.refreshData();

        this.alarmInterval = setInterval(() => this.checkEnCaja(), 60000);

    }




    componentWillUnmount(){

      console.log("componentWillUnmount")
        pusher.disconnect();
        console.log("close interval", this.interval);
        clearInterval( this.inteval );

        clearInterval(this.alarmInterval);

    }


    componentWillReceiveProps(nextProps) {
         if( nextProps.user.funcionario )this.refreshData();
      }
 





// https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
      handlePedidosChange (e) {



        console.log( "handle e", e);

        // let index=this.state.res.findIndex( (i)=> { console.log(i); return  i.ticket==e.orden} );
        //
        //
        // if( index>=0){
        //
        //         axios.get(  `delivery/pedido/${ e.id }`).then(
        //             (result)=>{
        //
        //                 this.setState(prevState => {
        //                     const newItems = [...prevState.res];
        //                     result.data.efecto=1;
        //                     newItems[index] = result.data;
        //                     return  {res:newItems};
        //                 })
        //
        //
        //             }
        //         ).catch(error => {
        //             console.log("error ", error.response.data.message);
        //         });;
        //
        // }else{
        //
        //
        //
        //     axios.get(  `delivery/pedido/${ e.id }`).then(
        //         (result)=>{
        //
        //             this.setState(prevState => {
        //                 result.data.efecto=2;
        //                 const newItems = [...prevState.res, result.data ];
        //                 return  {res: newItems };
        //             })
        //
        //
        //         }
        //     ).catch(error => {
        //         console.log("error ", error.response.data.message);
        //     });;
        //
        //
        // }



        this.refreshData()

    };

 

      eventoCinet(data){
        console.log("evento cinet DATA  con res:", data, this.state.res);

        //refreshData();
        this.handlePedidosChange(data)
        this.setState({playClick1:true});



        // notificador.addNotification({
        //     title: "EVENTO CINET",
        //     message: "orden",
        //     type: "success",
        //     insert: "top",
        //     container: "top-center",
        //     animationIn: ["animated", "fadeIn"],
        //     animationOut: ["animated", "fadeOut"],
        //     dismiss: {
        //       duration: 600,
        //       onScreen: false
        //     }
        //   });

    }



    eventoEstado(data){
        console.log("eventoEstado DATA", data);

        this.handlePedidosChange(data)
        this.setState({playClick1:true});

    }



    eventoPusher(data){
        console.log("evento pusher DATA  con res:", data, this.state.res);


        let sucursalesNames= this.props.user.funcionario.sucursales;


        console.log( data.sucursal, "en", sucursalesNames, sucursalesNames.includes(data.sucursal)  );

        if(sucursalesNames.includes(data.sucursal) ){
                this.handlePedidosChange(data);
  
                if( data.estado==='En espera'  ){
                    this.setState({playAlarm1:true});
        
                    if(data.sucursal) {
                        notificador.addNotification({
                        title: "Notificación",
                        message: `${data.sucursal} - #${data.orden} - ${data.estado}`,
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 600,
                          onScreen: false
                        }
                      });
                    
                    }
                }else{
                    this.setState({playClick1:true});
                }

        }




    this.refreshData()

    }





    refreshData (){
         
        let sucursal="Sol";
        console.log("map user", this.props.user);

        if( this.props.user.funcionario ){

            let sucursales= this.props.user.funcionario.sucursales.join(",");

            this.setState( {loading:true} );
            // `pedidosHead?s=${sucursal}&sort=asc`
            axios.get(  process.env.REACT_APP_API_ENDPOINT + `pedidosHead?sort=asc&s=${sucursales}` ).then(
                (result)=>{          
                    this.setState({
                        res:result.data.pedidos,
                        loading:false
                    });

                    this.props.sc( result.data.conteoFlota );



                }
            ).catch( e=>{
                this.setState( {loading:false} );
              
            } );        
    

        }


        
    }



    verPedido(i){
            this.setState( {openModal:true, pedidoId: i._id });
         //  props.history.push(`/pedidos/${i._id}`)
      }


    //   onClickHandler(i)
    //   {
    //       console.log("click handler");
    //       this.verPedido(i._id);  
    //   }





    

    // Función para verificar el estado "En caja" cada 30 segundos
          checkEnCaja() {
            const { res } = this.state;
            const pedidoEnCaja = res.find(pedido => pedido.estado === "En caja");
            if (pedidoEnCaja) {
                this.setState({ playAlarmEnCaja: true });
            }
        }

        // Función para reproducir el archivo de alarma
        handleAlarmSoundFinished() {
            this.setState({ playAlarmEnCaja: false });
        }



    closeModalHandler(){
        this.setState({openModal:false});
    }


      render(){

        return (



            <div className="w-full  flex   flex-col  ">
    

    {this.state.playAlarm1 &&  <Sound
      url="sounds/alarm1.mp3"
      playStatus={Sound.status.PLAYING}
      playFromPosition={ 0 }
      autoLoad={true}
      autoPlay={true}
      onFinishedPlaying={ ()=>{  this.setState({playAlarm1:false}) }  }
    />}


{this.state.playClick1 &&  <Sound
      url="sounds/click1.mp3"
      playStatus={Sound.status.PLAYING}
      playFromPosition={ 0 }
      autoLoad={true}
      autoPlay={true}
      onFinishedPlaying={ ()=>{  this.setState({playClick1:false}) }  }
    />}

    
        <Modal
        ariaHideApp={false}
          isOpen={this.state.openModal}
          style={customStyles}
          contentLabel="Pedido"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ ()=>this.setState({openModal:false}) }
        >
 
            <div className=" flex   h-full justify-between  w-full">

 
                    <PedidoPage refreshHandler={this.refreshData} pedidoId={ this.state.pedidoId } closeModalHandler={ this.closeModalHandler } />
            </div>
      
          
        </Modal>


 
{/* 
            <ToolsMonitoreo   refreshHandler={this.refreshData}   /> */}



    
            {this.state.playAlarmEnCaja &&
                    <Sound
                        url="sounds/alarmX5.mp3"
                        
                        playStatus={Sound.status.PLAYING}
                        playFromPosition={0}
                        autoLoad={true}
                        autoPlay={true}
                        onFinishedPlaying={() => this.handleAlarmSoundFinished()}
                    />
                }





            <div className="grid grid-cols-1  md:grid-cols-5 gap-3 flex-grow ">
                    <div className="bg-gray-500"  >
                        <TituloSeccion  refreshHandler={this.refreshData}>Solicitando driver</TituloSeccion>



                        <ListaEstado data={this.state.res} filtro="En espera"  clickHandler={this.verPedido} />
                        <ListaEstado data={this.state.res} filtro="Fasttrack"  clickHandler={this.verPedido} />
        
                    </div>
        
                    <div className="bg-gray-500">
                        <TituloSeccion  refreshHandler={this.refreshData}>En caja</TituloSeccion>
        
                        <ListaEstado data={this.state.res} filtro="En caja"  clickHandler={this.verPedido} />
        
        
                    </div>
        
                    <div className="bg-gray-500">
                        <TituloSeccion  refreshHandler={this.refreshData}>En producción</TituloSeccion>
        
                        <ListaEstado data={this.state.res} filtro="En producción" clickHandler={this.verPedido} />
        
        
                    </div>
        
                    <div className="bg-gray-500">
                        <TituloSeccion  refreshHandler={this.refreshData}>Listo, aguardando driver</TituloSeccion>
        
        
                        <ListaEstado data={this.state.res} filtro="Listo para entregar" clickHandler={this.verPedido} />
        
                    </div>
        
        
                    <div className="bg-gray-500">
                        <TituloSeccion  refreshHandler={this.refreshData}>En camino</TituloSeccion>
        
                        <ListaEstado data={this.state.res} filtro="En camino" clickHandler={this.verPedido} />   

                        <TituloSeccion  refreshHandler={this.refreshData}>Entregado</TituloSeccion> 
                        <ListaEstado data={this.state.res} filtro="Entregado" clickHandler={this.verPedido} />          
                        <TituloSeccion  refreshHandler={this.refreshData}>Borrado</TituloSeccion> 
                        <ListaEstado data={this.state.res} filtro="Borrado" clickHandler={this.verPedido} />    
                    </div>
            </div>
    





            </div>
        )


      }// fin render



}

const mapDispatchToProps = dispatch => {
    return {
      sc: d => dispatch( setSucursalesCount(d))
    }
  }

const mapStateToProps = function(state) {
    return {
      user: state.master.user
    }
  }



export default connect(
    mapStateToProps, mapDispatchToProps
  )(EstadosPage)
