import React from 'react'
import {  Draggable } from 'react-beautiful-dnd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';


const Handle=styled.div`
    width:20px;
    height:20px;
    background-color:orange;
    border-radius:4px;
    margin-right:8px;
`;



export default function MotoristaDrag(props) {


        let colorBg=''

        if( props.item.estado==='ACTIVO' && props.item.posicion==='En sucursal' )colorBg="bg-green-500";
        if( props.item.estado==='ACTIVO' && props.item.posicion==='En camino' )colorBg="bg-yellow-500";
        if( props.item.estado==='INACTIVO')colorBg="bg-gray-700";



    return (

        <Draggable key={props.item._id} 
        isDragDisabled={ props.item.posicion==='En camino'?true:false }
        draggableId={props.item._id} index={props.index}>
        {(provided, snapshot) => (
             
<div ref={provided.innerRef} {...provided.draggableProps} 
className={`flex items-center border rounded border-gray-500 p-2  justify-between ${colorBg}`}>


<div className="inline-flex ">
{props.item.posicion!=='En camino'&&<p className="text-white mr-2" {...provided.dragHandleProps} >
            <FontAwesomeIcon icon={['fas','arrows-alt']}   />
        </p>}

        <p className="font-semibold text-gray-800 text-sm"> {props.item.nombre}  </p>

</div>


        <div className="inline-flex ">

            { props.infoHandler && <button onClick={ ()=>props.infoHandler(props.item)}
                className="ml-3 p-0 w-8 h-8     mouse  transition ease-in duration-200 focus:outline-none ">
                <FontAwesomeIcon icon={['fas','info']}   />
                </button> }

            { props.estadoHandler && <div>
                {props.item.estado==='ACTIVO'?<button onClick={ ()=>props.estadoHandler(props.item,'INACTIVO')}
                className="ml-3 p-0 w-8 h-8     mouse shadow transition ease-in duration-200 focus:outline-none ">
                <FontAwesomeIcon icon={['fas','toggle-on']}   />
                </button>:     
                <button onClick={ ()=>props.estadoHandler(props.item,'ACTIVO')}
                className="ml-3 p-0 w-8 h-8     mouse shadow transition ease-in duration-200 focus:outline-none ">
                <FontAwesomeIcon icon={['fas','toggle-off']}   />
                </button>}     
            </div>}


        </div>



</div>
           
        )}
    </Draggable>

        )
}
