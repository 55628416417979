import React from 'react'

export default function ProductoOptionTotem({producto, grupo, options,  clickOptionHandler}) {


    const selected = options.find(f=>f.addon.addon_name.label===grupo)  && options.find(f=>f.addon.addon_name.label===grupo).addon.addon_name.name;



    return (

<div className="border rounded p-2 bg-gray-400 mb-5 ">
<p className="text-xl">{grupo}: 

{/* <span className="font-bold text-2xl"> {   options.find(f=>f.addon.addon_name.label===grupo)  && options.find(f=>f.addon.addon_name.label===grupo).addon.addon_name.name  }</span> */}

</p>

                    <div className="flex gap-3">


                    {
                    producto.options.filter(f=>f.option_group===grupo).map( i=>
       <div> <button key={ i.sku } 
        style={{ backgroundImage: `url(${i.url})`, backgroundSize:'cover', backgroundPosition:'center', borderWidth:'4px'   }}
        className={` ${ i.nameFront===selected?'border-green-500':'border-gray-200'}   border-separate   focus:outline-none text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24`} 
        onClick={()=> clickOptionHandler(i)    }>
        
        </button> 
            
        <div>


        { i.nameFront} { i.precio>0 && `(+${i.precio})`  }
        </div>      

            </div>
                    )
                
                    }

      
                    
                    </div>
          </div>

    )
}
