import React, { useState, useContext } from 'react'
import { authContext } from '../contexts/AuthContext'
import axios from 'axios'

const SignIn = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { setAuthData } = useContext(authContext)

  const onFormSubmit = e => {
    e.preventDefault()

    const data = { email: email, password: password }

    axios.post(process.env.REACT_APP_API_ENDPOINT + `auth2`, data).then(
      (result) => {
        console.log('result axios counter', result)

        setAuthData(result.data.token) // typically here we send a request to our API and in response, we receive the user token.
        //As this article is about the front-end part of authentication, we will save in the context the user's email.

        // localStorage.setItem('user', result.data.user)
        console.log(result.data.user)

        if (result.data.user.rol === 'drivein') {
          history.replace('/drivein')
        } else if (result.data.user.rol === 'totem') {
          history.replace('/totem')
        } else {
          history.replace('/callcenter')
        }

      }
    ).catch(e => {
      console.log('error ', e.response.data.error)
      alert(e.response.data.error)

    })

  }

  return (

    <div className="w-full p-2 flex justify-center  bg-gray-600 h-full overflow-scroll">


      <div
        className="block self-center lg:flex bg-white lg:shadow-lg rounded-lg bg-gray-400 p-3 flex flex-col lg:w-1/2 border">

        <div className="w-1/3 lg:w-1/3 flex  border-gray-200     self-center">
          <div className="m-auto rounded-full">
            <img src="/mostazalogoCircle.svg" alt="mostaza" className=" "/>
          </div>
        </div>
        <div className="w-full     ">
          <div className="mb-4 font-light tracking-widest text-2xl">LOGIN</div>
          <form onSubmit={onFormSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 text-sm text-gray-800">Email</label>
              <input type="email" name="email" id="email"
                     value={email} onChange={e => { setEmail(e.target.value) }}
                     className="focus:border-blue-500 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                     autoComplete="email" required/>
              <div v-if="feedback.email.error">
                <div className="text-sm text-red-500 mt-2" v-text="feedback.email.message"></div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2 text-sm text-gray-800">Password</label>
              <input type="password" name="password" id="password"
                     value={password} onChange={e => { setPassword(e.target.value) }}
                     className="focus:border-blue-500 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                     autoComplete="current-password" required/>
            </div>


            <div className="block md:flex items-center justify-between">
              <button type="submit"
                      className="align-middle bg-blue-500 hover:bg-blue-600 text-center px-4 py-2 text-white text-sm font-semibold rounded-lg inline-block shadow-lg">LOGIN
              </button>
              {/*
                        <a className="text-gray-600 hover:text-gray-700 no-underline block mt-3" href="/password/reset">
                            Forgot Your Password?
                        </a> */}
            </div>
          </form>
        </div>
      </div>


    </div>

  )
}

export default SignIn
