import { createSlice } from '@reduxjs/toolkit';


// direccion: {_id: "5e94dfe6f2df3216e15e2c44", icono: "home", ubicacion: {…}, calle: "pruebad", referencias: "pruebas", …}
// formaPago: "efectivo"
// giftcard: ""
// modalidad: "delivery"
// montoGiftcard: 0
// productos: (2) [{…}, {…}]
// sucursal: "Sol"
// ubicacion: null


import {
  selectListaProductos
    } from '../features/masterSlice';


const initial = {
  productos:[],
  cliente:null,
  direccion:null,
  formaPago:"efectivo",
  modalidad:"delivery",
  sucursal:"Sol",
  vehiculo:{ tipo:'Compacto', color:'Negro' },
  factura:{nombre:'', ruc:''},
  ubicacion:null,
  buscaEn:0,
  api:2,
  dondeCome:'local',
  posData:null,
  versionApp: `cc_v${process.env.REACT_APP_VERSION}`
};

export const pedidoSlice = createSlice({
  name: 'pedido',
  initialState: initial,
  reducers: {

    getCurrentState: state => {
      return state;
    },


    resetTotem: (state, action) => {

      console.log("reset totem", state, action)

      state.productos=[];
      state.cliente="Cliente totem";
      state.direccion=null;
      state.sucursal='Municipalidad';
      state.ubicacion=null;
      state.formaPago=null;
      state.modalidad="totem";
      state.vehiculo={ tipo:'', color:'' };
      state.observaciones="";
      state.factura={ nombre: '', ruc: '' };
      state.buscaEn=0;
      state.api=2;
      state.dondeCome=action.payload;
      state.posData=null;

      console.log("reset totem", action.payload)


    },


    reset: (state, action) => {
      state.productos=[];
      state.cliente=null;
      state.direccion=null;
      state.sucursal='Sol';
      state.ubicacion=null;
      state.formaPago="efectivo";
      state.modalidad="delivery";
      state.vehiculo={ tipo:'', color:'' };
      state.observaciones="";
      state.factura={ nombre: '', ruc: '' };
      state.buscaEn=0;
      state.api=2;
      state.versionApp= `cc_v${process.env.REACT_APP_VERSION}`


    },

    recreate: (state, action) => {
      state.productos = action.payload.productos;
      state.cliente = action.payload.cliente;
      state.direccion = action.payload.direccion;
      state.sucursal = action.payload.sucursal;
      state.formaPago = action.payload.formaPago;
      state.modalidad = action.payload.modalidad;
      state.vehiculo = action.payload.vehiculo;
      state.observaciones = action.payload.observaciones;
      state.factura = action.payload.factura;
      state.buscaEn = action.payload.buscaEn;
      state.api=2;
    },


    setDireccion: (state, action) => {
      state.direccion = action.payload;
    },

    setCliente: (state, action) => {
      console.log( "payload setcliente", action.payload );
      state.cliente = action.payload;
      state.factura= { nombre: action.payload.facturaNombre, ruc: action.payload.facturaRuc }
    }, 

    setFormaPago: (state, action) => {
      state.formaPago = action.payload;
    }, 

    setDondeCome: (state, action) => {
      state.dondeCome = action.payload;
    }, 

    setModalidad: (state, action) => {
      state.modalidad = action.payload;
    }, 

    setObservaciones: (state, action) => {
      state.observaciones = action.payload;
    }, 

    setPosData: (state, action) => {
      state.posData = action.payload;
    }, 


    setFactura: (state, action) => {
      state.factura = { nombre: action.payload.nombre, ruc: action.payload.ruc }
    }, 

    setSucursal: (state, action) => {
      state.sucursal = action.payload;
    }, 

    setVehiculo: (state, action) => {
      state.vehiculo = action.payload;
    }, 

    setBuscaEn: (state, action) => {
      state.buscaEn = action.payload;
    },
    
    removeProducto: (state, action) => {
      console.log("remove prod", action.payload, action);
      let newArray = state.productos.slice();
      newArray.splice(action.payload, 1);

      let prodDeliv=state.productos.find( (i)=>i.nameFront==='Delivery' );
      if(prodDeliv){
        let delivery=   JSON.parse(JSON.stringify(    prodDeliv  ) );
        state.productos = calculateDelivery(newArray, delivery, state.modalidad);  
      }else{
        state.productos=newArray;
      }

    }, 


    recalcular: (state, action) => {

        console.log( "state recalcular", state, action, state.productos, selectListaProductos  );

      let newArray = state.productos.slice();
      let delivery=   JSON.parse(JSON.stringify(    action.payload.deliv   ) );

      state.productos = calculateDelivery(newArray, delivery, state.modalidad);
      console.log("  agregamos a productos :", state.productos);

    }, 



    addProducto: (state, action) => {
      console.log("  agregamos a productos ", action.payload);

      let delivery=   JSON.parse(JSON.stringify(action.payload.deliv));
      
      let productos=[ ...state.productos ,  action.payload.producto];
      

      state.productos = calculateDelivery(productos, delivery, state.modalidad);



      console.log("  agregamos a productos :", state.productos);

    }, 








    setDatos: (state, action) => {
      console.log(" states setDatos");
        state.datos = action.payload;
      }


  },
});





const calculateDelivery=(productos, delivery, modalidad)=>{

  let combosPorDelivery=8;

  let combos = productos.filter((i)=> i.bebida );
  let cant=combos.reduce( (total, obj) => obj.cantidad + total , 0   );

  if( cant >combosPorDelivery) cant= Math.floor(cant/combosPorDelivery)+1;
  else cant=1;

   // ELIMINO PRODUCTO DELIVERY
  let i= productos.findIndex  ( p =>  {  return p.nameFront==="Delivery"} );
  if(i>=0) productos.splice(i, 1);

  console.log(  "delivery",  delivery );

  delivery.cantidad= parseInt(cant);
  if( modalidad==='delivery')  productos.push( delivery ); 

  return productos;
}







export const {   setDireccion, setCliente, setFormaPago, setModalidad, setBuscaEn, recalcular,  
  setObservaciones, setSucursal,setVehiculo,setFactura,   addProducto,removeProducto, reset, 
  setPosData,
  setDondeCome,
  resetTotem,
  getCurrentState,
   recreate} = pedidoSlice.actions;






// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.master.value)`
export const selectDireccion = state => state.pedido.direccion;
export const selectCliente = state => state.pedido.cliente;
export const selectPedido = state => state.pedido;
export const selectFormaPago = state => state.formaPago;
export const selectSucursal = state => state.sucursal;
export const selectProductos = state => state.productos;
export const selectFactura = state => state.factura;



export default pedidoSlice.reducer;
