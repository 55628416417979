import React from 'react'
import dayjs from 'dayjs'

export default function LogEstados({data}) {


    // data.pb_pickup_started_datetime
    // data.cinet_onProduction_date
    // data.cinet_ready_date
    // data.pb_delivery_started_datetime
    // data.pb_delivery_arrived_datetime


    return (
<table className="table-auto w-full">
  <thead>
    <tr>
      <th className="px-2 py-2">Event </th>
      <th className="px-2 py-2">Fecha</th>
    </tr>
  </thead>
  <tbody>


              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Inicio pickup</td>
                <td className="border px-2 py-1"> { data.pb_pickup_started_datetime  &&  dayjs(data.pb_pickup_started_datetime).format('HH:mm:ss') }</td>
              </tr>


              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">En producción</td>
                <td className="border px-2 py-1"> { data.cinet_onProduction_date && dayjs(data.cinet_onProduction_date).format('HH:mm:ss') }</td>
              </tr>


              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Pedido listo</td>
                <td className="border px-2 py-1"> { data.cinet_ready_date && dayjs(data.cinet_ready_date).format('HH:mm:ss') }</td>
              </tr>


              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Inicio delivery</td>
                <td className="border px-2 py-1"> { data.pb_delivery_started_datetime && dayjs(data.pb_delivery_started_datetime).format('HH:mm:ss') }</td>
              </tr>

              <tr  className="bg-gray-100" >
                <td className="border px-2 py-1  uppercase    text-gray-700   font-bold ">Fin delivery</td>
                <td className="border px-2 py-1"> { data.pb_delivery_arrived_datetime && dayjs(data.pb_delivery_arrived_datetime).format('HH:mm:ss') }</td>
              </tr>



  </tbody>
</table>
    )
}
