import React, {useEffect, useState} from 'react'
import MetricCard from '../components/dashboard/MetricCard'
import ChartCard from '../components/dashboard/ChartCard'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Doughnut, Pie , Line} from 'react-chartjs-2';
import moment from 'moment'
import 'moment/locale/es';



import {
    selectSucursales, selectFecha
      } from '../features/masterSlice';
import { setModalidad } from '../features/pedidoSlice';


export default function DashboardPage() {

    const [loading, setLoading] = useState(false)
    const [totalPedidos, setTotalPedidos] = useState(0)
    const [sucursales, setSucursales] = useState([]);  
    const [res, setRes] = useState([])
    const [dataSucursal, setDataSucursal] = useState(  {} )
    const [dataCanal, setDataCanal] = useState(  {} )
    const [dataModalidad, setDataModalidad] = useState({})
    const ss=useSelector(selectSucursales);

    const [dataMes, setDataMes] = useState({})


    const fecha=useSelector(selectFecha);


    useEffect(() => {
        setSucursales(ss);
        consultarSucursales();
    }, [ss, fecha])


    useEffect(() => {
 
//        consultarSucursales();


    }, [])




    const consultarSucursales = ()=>{

        setLoading(true);


        setDataCanal({});
        setDataModalidad({});

        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/dashboard?f=${ moment(fecha).toISOString()  }` ).then(
            (result)=>{
                setLoading(false);

                //setRes(result.data);

                let porDia=result.data.porDia;
                let data=result.data.sucursales;

                data.map( d=>{

                    setSucursales(  prev=>{

                        let newArray=prev.slice();
                        let i= newArray.findIndex( i=> i.nombre===d.nombre);
                        newArray[i]={ ...newArray[i], d  };
                        return newArray;

                    }  )


                    setTotalPedidos( data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.total)  ,0 ) );


                    let totalApp=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.canalApp)  ,0 );
                    let totalCc=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.canalCc)   ,0 );

                    let totalDelivery=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.delivery)  ,0 );
                    let totalProgramado=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.programado)  ,0 );
                    let totalFasttrack=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.fasttrack)  ,0 );
                    let totalDrivein=  data.reduce( (prev, next)=> parseInt(prev)+parseInt(next.drivein)  ,0 );



                    setDataMes({
                        data:{
                            labels: porDia.map(i=>i._id.fecha),
                        datasets: [
                            {
                            label: 'Pedidos',
                            type:'bar',
                            yAxisID : "y-axis-1",
                            data: porDia.map(i=>i.count),

                            borderWidth: 1
                        },
                        {
                            label: 'Total',
                            yAxisID : "y-axis-2",
                            data: porDia.map(i=>i.totalPrice),
                            backgroundColor: "rgba(255,255,255,0.5)",
                            borderColor: 'rgba(255, 93, 0, 0.6)',
                            borderWidth: 2
                        }]
                        },
                    options: {
                        scales: {
                            yAxes: [{
                                id:"y-axis-1",
                                position:'left',
                                type: 'linear',
                                ticks: {
                                    beginAtZero:true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Pedidos'
                                  }
                                },
                                  {
                                id:"y-axis-2",
                                position:'right',
                                type: 'linear',
                                /*ticks: {
                                    beginAtZero:true
                                },*/
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Monto'
                                  }
                            }],
                            xAxes : [{
                                gridLines : {
                                    display : false
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Fecha'
                                  }
                            }]
                        },
                        
                    }               });



                    setDataCanal({
                        labels: ['App', 'Callcenter'],
                        datasets: [{
                            label: 'pedidos',
                            data: [ totalApp  , totalCc],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                            ],
                            borderWidth: 1
                        }]
                    });


                    setDataModalidad({
                        labels: ['Delivery', 'Fasttrack', 'Programado', 'Drivein'],
                        datasets: [{
                            label: 'pedidos',
                            data: [ totalDelivery  , totalFasttrack,  totalProgramado, totalDrivein],

                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],

                            borderWidth: 1
                        }]
                    });


                    // setDataSucursal({
                    //     labels: ['Delivery', 'Fasttrack', 'Programado'],
                    //     datasets: [{
                    //         label: 'pedidos',
                    //         data: [ totalDelivery  , totalFasttrack,  totalProgramado],

                    //         backgroundColor: [
                    //             'rgba(54, 162, 235, 0.2)',
                    //             'rgba(255, 206, 86, 0.2)',
                    //             'rgba(75, 192, 192, 0.2)',
                    //             'rgba(153, 102, 255, 0.2)',
                    //             'rgba(255, 159, 64, 0.2)'
                    //         ],
                    //         borderColor: [
                    //             'rgba(54, 162, 235, 1)',
                    //             'rgba(255, 206, 86, 1)',
                    //             'rgba(75, 192, 192, 1)',
                    //             'rgba(153, 102, 255, 1)',
                    //             'rgba(255, 159, 64, 1)'
                    //         ],

                    //         borderWidth: 1
                    //     }]
                    // });



                })

               


            }
        ).catch(  (e)=> setLoading(false) );        
    }




    return (
		



	<div onClick={  ()=>consultarSucursales() }  class="  w-full  overflow-scroll   bg-black-alt font-sans leading-normal tracking-normal" style={ {backgroundColor:'#191919'} }>
		
		{<div class="w-full    mb-16 text-gray-800 leading-normal">
			
			<div class="flex flex-wrap grid grid-cols-2">
                    {
                        sucursales.map( s=>
                            <MetricCard title={s.nombre} total={s.d && s.d.total}  />
                            )
                    }
                            <MetricCard title={'TOTAL'} total={ totalPedidos }  />


            </div>



            <div class="flex flex-row flex-wrap flex-grow mt-2">
                <ChartCard title="Canal" > <Doughnut data={ dataCanal } /> </ChartCard>
                <ChartCard title="Modalidad">  <Pie data={ dataModalidad } />  </ChartCard>
            </div>			
					
            <div class="flex    flex-wrap flex-grow mt-2">
                <ChartCard  className={'w-full p-3 '} title="Mes" > 
                
                <div className="bg-white">
                <Line data={ dataMes.data } options={ dataMes.options }  />
                </div>

                 </ChartCard>
            </div>			


		</div>}
		

	</div> 




    )
}
