import React from 'react'

export default function BigChoice( { values, value, onChangeHandler} ) {
    return (

                   

        values.map( i=> <div className="inline-flex">
            <button 
            className={' text-gray-800  text-4xl font-bold py-2 px-4 rounded-l    mx-2'+ (   i.value===value?' bg-green-300':' bg-gray-300'    )       } 
            onClick={()=> onChangeHandler(i.value)  }>
            {i.label}  
            </button>
        </div>
        )
    )
}
