import React from 'react'
import NumberFormat from 'react-number-format';



export default function Producto2({data, showPrice, addProductHandler, handlerClickProduct}) {







  const clickProducto = (p)=>{
   // console.log("click p", handlerClickProduct);
    if( handlerClickProduct )handlerClickProduct(p);
  }



    return (




<div className="w-full bg-gray-200 shadow-lg rounded-lg overflow-hidden   flex"  onClick={ ()=>clickProducto(data) } >


<img className="h-16  object-cover " src={  data.url  } alt="ilustracion" />

<div className="p-2">
        <p style={{fontSize:'10px'}}>{data.sku}</p>
    <p className="text-gray-900 text-sm   truncate"> {data.nameFront} </p>
    {  showPrice && <p className="text-black font-bold text-sm">
        <NumberFormat value={data.precio} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
    </p>
    }

</div>
</div>



    )
}


{/* <div className="max-w-xs bg-gray-200 shadow-lg rounded-lg overflow-hidden   flex"  onClick={ ()=>clickProducto(data) } >


<img className="h-16  object-cover " src={  data.url  } alt="ilustracion" />

<div className="p-2">

    <p className="text-gray-900 text-sm   truncate"> {data.nameFront} </p>
    {  showPrice && <p className="text-black font-bold text-sm">
        <NumberFormat value={data.precio} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
    </p>
    }

</div>
</div> */}