import React, {  useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import Marcador from './Marcador';



export default function Mapa( props ) {





    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
       // setMap(map);  
       // setMaps(maps);  

      };




    return (


<GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCjyZq8RSfrImlIfnf5Rhbq-IXRqA5Dh7s'}}
          defaultCenter={ {lat:-25.2830485, lng:-57.5687854}  }
          defaultZoom={ props.defaultZoom }
          zoom={ props.zoom}
          center={ props.center}
          yesIWantToUseGoogleMapApiInternals={false}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          distanceToMouse={()=>{}}
          layerTypes={['TrafficLayer', 'TransitLayer']}
          onZoomAnimationEnd={ props.onEndZoom }
        >

{

    props.agendas && props.agendas.map ((item,i)=>

    <Marcador  
    lat={  item.geocoding[0].geometry.location.lat  } 
    lng={  item.geocoding[0].geometry.location.lng  } 
    text={`${i+1}`}
    />
    )

}


        </GoogleMapReact>


        )
}
