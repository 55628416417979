import React from 'react'

export default function Motoristas(props) {
    return (

            <div className="w-full   p-5">

                <p className="text-2xl text-center">Seleccione un motorista:</p>

<div className="grid grid-cols-4 gap-5">

{

    props.data.filter( a=>  a.posicion==='En sucursal' && a.sucursal===props.sucursal ).map( i=>
<button
onClick={  ()=> props.motoristaHandler(i) }
className="text-white px-4 w-auto h-10 bg-blue-600 rounded-full hover:bg-blue-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">

<span className="uppercase"> { i.nombre } </span>
</button>

)

}



</div>

            </div>

    )
}
