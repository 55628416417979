import React, {useEffect, useState} from 'react'

import  FacturaDetalle from './FacturaDetalle'
import NumberFormat from 'react-number-format';

import { useSelector , useDispatch} from 'react-redux';

import { removeProducto }
from '../features/pedidoSlice';



export default function Factura({data, callcenter}) {

    const [total, setTotal]=useState(0);
    const dispatch = useDispatch();

    useEffect( () => {
       
        let  total = data.productos.reduce((prev,next) => {

                // ojo, next.modifiablePrice es true  debo agregar la suma de price de los order_product_addons
                let addonsZ = 0;

                    console.log("productos each", next)

                if(next.modifiablePrice && next.order_product_addons){
                    addonsZ=   next.order_product_addons.reduce((prevAddon,nextAddon)=>{
                        return parseInt(prevAddon) + parseInt(nextAddon.price*next.cantidad);
                    },0);
                }

                console.log("productos each", addonsZ)

            return parseInt(prev) + parseInt(next.precio*next.cantidad) + addonsZ;
        },0);
        setTotal( total );
     }, [data])



     const removeHandler= (data, indice)=>{
         if( data.nameFront==='Delivery')return;

         dispatch(  removeProducto( indice ) );
     };




    return (
        <div>
           
        {

            data.productos.map( (item,i)=> <FacturaDetalle key={i} removeHandler={ callcenter && removeHandler} data={item} indice={i} /> )
        }


            <p className="text-2xl font-bold text-right ">
                            Total: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
                        </p>

            {data.giftcard &&  <div>


                <p className="text-2xl font-bold text-right ">
            Giftcard ({data.giftcard}): <NumberFormat value={-data.montoGiftcard} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
            </p>

        <p className="text-2xl font-bold text-right ">
        A pagar: <NumberFormat value={  data.montoGiftcard>total?0:total-data.montoGiftcard  } displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />
        </p>

            </div>
        
        }

        </div>
    )
}
