import React, {useState, useEffect} from 'react'
import Moment from 'react-moment';
import axios from 'axios';
import Mapa from '../components/Mapa';
import {Link} from 'react-router-dom';


export default function AgendaPage(props) {

    const [res,setRes]=useState(null);
    const [center,setCenter]=useState( {lat:-25.2830485, lng:-57.5687854}  );
    const [zoom,setZoom]=useState(15);



    useEffect(() => {

        consultarAgenda();

    }, [props.state])





    const consultarAgenda = ()=>{
         

        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/agenda` ).then(
            (result)=>{
          
               setRes(result.data);
            }
        ).catch( e=>{
          
        } );        
    }    



    const agendaClick = (item)=>{
        console.log( "click", item.geocoding[0].geometry.location );
        setCenter( item.geocoding[0].geometry.location );
        setZoom(17);

    }



    return (
        <div className="flex w-full">


        <div className="bg-green-500 w-2/3">
          
          
          <Mapa  center={ center } zoom={ zoom } agendas={res} defaultZoom={14} onEndZoom={  setZoom }  />

        </div>


        <div className="px-3  pt-3 w-1/3 overflow-scroll">


        <div className="flex items-center justify-between">



      <Link to="/agenda/direccion" className="mb-3 mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Agregar
      </Link>

    </div>


        <table className="table-auto w-full">
<thead>
<tr className="bg-indigo-200">
<th className="px-2 py-2">#</th>
<th className="px-2 py-2">Nombre</th>
<th className="px-2 py-2">Celular</th>

</tr>
</thead>
<tbody>
{
res && res.map ((item,i)=>

<tr className="bg-gray-100"  key={item._id}  onClick={ ()=> agendaClick(item)  } >


    <td className="border px-1 py-1"> { i+1 } </td>
    <td className="border px-1 py-1">{item.nombre}</td>
    <td className="border px-1 py-1"> {item.celular} </td>

</tr>
)


}


</tbody>
</table>
        </div>





        </div>
    )
}
