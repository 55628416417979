import React, {useContext} from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';




import {
    resetTotem
  } from '../../features/pedidoSlice'
import { authContext } from "../../contexts/AuthContext";


export default function DondeComer(props) {


    const {  auth } = useContext(authContext);

    const dispatch = useDispatch()

    const iniciarPedido =(dondeCome)=>{

        props.history.push('/totem/main/productos')
        dispatch(resetTotem(dondeCome))
    }



    return (




<div className="w-full flex flex-col bg-black justify-center items-center gap-10">

            <div className="text-4xl text-white bold text-center">Dónde vas a comer hoy?</div>


            <div className="flex gap-12 justify-center items-center">
                

            

            <button type="button" onClick={()=>iniciarPedido("local")  } className="  rounded-lg flex" 
            style={{width:'calc(25vw)'  }}
               >
                    <img  src="https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/imagenes/btn01_aqui.png"  />
                </button>


                <button type="button" onClick={()=>iniciarPedido("llevar")  } className="  rounded-lg flex" 
            style={{width:'calc(25vw)'  }}
               >
                    <img  src="https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/imagenes/btn02_llevar.png"  />
                </button>



                {/* <button type="button" onClick={()=>iniciarPedido("local")  } className="  py-24 bg-blue-300 rounded-lg flex flex-col gap-12 justify-center items-center" 
                style={{width:'calc(30vw)'  }}>

                    <img style={{width:'8em'}} src="https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/imagenes/paraComerAqui_min.jpg"  />
                    <p className="bold text-3xl">Comer aquí</p>
                </button>

                <button type="button" onClick={()=>iniciarPedido("llevar")  } className=" py-24  bg-blue-300 rounded-lg flex flex-col gap-12 justify-center items-center" 
                style={{width:'calc(30vw)'  }}>
                <img style={{width:'8em'}} src="https://mostaza-assets.s3.sa-east-1.amazonaws.com/totem/imagenes/paraLlevar_min.jpg"  />
                    <p className="bold text-3xl">Llevar</p>
                </button> */}


            </div>

</div>




    )
}
