import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './contexts/AuthContext';

import store from './app/store';
import { Provider } from 'react-redux';

import './styles/index.css';
import './styles/tailwind.css';

import './fontawesome';

ReactDOM.render(
  <React.StrictMode >
    <Provider store={store}>
    <AuthProvider>
      <App  />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
