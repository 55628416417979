import React from 'react'

export default function Ayuda() {
    return (
        <div className="w-full overflow-scroll">

    
        <div className="w-1/3 p-5 ">
            <p className="p-2 m-2 border rounded bg-gray-300">
Mostaza Delivery, buenas días/ buenas tardes/ buenas noches, le saluda *****<br/>Le tomo la orden por favor?...
</p>

<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > cliente responde al saludo
</p>

<p className="p-2 m-2 border rounded bg-gray-300">Me indica su numero de celular por favor....</p>

<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > Si, 09** *** ***
</p>


<p className="p-2 m-2 border rounded bg-gray-300">Si está registrado: Es Ud. ****** *****?</p>
<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > Si 
</p>

<p className="p-2 m-2 border rounded bg-gray-300">Su dirección es Avda ****** casi ***** numero **** y con porton ****?</p>
<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > Si 
</p>


<p className="p-2 m-2 border rounded bg-gray-300">Si no está registrado se toman los datos. Nombre, apellido, ci, ruc, dirección, ciudad y referencias de la casa y alrededores</p>

<p className="p-2 m-2 border rounded bg-gray-300">La factura, le enviamos a nombre de alguien?</p>

<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > Si / no
</p>

<p className="p-2 m-2 border rounded bg-gray-300">Le tomo la orden por favor: toma el pedido, ofrece agrandado, consulta si desea agregar algo más.</p>

<p className="p-2 m-2 border rounded bg-gray-300">Una vez terminado de tomar la orden le repite e indica el monto.<br/>Su pedido totaliza Gs ********, Abona con tarjeta o en efectivo?</p>

<p className="p-2 m-2 border rounded bg-gray-300">Sr. ****, su pedido estaría llegando entre 40 a 50 minutos.</p>

<p className="italic text-right p-2 m-2 border rounded bg-gray-400"> 
    > ok, gracias
</p>

<p className="p-2 m-2 border rounded bg-gray-300">Gracias por llamar a Mostaza! Que tenga buen resto de jornada/ que tenga buena noche</p>

        </div>


        </div>        
    )
}
