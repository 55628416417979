import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Factura from '../components/Factura'
import { useDispatch } from 'react-redux'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import axios from 'axios'

import {
  selectProductosDi,
  selectSucursales
} from '../features/masterSlice'

import {
  selectDireccion,
  selectCliente,
  selectPedido,
  reset,
  setFormaPago,
  setCliente,
  setFactura,
  setModalidad,
  setObservaciones,
  setVehiculo,
  setBuscaEn,
  setSucursal,
  recalcular
} from '../features/pedidoSlice'
import BigChoice from './BigChoice'

export default function PedidoDi (props) {

  const sucursales = useSelector(selectSucursales)
  const cliente = useSelector(selectCliente)
  const direccion = useSelector(selectDireccion)

  const [nombre, setNombre] = useState('')
  const [celular, setCelular] = useState('')

  const pedido = useSelector(selectPedido)
  const productos = useSelector(selectProductosDi)
  const [showDialog, setShowDialog] = useState(0)
  const [isSubmitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const cars = [
    { src: './assets/cars/car-city-model.svg', nombre: 'Compacto' },
    { src: './assets/cars/sedan-car-model.svg', nombre: 'Sedan' },
    { src: './assets/cars/minivan-car.svg', nombre: 'Minivan' },
    { src: './assets/cars/jeep.svg', nombre: 'Camioneta' },
    { src: './assets/cars/bike.svg', nombre: 'Moto' }
  ]

  const colors = [
    { bg: 'black', fg: 'white', nombre: 'Negro' },
    { bg: 'red', fg: 'white', nombre: 'Rojo' },
    { bg: 'gray', fg: 'white', nombre: 'Gris' },
    { bg: 'white', fg: 'gray', nombre: 'Blanco' },
    { bg: 'blue', fg: 'white', nombre: 'Azul' },
    { bg: '#b0b8b9', fg: 'black', nombre: 'Plata' },
    { bg: 'gold', fg: 'gray', nombre: 'Dorado' }
  ]

  const formasDePago = [
    { label: 'Efectivo', value: 'efectivo' },
    { label: 'POS', value: 'pos' }
  ]

  useEffect(() => {
//      console.log("pedido en pedidodi", pedido);
    if (!pedido.cliente) props.history.push('/drivein')

  }, [pedido])

  const iniciarOrden = () => {
    console.log(props.history)
    props.history.push('/callcenter/cliente')

  }

  const cancelarOrden = () => {

    dispatch(reset())
    props.history.push('/drivein')

  }

  const enviarOrden = () => {
    console.log(pedido)
    if (pedido.cliente?.nombre.length < 3) {
      alert('Ingrese el nombre del cliente')
      return
    }
    if (pedido.cliente?.celular.length < 3) {
      alert('Ingrese el celular del cliente')
      return
    }

    //console.log('pedido', pedido);

    if (pedido.productos.length === 0) {
      alert('Debe agregar productos al pedido')
      return
    }

    let data = JSON.parse(JSON.stringify(pedido))

    //data.cliente=null;

    setSubmitting(true)
    axios.post(`delivery/pedido`, data).then(
      (result) => {
        console.log('result axios pedido', result)

        dispatch(reset())
        props.history.push('/drivein/pedidos')
      }
    ).catch(error => {
      alert(error.response.data.error)
      setSubmitting(false)
    })

    // setTimeout(() => setSubmitting(false), 2000)

    //dispatch( reset() );
    //props.history.push('/pedidos');

  }

  const seleccionarCliente = () => {

    props.history.push('/callcenter/cliente')
  }

  return (
    pedido.cliente && <div className="p-5">


      <Dialog style={{ color: 'red' }} isOpen={showDialog} onDismiss={() => setShowDialog(false)}>
        <p className="m-5 text-2xl  text-center">Confirmas cancelar el pedido?</p>

        <div className="flex">
          <button className="bg-red-200 border p-2 text-2xl mr-5 outline-none w-32 h-24"
                  onClick={() => setShowDialog(false)}>No
          </button>
          <button className="bg-green-200 border p-2 text-2xl outline-none w-32  h-24" onClick={() => {
            setShowDialog(false)
            cancelarOrden()
          }}>Si
          </button>
        </div>
      </Dialog>


      <div className="mb-5  w-2/3 self-center">
        {pedido && <Factura data={pedido}/>}
      </div>


      <label className="mt-5 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        Vehiculo: {pedido.vehiculo.tipo}/{pedido.vehiculo.color}
      </label>


      <div className="mb-5">
        <BigChoice
          values={cars.map(i => { return { label: i.nombre, value: i.nombre } })}
          value={pedido.vehiculo.tipo}
          onChangeHandler={(v) => dispatch(setVehiculo({ ...pedido.vehiculo, tipo: v }))}
        />
      </div>


      <div className="mb-5">
        <BigChoice
          values={colors.map(i => { return { label: i.nombre, value: i.nombre } })}
          value={pedido.vehiculo.color}
          onChangeHandler={(v) => dispatch(setVehiculo({ ...pedido.vehiculo, color: v }))}
        />
      </div>


      <div className="mt-5 inline-flex w-full">


        <div className="w-2/5 mr-5">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-fact-nombre">
            NOMBRE CLIENTE:
          </label>
          <input value={pedido.cliente?.nombre}
                 onChange={(e) => dispatch(setCliente({
                   nombre: e.target.value,
                   celular: pedido.cliente?.celular,
                   facturaNombre: e.target.value,
                   facturaRuc: pedido.factura.ruc
                 }))}
                 className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                 id="grid-fact-nombre" type="text" placeholder="nombre cliente "/>
        </div>


        <div className="w-2/5  mr-5 ">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-factura-ruc">
            CELULAR:
          </label>
          <input
            value={pedido.cliente?.celular}
            onChange={(e) => dispatch(setCliente({
              nombre: pedido.cliente?.nombre,
              celular: e.target.value,
              facturaNombre: pedido.factura.nombre,
              facturaRuc: pedido.factura.ruc
            }))}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="grid-factura-ruc" type="text" placeholder="celular cliente "/>
        </div>

        <div className="w-1/5  mr-5">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-factura-ruc">
            Numero de auto:
          </label>
          <input
            value={pedido.vehiculo.carNumber}
            onChange={(e) => dispatch(setVehiculo(
              { ...pedido.vehiculo, carNumber: e.target.value }
              // carNumber: e.target.value

            ))}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="grid-factura-ruc" type="text" placeholder="numero de auto "/>
        </div>

      </div>


      <div className="mt-5 inline-flex w-full">


        <div className="w-2/3 mr-5 ">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-fact-nombre">
            FACTURA NOMBRE:
          </label>
          <input value={pedido.factura.nombre}
                 onChange={(e) => dispatch(setFactura({ nombre: e.target.value, ruc: pedido.factura.ruc }))}
                 className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                 id="grid-fact-nombre" type="text" placeholder="nombre factura "/>
        </div>


        <div className="w-1/3 ">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-factura-ruc">
            FACTURA RUC:
          </label>
          <input value={pedido.factura.ruc}
                 onChange={(e) => dispatch(setFactura({ nombre: pedido.factura.nombre, ruc: e.target.value }))}
                 className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                 id="grid-factura-ruc" type="text" placeholder="ruc factura "/>
        </div>


      </div>


      <div className="mt-5">

        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Forma de pago: {pedido.formaPago}
        </label>


        <BigChoice
          values={formasDePago}
          value={pedido.formaPago}
          onChangeHandler={(v) => dispatch(setFormaPago(v))}
        />


        <div className="w-full mt-5">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-obs">
            OBSERVACIONES:
          </label>


          <textarea
            value={pedido.observaciones} onChange={(e) => dispatch(setObservaciones(e.target.value))}
            id="grid-obs" type="text" placeholder="Observaciones"
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
      resize-none "/>

          {/* <input value={  pedido.observaciones } onChange={(e)=> dispatch( setObservaciones(e.target.value) )}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
       id="grid-obs" type="text" placeholder="Observaciones"/> */}


        </div>


      </div>


      <div className="mt-8">

        {isSubmitting ?
          <p className='text-3xl text-center  w-full pb-5'>
            Aguarde...
          </p> :
          <div className="flex">
            <button
              className="px-3 py-1 bg-red-500 text-sm text-white font-semibold rounded text-2xl w-full mr-5 outline-none h-24"
              onClick={() => setShowDialog(true)}>Cancelar pedido
            </button>
            <button
              className="px-3 py-1 bg-green-300 text-sm text-black font-semibold rounded text-2xl w-full outline-none"
              onClick={enviarOrden}>Confirmar pedido
            </button>
          </div>}

      </div>


    </div>

  )
}
