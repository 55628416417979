import React, {useEffect, useState} from 'react'
import { useSelector , useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from 'react-loading-overlay';


import MotoristaDrag from '../components/MotoristaDrag'


import {
    selectSucursales,  setLoading
      } from '../features/masterSlice';
  
      import axios from 'axios';
import ToolsMonitoreo from '../components/ToolsMonitoreo';

export default function MotoristasPage() {
    const sucursales = useSelector(selectSucursales);
    const [motoristas, setMotoristas] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        refreshData()
    }, [sucursales])




   const  refreshData=()=>{

        setIsLoading(true);
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `motoristas` ).then(
            (result)=>{
                setIsLoading(false);         
               setMotoristas(result.data);
            }
        ).catch( e=>{
            setIsLoading(false);                  
        } );       
    }



    const  updateMotorista=(id, data)=>{

       // dispatch(  setLoading(true) );
        axios.put(  process.env.REACT_APP_API_ENDPOINT + `motoristas/${id}`, data ).then(
            (result)=>{
               // dispatch(  setLoading(false) );
                handleMotoristaChange(id,data);
            }
        ).catch( e=>{
                //dispatch(  setLoading(false) );
        } );       
    }


    const estadoMotorista=(m, estado)=>{

        updateMotorista( m._id, { estado: estado}) 
        
    }



    const infoMotorista=(m)=>{

        console.log("info motorista");
        
    }



    const onDragEnd = result => {
        console.log("drag end", result);

        if( result.reason === 'DROP' && result.destination)
        {

            let index = motoristas.findIndex( i=> i._id=== result.draggableId  );

            updateMotorista(result.draggableId, {
                sucursal:result.destination.droppableId
            });


            setMotoristas(prevState => {
                const newItems = [...prevState];
                newItems[index].loading=true;
                newItems[index].sucursal = result.destination.droppableId;
                return  newItems;
            })

        }


    }




    const handleMotoristaChange=(id, data)=>{

        let index = motoristas.findIndex( i=> i._id=== id  );

        setMotoristas(prevState => {
            const newItems = [...prevState];
            newItems[index]={...newItems[index], ...data  };
            return  newItems;
        })
    }





    const  nuevoMotorista=(sucursal)=>{


        let nombre=prompt("Nombre del motorista");

        if( nombre && nombre.length>1){
                let data={
                    nombre:nombre,
                    sucursal:sucursal.nombre,
                    estado:'INACTIVO'
                }
                
                setIsLoading(true);
                axios.post(  process.env.REACT_APP_API_ENDPOINT + `motoristas`, data ).then(
                    (result)=>{
                        setIsLoading(false);
                    refreshData();
                    }
                ).catch( e=>{
                    setIsLoading(false);
                
                } );       
        }

    }


    return (

        <DragDropContext
        onDragEnd={  onDragEnd  }
      >


<div className="w-full  bg-red-900   flex   flex-col  ">

 
 

        <ToolsMonitoreo   refreshHandler={refreshData}   />

<div className="bg-blue-200  flex-grow overflow-scroll">
<div className="grid grid-cols-5 gap-3   ">
            


            {
                sucursales.map( s=>
                    
                <div key={s._id} className="rounded  flex flex-col">


        <div className="inline-flex  bg-gray-600  border-gray-600  p-1"  style={{backgroundColor: s.color }} >     

                 <p className="text-lg p-1">{s.nombre}
                 
                 <button onClick={ ()=>nuevoMotorista(s)}
                        className="ml-3 p-0 w-8 h-8 bg-gray-400 rounded hover:bg-gray-200 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none ">
                        <FontAwesomeIcon icon={['fas','plus']}   />
                </button>     
                 </p>
        
         </div>           





        <div className="flex-grow overflow-scroll  border-gray-600 border rounded rounded-t-none border-t-0 "   style={{backgroundColor: s.color+'22' }} >

        <Droppable droppableId={s.nombre}  direction='vertical' type='cards'>
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef} className=" p-2 min-h-full " >


            <div className="border border-gray-500 p-1 bg-white mb-5">
            <label className="block uppercase   tracking-wide text-gray-700 text-xs font-bold "  >Libres:</label>
                <div className="grid gap-2">
                        { motoristas.filter( f=> f.sucursal===s.nombre && (f.estado==="ACTIVO" && f.posicion==='En sucursal' ) ).map((item, index) => (
                            <MotoristaDrag  item={item}  index={index} estadoHandler={ estadoMotorista } infoHandler={ infoMotorista }  />
                        ))}
                </div>                                
            </div>




            <div className="border border-gray-500 p-1 bg-white flex flex-col mb-5">
                <label className="block uppercase   tracking-wide text-gray-700 text-xs font-bold "  >En camino:</label>
                <div className="grid gap-2 grid-cols-1">
                        { motoristas.filter( f=> f.sucursal===s.nombre && (f.estado==="ACTIVO" && f.posicion==='En camino' ) ).map((item, index) => (
                            <MotoristaDrag  item={item}  index={index}  infoHandler={ infoMotorista } />
                            ))}
                </div>                            
            </div>




            <div className="border border-gray-500 p-1 bg-white">
                <label className="block uppercase   tracking-wide text-gray-700 text-xs font-bold "  >Inactivos:</label>

                <div className="grid gap-2 ">
                                                { motoristas.filter( f=> f.sucursal===s.nombre && (f.estado==="INACTIVO") ).map((item, index) => (
                                                    <MotoristaDrag  item={item}  index={index} estadoHandler={ estadoMotorista } infoHandler={ infoMotorista }  />
                                                ))}
                </div>
            </div>




								{provided.placeholder}
							</div>
						)}
					</Droppable>            
        </div>






                 </div>

                )
            }



        </div>

</div>
 

</div>
 

        </DragDropContext>


    )
}
