import React from 'react'
import Producto from '../components/Producto'

export default function Bebidas({data, handlerClickProduct}) {
    return (
        <div className="grid  grid-cols-4  sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 gap-4">
        {
            data.map(el => <Producto  handlerClickProduct={handlerClickProduct}    key={el._id} data={el} /> )
        }
        </div>
    )
}
