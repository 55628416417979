import React from 'react'

export default function AutoDriveIn (props) {
  // return (props.driveInHandler(prompt('numero de auto')))
  return (
    <div className="w-full   p-5">

      <p className="text-2xl text-center">Numero de auto:{props.pedido.vehiculo.carNumber}</p>

      <div className="grid grid-cols-2 gap-5">

        {/*<input type="text" placeholder={'numero de auto'} id={'name'}/>*/}
        {/*<label>asd</label>*/}
        <button
          onClick={() => props.driveInHandler()}
          className="text-white px-4 w-auto h-10 bg-blue-600 rounded-full hover:bg-blue-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
          Aceptar
          {/*<span className="uppercase"> {i.nombre} </span>*/}
        </button>



      </div>

    </div>
  )
}
