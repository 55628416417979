import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import FacturaTotem from '../components/FacturaTotem'
import { useDispatch } from 'react-redux'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import axios from 'axios'
import Modal from 'react-modal'

import store from '../app/store';

import { BsCashCoin, BsFillCreditCardFill} from "react-icons/bs";

import NumberFormat from 'react-number-format';


import {
  selectProductosDi,
  selectSucursales
} from '../features/masterSlice'

import {
  selectDireccion,
  selectCliente,
  selectPedido,
  setPosData,
  reset,
  setFormaPago,
  setCliente,
  setFactura,
  setModalidad,
  setObservaciones,
  setVehiculo,
  setBuscaEn,
  setSucursal,
  recalcular,
  getCurrentState
} from '../features/pedidoSlice'
import BigChoice from './BigChoice'
import PrintComponentButton from "./PrintButton"
import NombreLlamador from "./NombreLlamador"
import Ruc from "./Ruc"



const customStyles = {
    content: {
      backgroundColor: '#2d3748',
      top: '200px',
      left: '80px',
      right: '80px',
      bottom: '200px',
      transform: 'none' // Elimina la transformación de la posición central
    }
  }


  const customStylesMessageDialog = {
    content: {
      top: '800px',
      left: '240px',
      right: '240px',
      bottom: '800px',
      transform: 'none' // Elimina la transformación de la posición central
    }
  }



export default function PedidoTotem (props) {

    const {gotoIntro}  = props;


    const [rucModal, setRucModal] = useState( {open:false, data:null} )
    const [llamadorModal, setLlamadorModal] = useState( {open:false, data:null} )



    const [formaPagoModal, setFormaPagoModal] = useState( {open:false, data:null} )
    const [graciasModal, setGraciasModal] = useState( {open:false, data:null} )
    const [aguardeModal, setAguardeModal] = useState( {open:false, data:null} )
    const [pedidoRevisionModal, setPedidoRevisionModal] = useState( {open:false, data:null} )

  const sucursales = useSelector(selectSucursales)
  const cliente = useSelector(selectCliente)
  const direccion = useSelector(selectDireccion)

  const [nombre, setNombre] = useState('')
  const [celular, setCelular] = useState('')

  const pedido = useSelector(selectPedido)
  const productos = useSelector(selectProductosDi)
  const [showDialog, setShowDialog] = useState(0)
  const [showMessageDialog, setShowMessageDialog] = useState({
    open:false,
    message:'Seleccione la bebida...'
  })
  const [isSubmitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const [total, setTotal]=useState(0);
 

  const formasDePago = [
    { label: 'Efectivo', value: 'efectivo' },
    { label: 'POS', value: 'pos' }
  ]

  useEffect(() => {
//      console.log("pedido en pedidodi", pedido);
//    if (!pedido.cliente) props.history.push('/drivein')

    let  total = pedido.productos.reduce((prev,next) => {
        return parseInt(prev) + parseInt(next.precio*next.cantidad);
    },0);
    setTotal( total );

  }, [pedido])




  const ocultarMensaje = (message)=>{


    setShowMessageDialog({
        open:false,
        message:""
    })
  }

  const mostrarMensaje = (message)=>{


    setShowMessageDialog({
        open:true,
        message:message
    })
  }



  const enviarOrden = async () => {

            // Esperar a que el estado se actualice
            await new Promise(resolve => setTimeout(resolve, 0));

            // Obtener el estado actualizado
            const updatedState = store.getState().pedido;
            console.log('Estado actualizado:', updatedState);

            const pedido = updatedState;


            // if (pedido.cliente?.nombre.length < 3) {
            //     mostrarMensaje('Ingrese el nombre del cliente')
            //     return
            // }
            // if (pedido.cliente?.celular.length < 3) {
            //     mostrarMensaje('Ingrese el celular del cliente')
            //     return
            // }

            // //console.log('pedido', pedido);

            // if (pedido.productos.length === 0) {
            //     mostrarMensaje('Debe agregar productos al pedido')
            //     return
            // }

            console.log("enviar orden pedido", pedido)

            let data = JSON.parse(JSON.stringify(pedido))

            console.log("enviar orden pedido data", data)

            //data.cliente=null;

            setSubmitting(true)
            setAguardeModal({open:true, data:null, mensaje:"Procesando pedido..." })
            axios.post(`delivery/pedido`, data).then(
                (result) => {
                    console.log('result axios pedido', result)

                    const {data} = result;

                    const dataPrint={
                        ticket:data.ticket,
                        formaPago:data.formaPago,
                        payload:data
                    }

                    axios.post(`https://gql.fastview.cloud/printTotem`, dataPrint).then(()=>{

                    
                        setGraciasModal({open:true, data:null})
                        setAguardeModal({open:false, data:null, mensaje:null })
                        setTimeout(() => {
                            setGraciasModal({open:false, data:null})
                            closeFormaPagoModal();
                            cancelOrderHandler();
                        }, 6000);


                    }).catch(error=>{
                        setAguardeModal({open:false, data:null, mensaje:null })
                        mostrarMensaje(error.response.data.error)
                    })


                    //dispatch(resetTotem())
                    //props.history.push('/drivein/pedidos')
                }
            ).catch(error => {
                setAguardeModal({open:false, data:null, mensaje:null })
                mostrarMensaje(error.response.data.error)
                setSubmitting(false)
            }).finally(() => {
            })

            // setTimeout(() => setSubmitting(false), 2000)

            //dispatch( reset() );
            //props.history.push('/pedidos');
  }


  const cancelOrderHandler = ()=>{
        setShowDialog(false)
        gotoIntro();
        //cancelarOrden()
  }







const closeLlamadorModal = ()=>{
    setLlamadorModal({open:false, data:null})
}



  const closeFormaPagoModal = ()=>{
        setFormaPagoModal({open:false, data:null})
    }


    const closePedidoRevisionModal = ()=>{
        setPedidoRevisionModal({open:false, data:null})
    }


    const closeGraciasModal = ()=>{
        setGraciasModal({open:false, data:null})
    }


    const closeAguardeModal = ()=>{
        setAguardeModal({open:false, data:null, mensaje:null })
    }



    const completarOrden = ()=>{
        setPedidoRevisionModal({open:true, data:null})
    }



    const confirmarPedido = ()=>{
            closePedidoRevisionModal();


            // setFormaPagoModal({open:true, data:null})
            setRucModal({open:true, data:null})
 
   }



   const closeRucModal = (rucByCedula)=>{
    if(rucByCedula){
        dispatch( setFactura({
            nombre: rucByCedula?.razon_social,
            ruc: `${rucByCedula?.ruc}-${rucByCedula?.dv}`
        }) )

        setFormaPagoModal({open:true, data:null})
    }



    setRucModal({open:false, data:null})

}





    const procesarEfectivo = ()=>{
      //  closeFormaPagoModal();
        dispatch(setFormaPago('efectivo'))
        enviarOrden();
    }

    const  procesarTarjeta = ()=>{

        dispatch(setFormaPago('bancard'))

        console.log("procesar tarjeta", pedido);


        const min = 500000;
        const max = 999999;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        const ventaPayload={
            "facturaNro": randomNumber,
            "monto":  total
        }
        

        // const payloadB={
        //     "bin": ventaRes.data.bin,
        //     "nsu": ventaRes.data.nsu,
        //     "monto": ventaPayload.monto
        // }


        


        setAguardeModal({open:true, data:null, mensaje:`Pase su tarjeta...`})
        axios.post(`https://gql.fastview.cloud/ventaBancard`,  {ventaPayload} ).then((result)=>{
            const {data}=result;

            console.log("data bancard venta",data);

            if(data.res){
                dispatch(setPosData(data.res))
                setTimeout(() => {
                        console.log("pedido to", pedido)
                        enviarOrden();  
                    }, 100);  
            }else{
                mostrarMensaje("error bancard venta")                
                setAguardeModal({open:false, data:null, mensaje:null })
                console.log("error bancard venta")
            }

        }).catch(error=>{
            mostrarMensaje("error bancard venta")
            setAguardeModal({open:false, data:null, mensaje:null })
            console.log("error bancard venta",error)
        }).finally(() => {
            
        })
    }


    




  return (


    <div className="bg-gray-300 rounded-lg w-full flex flex-col  h-full">
            <div className="bg-gray-900 text-gray-200 flex justify-between text-2xl p-2">
                <p>Mi orden para {pedido.dondeCome==='local'?'comer aquí':'llevar'}</p>
                <p>Total: <span className="font-bold">
                      <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />  </span>
                    </p>
            </div>

            <div className="bg-gray-400 flex-1 overflow-scroll">
                {pedido && <FacturaTotem data={pedido}/>}
            </div>

            <div className="flex justify-end p-5">
                    <div className="flex gap-5">
                        <button onClick={ () => pedido.productos.length>0?setShowDialog(true):cancelOrderHandler() } type ="buton" className="bg-red-900 px-8 py-4 text-gray-200 text-2xl p-2  focus:outline-none">Cancelar</button>
                        <button onClick={ completarOrden } type ="buton" className="bg-green-600 px-8 py-4 text-gray-200 text-2xl p-2  focus:outline-none">Completar orden</button>
                    </div>
            </div>



            <Modal
          ariaHideApp={false}
          isOpen={showMessageDialog.open}
          style={customStylesMessageDialog}
          contentLabel=""
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ ocultarMensaje }
        >

<div className="flex flex-col w-full justify-center items-center   h-full gap-8">

        <p className="text-3xl  text-center"> {showMessageDialog.message}  </p>
 
        <button className="bg-green-200 border p-2 text-2xl outline-none px-12   py-8 focus:outline-none" onClick={ ocultarMensaje }>Aceptar
        </button>

</div>


</Modal>


            {/* <Dialog style={{ color: 'blue' }} isOpen={showMessageDialog.open} onDismiss={ ocultarMensaje }>
                <p className="m-5 text-2xl  text-center">{ showMessageDialog.message } </p>

                <div className="flex">
                <button className="bg-green-200 border p-2 text-2xl outline-none w-32  h-24 focus:outline-none" onClick={ ocultarMensaje }>Aceptar
                </button>
                </div>
            </Dialog> */}





            <Dialog style={{ color: 'blue' }} isOpen={showDialog} onDismiss={() => setShowDialog(false)}>
                <p className="m-5 text-2xl  text-center">Cancelar el pedido?</p>

                <div className="flex">
                <button className="bg-red-200 border p-2 text-2xl mr-5 outline-none w-32 h-24 focus:outline-none"
                        onClick={() => setShowDialog(false)}>No
                </button>
                <button className="bg-green-200 border p-2 text-2xl outline-none w-32  h-24 focus:outline-none" onClick={ cancelOrderHandler }>Si
                </button>
                </div>
            </Dialog>







            <Modal
          ariaHideApp={false}
          isOpen={pedidoRevisionModal.open}
          style={customStyles}
          contentLabel="Revisión del pedido"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ closePedidoRevisionModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-800 justify-center  ">

          <div className="flex flex-col  gap-8  justify-start h-full">
                            <p style={{fontSize:'48px'}}>Revisión del pedido </p>
                             {pedido.productos.length>0 && 
                                <div className="p-5 bg-gray-100 rounded-lg flex flex-col gap-5">
                                    <FacturaTotem data={pedido}/>
                                    <p className="text-right"  style={{fontSize:'36px'}}>🇵🇾 Total: <span className="font-bold">
                                         <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'Gs. '} />  </span>
                                    </p>



                                    <p className="text-right"  style={{fontSize:'28px'}}>🇦🇷 Total Pesos: <span className="font-bold text-gray-800">
                                         <NumberFormat value={total/13} displayType={'text'}  decimalScale={0} thousandSeparator={true} prefix={'$ '} />  </span>
                                    </p>

                                    <p className="text-right"  style={{fontSize:'28px'}}>🇧🇷 Total Reales: <span className="font-bold  text-gray-800">
                                         <NumberFormat value={total/1450} displayType={'text'}  decimalScale={2} thousandSeparator={true} prefix={'R$ '} />  </span>
                                    </p>

                                    <p className="text-right"  style={{fontSize:'28px'}}>🇺🇸 Total USD: <span className="font-bold text-gray-800">
                                         <NumberFormat value={total/7200} displayType={'text'}  decimalScale={2} thousandSeparator={true} prefix={'$ '} />  </span>
                                    </p>



                                </div>                             
                             }


                            <div className="flex gap-16 justify-center mt-auto">
                                <button type="button" 
                                    style={{fontSize:'32px'}}
                                onClick={closePedidoRevisionModal} className="bg-blue-300  text-white font-bold py-6 px-8 rounded">Volver</button>

                                <button type="button" 
                                    style={{fontSize:'42px'}}
                                onClick={confirmarPedido} className="bg-green-500  text-white font-bold py-6 px-8 rounded">Confirmar</button>
                            </div>
                        </div>


          </div>
        </Modal>








        <Modal
          ariaHideApp={false}
          isOpen={rucModal.open}
          style={customStyles}
          contentLabel="Ruc"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ closeRucModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-800 justify-center  ">
                        <div className="flex flex-col justify-center gap-8 place-items-center">
                            <p className="text-white  text-4xl  text-center">Datos para la factura:</p>
                            <Ruc  onRequestClose={  closeRucModal  }   />
                        </div>
          </div>
        </Modal>




        <Modal
          ariaHideApp={false}
          isOpen={llamadorModal.open}
          style={customStyles}
          contentLabel="Llamador"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ closeLlamadorModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-900 justify-center  ">

                        <div className="flex flex-col justify-center gap-8 items-center ">
                         
                        {/* <p className="text-white text-2xl">Donde vas a comer hoy?</p> */}
                        <p className="text-white text-4xl ">Ingrese su nombre</p>
                             <NombreLlamador />

                        </div>


          </div>
        </Modal>






            <Modal
          ariaHideApp={false}
          isOpen={formaPagoModal.open}
          style={customStyles}
          contentLabel="Forma de pago"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ closeFormaPagoModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-800 justify-center  ">

                        <div className="flex flex-col justify-center gap-8 place-items-center">
                            <p style={{fontSize:'48px'}} className="w-full text-center text-white">
                                Forma de pago:
                            </p>




                            <div className=" grid gap-16 grid-cols-2 p-5">
                                <button type="button" 
                                    style={{fontSize:'60px'}}
                                onClick={ procesarTarjeta } className="bg-green-500  text-white font-bold py-12 px-16 rounded">
                                    

                                    <div className="flex flex-col justify-center items-center  gap-2   ">
                                        <BsFillCreditCardFill  />
                                        <p>Tarjeta</p>
                                    </div>
                                </button>


                                <button type="button" 
                                    style={{fontSize:'60px'}}
                                onClick={ procesarEfectivo } className="bg-green-500 text-center  text-white font-bold py-12 px-16 rounded">
                                    <div className="flex flex-col justify-center items-center  gap-2   ">
                                        <BsCashCoin  />
                                        <p>Efectivo</p>
                                    </div>
                                    
                                    </button>
                            </div>
                        </div>


          </div>
        </Modal>






        <Modal
          ariaHideApp={false}
          isOpen={aguardeModal.open}
          style={customStyles}
          contentLabel="Aguarde"
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={ closeAguardeModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-800 justify-center  ">

                        <div className="flex flex-col justify-center gap-8 place-items-center text-white text-center">
                            <p  className="text-center w-full" style={{fontSize:'48px'}}>
                                
                                {
                                    aguardeModal.mensaje?aguardeModal.mensaje:'Aguarde...'
                                }
                                

                            </p>
                        </div>


          </div>
        </Modal>


        <Modal
          ariaHideApp={false}
          isOpen={graciasModal.open}
          style={customStyles}
          contentLabel="Gracias"
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={ closeGraciasModal }
        >

          <div className=" flex flex-col  h-full gap-16  w-full  bg-gray-800 justify-center  ">

                        <div className="flex flex-col justify-center gap-8 place-items-center text-white">
                            <p style={{fontSize:'72px'}}>
                                Gracias por su compra!
                            </p>

                            <p style={{fontSize:'48px'}}>
                                Retire su factura y pedido en la caja
                            </p>
                        </div>


          </div>
        </Modal>







        </div>



  )
}
