import React, { createContext, useState, useEffect } from 'react';
import jwtDecode from "jwt-decode";
import {  useDispatch, useSelector} from 'react-redux';
import {
    loadData
  } from '../features/masterSlice';
import axios from 'axios';

import {
    selectUser
  } from '../features/masterSlice';
export const authContext = createContext({});




const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null });

  const user=useSelector(selectUser);

  const dispatch = useDispatch();

  const setAuthData = (data) => {
      if(data){
        let decoded=jwtDecode(data);
        setAuth({data: data, decoded: decoded });
    }else{
        setAuth({data: null}); 
    }
  };






  useEffect(() => {
   // console.log( "use effect on auth context");

    let d;
    
    try {
       d= JSON.parse(window.localStorage.getItem('authData')); 
    } catch (error) {
        console.log("error en parse item token");
    }

    

    if( d ){
        let decoded=jwtDecode(d);
        setAuth({ loading: false, data: d, decoded: decoded   });
        //console.log("1 axios auth", `Bearer ${d}`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${d}`;
        axios.defaults.baseURL=process.env.REACT_APP_API_ENDPOINT;
        dispatch( loadData() );
    }

        /// en este punto debemos chequear si el token expiró

  }, []);



// 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.
//2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false. 
//This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {
    window.localStorage.setItem('authData', JSON.stringify(auth.data));

   // console.log( "use effect auth data", auth.data);
   if( auth.data){
       //console.log("2 axios auth", `Bearer ${auth.data}`);
       axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.data}`;
       axios.defaults.baseURL=process.env.REACT_APP_API_ENDPOINT;
     }
    dispatch( loadData() );

  }, [auth.data]);








  return (
    <authContext.Provider value={{ auth, setAuthData, user }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;