import React, {useState, useEffect} from 'react'
import Producto from '../components/Producto'
import Producto2 from '../components/Producto2'
import { useSelector} from 'react-redux';




import {
    selectListaProductos
  } from '../features/masterSlice'



export default function Menu2({term, addProductHandler, handlerClickProduct, showPrice}) {


    const productos = useSelector(selectListaProductos);
    const [items,setItems]=useState([]);
//    const [clear,setClear]=useState(false);

console.log("prdouctos",productos, productos.length)



    const filtrar =()=>{
        let filtered=[];  
        if( term !==""){
            filtered= productos.filter( (a)=>{
            return  (   a.nameFront.toUpperCase().search(term.toUpperCase())  !== -1 ) 
                    || a.grupo.toUpperCase().search(term.toUpperCase() )!== -1
                    || a.sku.toUpperCase().search(term.toUpperCase()) !== -1
        
              
            });
        }else
        {
            filtered= productos.filter( (a)=>{
            return true 
            });   
        }

            let a={};
            filtered.forEach( i=>   {  if(!a[i.grupo])a[i.grupo]=[];    a[i.grupo].push(i) } );

            let b=[];
            for (const property in a) {
                b.push( {nombre:property, productos:a[property]} );
            }
        setItems( b );
    }


    // useEffect(() => {
    //    // filtrar();        
    //  });

    useEffect( () => {
        filtrar();    
    }, [term])


    useEffect( () => {
       console.log("use effects products");
       filtrar();    
    }, [productos])



    return (


       
            items.map(c => 


                <div  key={c.nombre} className="rounded p-3 shadow bg-white  w-full mb-2">
                    
                        <div className="font-bold text-base mb-2 uppercase text-center">{c.nombre} </div>

                        <div className="grid  grid-cols-3  gap-3">
                        {
                            c.productos.map(el => 
                            <Producto2   handlerClickProduct={handlerClickProduct}  addProductHandler={addProductHandler} showPrice={showPrice}   key={el.sku} data={el} /> )
                        }
                        </div>
                    
                </div>
                )
      


        )
}
