import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Opciones from '../components/Opciones'
import Bebidas from '../components/Bebidas'
import { useSelector, useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'

import {
  selectBebidasSa,
  selectProductosDi,
  selectUser
} from '../features/masterSlice'

import { addProducto, selectPedido, setModalidad, setSucursal, setDireccion, setCliente, setFactura }
  from '../features/pedidoSlice'

import ProductoOption from './ProductoOption'
import DiMenu from './DiMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as swal from 'sweetalert2'

export default function ProductoDiDetail (props) {

  const dispatch = useDispatch()
  const location = useLocation()
  const [flagsSeleccion, setFlagsSeleccion] = useState({
    opciones: false,
    bebida: false,
    cafe: false,
    cono: false,
    cono2: false,
    papas: false,
    agranda: false,
    juguete: false
  })
  const [data] = useState(location.state.data)
  const productos = useSelector(selectProductosDi)
  const pedido = useSelector(selectPedido)
  const user = useSelector(selectUser)
  const [options, setOptions] = useState([])
//    const [tipo, setTipo] = useState('')
  const [preferences, setPreferences] = useState([])
  const [precioC, setPrecioC] = useState(data.precio)

  // const [comments, setComments] = useState('')
  const [juguete, setJuguete] = useState('')

  const [producto, setProducto] = useState({
    ...location.state.data,
    cantidad: 1,
    order_product_addons: [],
  })

  useEffect(() => {

    let p = location.state.data

    let precioBase = data.precio

    // producto.cantidad
    setPrecioC(precioBase)

    console.log('producto', producto)

    let t = 'simple'
    if (producto.cajita && producto.cajita == true) t = 'cajita'
    if (producto.combo && producto.combo === true) t = 'combo'
    if (producto.promo && producto.promo === true) t = 'promo'
    if (producto.cafe && producto.cafe === true) t = 'cafe'
    if (producto.cono && producto.cono === true) t = 'cono'
    if (producto.cono2 && producto.cono2 === true) t = 'cono2'
    console.log('t', t)
    // setTipo(t);

    setProducto({ ...producto, precio: precioC, tipo: t })

    switch (t) {
      case 'cajita':
        setFlagsSeleccion({
          bebida: true, papas: false, agranda: false, juguete: true, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'combo':
        setFlagsSeleccion({
          bebida: true, papas: true, agranda: true, juguete: false, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'promo':
        setFlagsSeleccion({
          bebida: true, papas: false, agranda: true, juguete: false, cafe: false, cono: false,
          cono2: false
        })
        break

      case 'cafe':
        setFlagsSeleccion({
          bebida: false, papas: false, agranda: false, juguete: false, cafe: true, cono: false,
          cono2: false
        })
        break

      case 'cono':
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: true,
          cono2: false
        })
        break

      case 'cono2':
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: true,
          cono2: true
        })
        break

      default:
        setFlagsSeleccion({
          bebida: false,
          papas: false,
          agranda: false,
          juguete: false,
          cafe: false,
          cono: false,
          cono2: false
        })
        break
    }

    if (producto.preferences.length > 0) setFlagsSeleccion(ps => {return { ...ps, opciones: true }})

  }, [props])

  useEffect(() => {
    let sumaAddons = 0

    if (options.length > 0) {
      sumaAddons = options.reduce((prev, i) => prev + parseInt(i.addon.price), 0)
    }

    setPrecioC(prev => parseInt(data.precio) + parseInt(sumaAddons))

  }, [options])

  const clickPreference = (o, v) => {

    console.log(o, v)

    // this.pedidoProvider.producto.opciones=this.opciones.filter( i=> i.value===true );

    // console.log( "opc", this.opciones, this.pedidoProvider.producto.opciones);

    let opc = JSON.parse(JSON.stringify(o))

    var index = preferences.findIndex(p => p.nombre === o.nombre)

    if (index === -1) {
      setPreferences(prev => [...prev, opc])
    } else {
      if (!v) setPreferences(preferences.filter(p => p.nombre !== opc.nombre))
    }

    console.log('preferences', preferences)

  }

  const clickOption = (item) => {

    let addOn = {
      id: item.sku,
      price: item.precio,
      addon_id: item.sku,
      addon: {
        id: item.sku,
        price: item.precio,
        addon_name: {
          id: item.sku,
          name: item.nameFront,
          label: item.option_group,
          description: item.option_group
        }
      }
    }

    let n = options.filter(f => f.addon.addon_name.label !== item.option_group)
    setOptions([...n, addOn])

  }

  useEffect(() => {

  }, [producto])

  useEffect(() => {
    setProducto({ ...producto, precio: precioC })

  }, [precioC])

  const handlerAceptar = () => {

    console.log('pedido', user, pedido)

    if (pedido.modalidad !== 'drivein') {
      let suc = user.funcionario.sucursales[0]

      dispatch(setModalidad('drivein'))
      dispatch(setSucursal(suc))

      dispatch(setDireccion({ calle: 'DriveIn', referencias: suc, cliente_id: 0 }))
      dispatch(setCliente({ nombre: '', celular: '', facturaNombre: '', facturaRuc: '' }))
      dispatch(setFactura({ nombre: '', ruc: '' }))

    }

    // if( !cliente ){
    //     alert("Debe seleccionar un cliente primero");
    //     return;
    // }
    if (flagsSeleccion.juguete && !juguete) {
      alert('Seleccione el juguete')
      return
    }
    if (flagsSeleccion.bebida && !options.find(f => f.addon.addon_name.label === 'Bebida')) {
      alert('Seleccione la bebida')
      return
    }
    if (flagsSeleccion.papas && !options.find(f => f.addon.addon_name.label === 'Papas')) {
      alert('Seleccione el tipo de papas')
      return
    }
    if (flagsSeleccion.agranda && !options.find(f => f.addon.addon_name.label === 'Agrandado')) {
      alert('Seleccione si es agrandado o no')
      return
    }

    if (flagsSeleccion.cafe && !options.find(f => f.addon.addon_name.label === 'Cafe')) {
      alert('Seleccione cafe')
      return
    }

    if (flagsSeleccion.cono && !options.find(f => f.addon.addon_name.label === 'Cono')) {
      alert('Seleccione sabor')
      return
    }
    if (flagsSeleccion.cono2 && !options.find(f => f.addon.addon_name.label === 'Cono2')) {
      alert('Sabores')
      return
    }

    let productoResultante = {
      producto: {
        ...producto,
        order_product_addons: options,
        preferences: preferences,
        opciones: preferences,
        comments: '' + juguete
      }, deliv: productos.find(i => i.nameFront === 'Delivery')
    }
    productoResultante.producto.options = []
    delete productoResultante.producto.options

    console.log('state', producto)
    dispatch(addProducto(productoResultante))
    console.log(' producto agregado', productoResultante)
    props.history.push('/drivein/categorias')

  }

  const handlerCancelar = () => {
    props.history.push('/callcenter/productos')
  }

  return (

    <div className="w-full h-full flex flex-row">


      <div className="flex flex-col m-5      w-3/12">
        <img className=" object-cover rounded-lg  " src={data.url} alt="ilustracion"/>

        <div className="p-2 overflow-auto">
          <h3 className="text-gray-200  text-4xl font-bold "> {data.nameFront} </h3>
          <p className="text-gray-300 text-lg   mt-1">{data.descripcion} </p>
        </div>
      </div>


      <div className="w-full  flex-grow mr-3      rounded-lg overflow-y-auto w-7/12 ">


        {flagsSeleccion.opciones &&
        <div className="  bg-gray-400 rounded p-2 m-5">
          <p className="p-3">Preferencias: </p>
          <ul className="inline-flex">
            {

              producto.preferences.map(i =>

                <p class="inline-flex"><label className="md:w-2/3 block text-gray-800 font-bold">
                  <input className="mr-2 leading-tight" type="checkbox"
                         onChange={e => clickPreference(i, e.target.checked)}/>
                  <span className="text-2xl">
                            {i.nombre}
                            </span>
                </label></p>
              )

            } </ul>
        </div>
        }


        <div className='m-5 '>
          {flagsSeleccion.juguete &&

          <div className=" rounded  bg-gray-400  mb-5 p-2">


            <p className="text-xl">Juguete:
              <span className="font-bold text-2xl"> {juguete}</span>
            </p>

            <div className="inline-flex">

              <button className="bg-green-400   text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24"
                      onClick={() => setJuguete('niña')}>
                Niña
              </button>

              <button className="bg-green-400   text-gray-800 font-bold p-1  uppercase rounded mr-1  h-24 w-24"
                      onClick={() => setJuguete('niño')}>
                Niño
              </button>
            </div>
          </div>

          }
          {flagsSeleccion.papas &&
          <ProductoOption grupo="Papas" clickOptionHandler={clickOption} producto={producto} options={options}/>}
          {flagsSeleccion.bebida &&
          <ProductoOption grupo="Bebida" clickOptionHandler={clickOption} producto={producto} options={options}/>}
          {flagsSeleccion.agranda &&
          <ProductoOption grupo="Agrandado" clickOptionHandler={clickOption} producto={producto} options={options}/>}
          {flagsSeleccion.cafe &&
          <ProductoOption grupo="Cafe" clickOptionHandler={clickOption} producto={producto} options={options}/>}
          {flagsSeleccion.cono &&
          <ProductoOption grupo="Cono" clickOptionHandler={clickOption} producto={producto} options={options}/>}
          {flagsSeleccion.cono2 &&
          <ProductoOption grupo="Cono2" clickOptionHandler={clickOption} producto={producto} options={options}/>}


        </div>


        <h1 className="text-gray-200 font-bold text-4xl m-5 text-right">
          <NumberFormat value={producto.cantidad * precioC} displayType={'text'} thousandSeparator={true}
                        prefix={'Gs. '}/>
        </h1>


      </div>


      <div className="flex items-center   px-4 py-2 bg-gray-600 w-1/12 flex-col">


        <div className="flex flex-col grid gap-5 w-full mb-16  ">
          <button className=" text-2xl py-3 bg-green-400 text-xl text-gray-900 font-semibold rounded"
                  onClick={() => handlerAceptar()}>
            <FontAwesomeIcon icon={['fas', 'check']}/>
          </button>

          <button className=" text-2xl py-1  bg-gray-200 text-xl text-gray-900 font-semibold rounded "
                  onClick={() => props.history.goBack()}>
            <FontAwesomeIcon icon={['fas', 'arrow-left']}/>
          </button>

        </div>


        <div className="flex flex-col w-full grid gap-2">
          <button className="bg-gray-300  text-2xl  py-3 hover:bg-gray-400 text-gray-800 font-bold   rounded-l"
                  onClick={() => setProducto(prev => {
                    if (prev.cantidad !== 1) {
                      return {
                        ...producto,
                        cantidad: prev.cantidad - 1
                      }
                    } else {return { ...producto, cantidad: prev.cantidad }}
                  })}>
            <FontAwesomeIcon icon={['fas', 'minus']}/>
          </button>
          <p className="font-mono bg-white p-3"> {producto.cantidad} </p>
          <button className="bg-gray-300 text-2xl py-3 hover:bg-gray-400 text-gray-800 font-bold  w-full rounded-r mr-5"
                  onClick={() => setProducto(prev => {return { ...producto, cantidad: prev.cantidad + 1 }})}>
            <FontAwesomeIcon icon={['fas', 'plus']}/>
          </button>
        </div>
        <h1 className="text-gray-200 font-bold text-2xl">

        </h1>


      </div>


    </div>

  )
}
