import React, { useState, useContext, useEffect } from 'react'
import { authContext } from '../contexts/AuthContext'
import axios from 'axios'

const SignIn = ({ match, history }) => {


    const { user, pass, mac, hostname } = match.params;
    const { setAuthData } = useContext(authContext)


    useEffect(() => {
        const data = { email: user, password: pass, mac, hostname }

        localStorage.setItem('mac',mac)

        axios.post(process.env.REACT_APP_API_ENDPOINT + `auth2`, data).then(
          (result) => {
            console.log('result axios counter', result)
    
            setAuthData(result.data.token) // typically here we send a request to our API and in response, we receive the user token.
            //As this article is about the front-end part of authentication, we will save in the context the user's email.
    
            // localStorage.setItem('user', result.data.user)
            console.log(result.data.user)
    
            if (result.data.user.rol === 'drivein') {
              history.replace('/drivein')
            } else if (result.data.user.rol === 'totem') {
              history.replace('/totem')
            } else {
              history.replace('/callcenter')
            }
    
          }
        ).catch(e => {
          console.log('error ', e.response.data.error)
          alert(e.response.data.error)
    
        })
    }, [])
    




  return (

    <div className="w-full p-2 flex justify-center  bg-gray-600 h-full overflow-scroll">
        totem sign in...
    </div>

  )
}

export default SignIn
