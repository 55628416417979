import React  from 'react'
import Menu from '../components/Menu'
import Menu2 from '../components/Menu2'

import { useSelector , useDispatch} from 'react-redux';

import {
    selectFiltroProducto,
    setFiltroProducto
      } from '../features/masterSlice';

export default function Productos(props) {

    const query=useSelector(selectFiltroProducto);
    const dispatch = useDispatch();

    const handlerClickProduct = (p)=>{
        console.log("add product", p);


        props.history.push(  `/callcenter/producto/${p.sku}`, {data:p}  );


    }



    return (
<div className="flex w-full bg-red-400 overflow-hidden">
        <div className="flex flex-col  p-5  w-full">

      

        <div className="inline-flex mb-5">
        <input  value={query} onChange={  (e)=> { dispatch(setFiltroProducto(e.target.value)) }   }
            className=" bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" 
            placeholder="Buscar..." type="text"></input>
            
               { query!=='' && <button className=" bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" onClick={()=> { dispatch(setFiltroProducto('')) }  }>
                    X
                </button>}
        </div>



<div className=" flex-grow   overflow-scroll">
{/* <Menu  showPrice handlerClickProduct={handlerClickProduct} term={query} /> */}
<Menu2  showPrice handlerClickProduct={handlerClickProduct} term={query} />
</div>

        </div>

        </div>
    )
}
