import React, {useState, useEffect} from 'react'
import DateTimePicker from 'react-datetime-picker';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';
import TablaPedidos from '../components/TablaPedidos';
import Modal from 'react-modal';



import {  useDispatch, useSelector } from 'react-redux';


import {
   setFecha, selectFecha, selectSucursales, selectUser
} from '../features/masterSlice';
import PedidoPage from './PedidoPage';


const customStyles = {
    content : {
        padding:0
    }
  };






export default function PedidosDiPage(props) {

    const [today,setToday]=useState();
  //  const [fecha,setFecha]=useState(null);
    const [res,setRes]=useState(null);
    const [filtered,setFiltered]=useState(null);
    const [loading,setLoading]=useState(false);

    const [onlyCc, setOnlyCc]= useState(true);
    const [onlyApp, setOnlyApp]= useState(true);
    const [onlyDelivery, setOnlyDelivery]= useState(true);
    const [onlyFasttrack, setOnlyFasttrack]= useState(true);
    const [onlyProgramado, setOnlyProgramado]= useState(true);

    const [openModal, setOpenModal] = useState(false)
   const [pedidoId, setPedidoId] = useState(0)

    const formasPago= ['efectivo', 'bancard', 'pos'];
    const [filtroFp, setFiltroFp] = useState( formasPago )
    const [filtroSucursal, setFiltroSucursal] = useState([])



    const sucursales = useSelector(selectSucursales);

    const dispatch = useDispatch();
    const fecha=useSelector(selectFecha);
    const user=useSelector(selectUser);

 






    useEffect(() => {
      if(fecha) consultarPedidos(fecha);
    }, [props, user])



      useEffect(() => {
        moment.locale(['es']);
        setToday( moment().toDate() );
    }, [props.state])


   

    const solicitarRetorno = (id)=>{

      axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/pedidos/solicitarRetorno?id=${id}` ).then(
          (result)=>{
             
             consultarPedidos(fecha);
          }
      );        
  }


    const verPedido= (id)=>{
       setPedidoId(id);
       setOpenModal(true);
    }


    const regenerar= (id)=>{
        props.history.push(`/callcenter/productos`,{id:id});
    }





    const consultarPedidos = (date)=>{
         
       if(user && user.funcionario)
       {

        console.log("user", user);

         let sucursales= user.funcionario.sucursales.join(",");

        //let sucursales = 'Municipalidad';

        let d= moment(date).toISOString();
        setLoading(true);
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/pedidos/head?fecha=${d}&s=${sucursales}` ).then(
            (result)=>{
          
                console.log(  result.data );
                setFiltered( result.data.data.filter( i=>i.modalidad==='drivein' ) )
               setLoading(false);
            }
        ).catch( e=>{
          setLoading(false);
          
        } );      }  
    }







    return (


      <div className="w-full  p-5    flex  justify-center overflow-scroll  ">

   
        <Modal
        ariaHideApp={false}
          isOpen={openModal}
          style={customStyles}
          contentLabel="Pedido"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={ ()=>setOpenModal(false) }
        >
 
            <div className=" flex   h-full justify-between  w-full">

 
                    <PedidoPage pedidoId={  pedidoId } closeModalHandler={ ()=>setOpenModal(false) } />
            </div>
      
          
        </Modal>


                <div className="w-full grid grid-cols-2 gap-5">
                {
                    filtered && filtered.map( p=>
                        
                        
                    <div 
                    key={p.id} 
                    onClick={  ()=>verPedido(p._id) }
                    className="bg-gray-300  rounded  p-5" >

                        <div className="text-2xl transform-uppercase">
                        {p.estado}
                        </div>    

                        <div className="text-xl transform-uppercase">
                        {p.ticket}
                        </div>    

                        <div className="text-2xl transform-uppercase">
                        {p.vehiculo?.tipo} {p.vehiculo?.color} 
                        </div> 

                        <div className="text-sm transform-uppercase">
                        {p.sucursal}-{p.tomadoPor}
                        </div>   

                    </div>        


                        
                        )




                }
                </div>
 </div>
    
    )
}
