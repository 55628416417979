import React, { useState } from "react";
import axios from 'axios';
import Cliente from '../components/Cliente'
import {Link} from 'react-router-dom';
export default function ClientesPage(props) {

    const [query, setQuery] = useState("");
    const [res, setRes] = useState([]);

    // useEffect( () => {
    //   console.log( 'effect query'); 
    //   consultarClientes();
    // }, [query])



    const handleSubmit = (evt) => {
        evt.preventDefault();
       // alert(`Submitting Name ${query}`)

       consultarClientes();


    }


    const consultarClientes = ()=>{
        console.log(" request for cliente ");
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/cliente/search?query=${query}` ).then(
            (result)=>{
               console.log( "result axios counter", result);
               setRes(result.data);
            }
        );        
    }

    



    return (
        
        <div className="p-5 w-full overflow-scroll">
            <p>Clientes</p>

        <form className="w-full max-w-md pb-5"  onSubmit={handleSubmit}>
            <div className="flex items-center border-b border-b-2 border-teal-500 py-2">
                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text"
                value={query} onChange={  (e)=> { setQuery(e.target.value); }   }
                 placeholder="nombre, ruc o celular" aria-label="Full name" />


                <button 
                onClick={ ()=> props.history.push('/callcenter/nuevo/cliente',{query:query}) }
            className="flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded mr-3"  type="button"   >
                Nuevo </button>

                <button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded " type="submit">
                Buscar
                </button>




            </div>
            </form>







            <div className=" grid  grid-cols-1  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4">
            {
                res.map(el =>    <Link  key={el.id} to={{pathname: `/callcenter/cliente/${el.id}`}}  > <Cliente key={el.id} data={el} />  </Link> )
            }
            </div>


        </div>


    )
}
