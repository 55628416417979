import React, {useState, useEffect} from 'react'
import ProductoDi from '../../components/ProductoDi'
import { useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";



import {
    selectListaProductosDi
  } from '../../features/masterSlice'
import DiMenu from '../../components/DiMenu';



export default function MenuPage(props, { addProductHandler,  showPrice}) {

    const location = useLocation();
    const productos = useSelector(selectListaProductosDi);
    const [items,setItems]=useState([]);
//    const [clear,setClear]=useState(false);





    const filtrar =( term )=>{
     
        console.log("term ", term);
        let filtered=[];  
        if( term !==""){
            filtered= productos.filter( (a)=>{
                console.log("a", a.grupo.toUpperCase(), term );
            return  a.grupo.toUpperCase().search( term.toUpperCase() )  !== -1 ; 
            });
        }else
        {
            filtered= productos.filter( (a)=>{
            return true 
            });   
        }

        filtered= filtered.filter( i=> i.grupo!=='Delivery');

            let a={};
            filtered.forEach( i=>   {  if(!a[i.grupo])a[i.grupo]=[];    a[i.grupo].push(i) } );

            let b=[];
            for (const property in a) {
                b.push( {nombre:property, productos:a[property]} );
            }
        setItems( b );
    }


    // useEffect(() => {
    //    // filtrar();        
    //  });

    useEffect( () => {

 

    }, [])




 




    useEffect( () => {
        console.log( "state location ", location.state.categoria );
        if(location.state) filtrar( location.state.categoria );    

        
    }, [productos])


    const handlerClickProduct = (p)=>{
        console.log("add product ", p, props);


        props.history.push(  `/drivein/producto/${p.sku}`, {data:p}  );


    }



    return (


       


<div className="bg-gray-800 p-5 h-full overflow-auto">


{/* <div className="font-bold text-base mb-2 uppercase text-center">{ location.state.categoria } </div> */}

{items.map(c => 
 

<div  key={c.nombre} className="rounded     flex-row  w-full mb-2">
    
      

        <div className="grid  grid-cols-4  gap-3">
        {
            c.productos.map(el => 
            <ProductoDi   handlerClickProduct={handlerClickProduct}  addProductHandler={addProductHandler} showPrice={showPrice}   key={el.sku} data={el} /> )
        }
        </div>
    
</div>
)}




</div>
      


        )
}
