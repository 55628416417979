import React, {useState, useEffect} from 'react'
import moment from 'moment'
import 'moment/locale/es';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { motion, useAnimation } from "framer-motion";
//import { setSucursal } from '../features/pedidoSlice';


 

const styles={

    fondoBorrado:{
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`
    },
    fondoEntregado:{
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")`
    },

}


export default function Estado({data}) {

    let iconoModalidad, colorModalidad, fondo;

    const [escala, setEscala] = useState(0.3);
    const [animacion, setAnimacion] = useState({ scale:1 });
    const [now, setNow] = useState( moment()  )

    const [semaforo, setSemaforo] = useState(null)

    const [motionInitial, setMotionInitial] = useState(false)


    switch( data.modalidad ){
        case 'pickup': iconoModalidad='fa-burger-soda'; colorModalidad='bg-orange-300'; break;
        case 'drivein': iconoModalidad='car'; colorModalidad='bg-yellow-500'; break;
        case 'totem': iconoModalidad='car'; colorModalidad='bg-blue-100'; break;
        case 'delivery': iconoModalidad='motorcycle'; colorModalidad='bg-teal-200'; break;
        case 'fasttrack': iconoModalidad='car'; colorModalidad='bg-blue-200'; break;
        case 'programado': iconoModalidad='clock'; colorModalidad='bg-teal-400'; break;
        default: iconoModalidad=''; colorModalidad='';
    }

    fondo={};

    if( data.estado === 'Entregado'){ colorModalidad='bg-green-400'; fondo=styles.fondoEntregado;}
    if( data.estado === 'Borrado'){ colorModalidad='bg-red-300'; fondo=styles.fondoBorrado; }





    const controls = useAnimation()


    useEffect(() => {
        //console.log( "-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+- ONE TIME -+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-", data.ticket);

       const interval = setInterval( ()=>setNow(moment()), 30000   );

         return () => clearInterval(interval);


    }, []);


    useEffect(() => {
        console.log("effect data");

       // efecto1();

    }, [data])

    useEffect(() => {
 
        if( data.efecto == 1)efecto1();
        if( data.efecto == 2)efecto2();

 
    

    }, [data.efecto])


    useEffect(() => {
 
 
        let alarma=false;
        if( data.estado!=='Entregado' && data.estado!=='Borrado'){

            if(data.modalidad==='fasttrack' &&  (moment(data.created_at).add(data.buscaEn,'minutes').diff( now ,'minutes')<=5) )alarma=true;
            if(data.modalidad!=='fasttrack' && data.modalidad!=='programado' && data.estado==='En espera'  &&  (moment( ).diff( data.created_at,'minutes')>5) )alarma=true;
            if( data.modalidad==='programado' && data.estado==='En espera'  &&  moment(data.fechaEntregaFrom).diff( now ,'minutes') <30 )alarma=true;

// console.log("tiempo ", moment( ).diff( data.created_at,'minutes'));
        }

        if( alarma )efectoAlerta1();
    
        const horaPedido = moment(data.created_at);
        const horaActual = moment();
        const diferenciaEnMinutos = horaActual.diff( horaPedido, 'minutes');
        // console.log("La diferencia en minutos es:", diferenciaEnMinutos);

        let colorSemaforo = 'bg-green-500' //'bg-green-300';
        if(diferenciaEnMinutos >= 5)colorSemaforo = 'bg-yellow-200';
        if(diferenciaEnMinutos >= 10)colorSemaforo = 'bg-red-600';
        setSemaforo(colorSemaforo);


    }, [now])




    const efecto2=async ()=>{
        // initial={{ scale: 0 }}
        // animate={{ rotate: 180, scale: 1 }}

        await controls.start({scale:1.3, rotate:0, opacity:0.3, transition: { duration: 0 }});
        controls.start({
            scale: 1,
            opacity:1,
            rotate: 0,
            transition: {             
                type: "spring",
                stiffness: 260,
                damping: 20,
                duration: 0.4
            }
          })

        //    await controls.start({scale:0, opacity:0, transition: { duration: 0 }});
        //     controls.start({
        //         scale: 1,
        //         opacity:1,
        //         backgroundColor: "#f00",
        //         transition: { duration: 0.3 },
        //       })

        }


    const efecto1=async ()=>{
                // initial={{ scale: 0 }}
                // animate={{ rotate: 180, scale: 1 }}

                await controls.start({scale:0, rotate:0, opacity:0, transition: { duration: 0 }});
                controls.start({
                    scale: 1,
                    rotate:0,
                    opacity:1,
                    transition: {             
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 0.4
                    }
                })
        }




        const efectoAlerta1=()=>{
            // initial={{ scale: 0 }}
            // animate={{ rotate: 180, scale: 1 }}

            setMotionInitial( {
                scale:0, rotate:0, opacity:0.7
            } );

            controls.start({
                scale: 1.05,
                opacity:1,
                transition: {      
                    yoyo: Infinity,
                    duration: 0.6,
                    ease: "easeInOut"
                }
            })
    }







    return (


        <motion.div
        initial={ motionInitial }
        animate={ controls }
      >

        <div className={`rounded p-2 ${colorModalidad}   `}   style={  fondo }     >



            { (data.modalidad==='fasttrack') && data.buscaEn && <span> Busca a las {moment(data.created_at).add(data.buscaEn,'minutes').format('HH:mm')} (en {
                 moment(data.created_at).add(data.buscaEn,'minutes').diff( now ,'minutes') } mins.) </span> }


            {
                data.modalidad==='programado' && data.estado!=='Entregado' &&
                <span> 
                <FontAwesomeIcon icon={['fas', 'clock' ]}   />
                { moment(data.fechaEntregaFrom).format('HH:mm') }  (en {   moment(data.fechaEntregaFrom).diff( now ,'minutes') } m)
                </span>
            }


            <div className="flex-none sm:flex-1 md:flex justify-between  w-full">
                <p className="text-sm  md:text-2xl"> { <FontAwesomeIcon icon={['fas', iconoModalidad ]}   /> }  #{data.ticket}</p>
                <p className="text-xs font-bold uppercase text-right">  {data.sucursal}<br/>{data.modalidad} {(data.landing && data.landing==='true')?" - Landing":''} </p>
            </div>

     
            <div className="flex-none sm:flex-1 md:flex justify-between  justify-between  w-full">
                <p>   { moment(data.created_at).from( now ) }  </p>
                <p>   { moment(data.created_at).format('HH:mm') }  </p>
            </div>




            { data.estados && data.estados.map((e)=>{
                if (e.diff)
                    return <div><p>{e.diff + ' - ' + e.diff_min + 'm ' + e.diff_seg+'s '}</p></div>

            })}
            <p>{ data.cliente.nombre} { data.cliente.apellido}</p>

            
            

            { data.modalidad==='fasttrack' && <div className="inline-flex  justify-end  w-full">
                { data.modalidad==='fasttrack' && data.ft && data.ft.parking &&  <p className="text-xl mr-3">  <FontAwesomeIcon icon={['fas', 'flag-checkered' ]}   /> </p>}
                { data.modalidad==='fasttrack' && data.ft &&  data.ft.received &&    <p className="text-xl">  <FontAwesomeIcon icon={['fas', 'thumbs-up' ]}   /> ({data.puntaje}) </p>}
            </div>
            }

       
            {
                data.nombreMotorista &&   <p className="text-sm text-gray-700 text-right">  <FontAwesomeIcon icon={['fas', 'motorcycle' ]}   /> {data.nombreMotorista}</p>  
            }

            {/* <div>
                PB ID: 63903
            </div>
           { data.estado==='En caja' &&  <div>
                Driver: Own Pedro Alfonso
            </div> } */}



           {    data.estado === 'En espera' &&
               <div className={`rounded-full w-12 h-12 ${semaforo} border border-black`}>
                     </div>
            }




            
        </div>

</motion.div>


    )
}
